export enum PageType {
  Home = 'HOME',
  Account = 'ACCOUNT',
  Favorites = 'FSP',
  Designers = 'DSP',
  ProductDetailPage = 'PDP',
  Editorial = 'ESP',
  ProductListingPage = 'PLP',
  SearchResults = 'SRP',
  Trunkshow = 'TSP',
  TrunkshowListing = 'TLP',
  Checkout = 'CHECKOUT',
  ShoppingBag = 'SBP',
  Legal = 'LEGAL',
  About = 'ABOUT',
  Rewards = 'REWARDS',
  Employee = 'ELP',
  Guide = 'GLP',
  RequestReturnPage = 'RRP',
  CancelRequestPage = 'CRP',
  CancelConfirmationPage = 'CCP',
  ModaPrivate = 'MP',
  ModaNetwork = 'MN'
}
