import React from 'react';
import {
  EmailVerificationStatusPending,
  EmailVerificationStatusPendingProps
} from './EmailVerificationStatusPending';
import {
  EmailVerificationStatusSuccess,
  EmailVerificationStatusSuccessProps
} from './EmailVerificationStatusSuccess';
import {
  EmailVerificationStatusError,
  EmailVerificationStatusErrorProps
} from './EmailVerificationStatusError';
import {
  EmailVerificationStatusAlreadyVerified,
  EmailVerificationStatusAlreadyVerifiedProps
} from './EmailVerificationStatusAlreadyVerified';

export type EmailVerificationStatusProps =
  | ({ status: 'pending' } & EmailVerificationStatusPendingProps)
  | ({ status: 'success' } & EmailVerificationStatusSuccessProps)
  | ({ status: 'error' } & EmailVerificationStatusErrorProps)
  | ({ status: 'already-verified' } & EmailVerificationStatusAlreadyVerifiedProps);

export const EmailVerificationStatus: React.FC<EmailVerificationStatusProps> = props => {
  switch (props.status) {
    case 'pending':
      return <EmailVerificationStatusPending {...props} />;
    case 'success':
      return <EmailVerificationStatusSuccess {...props} />;
    case 'error':
      return <EmailVerificationStatusError {...props} />;
    case 'already-verified':
      return <EmailVerificationStatusAlreadyVerified {...props} />;
    default:
      return null;
  }
};
