import React, { useState, useEffect } from 'react';

export const ClientSideOnly: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return show ? <>{children}</> : null;
};
