import React from 'react';
import { range } from 'ramda';

import './DesktopSiteNavItemLoading.scss';

const PLACEHOLDER = 'Loading';
const NO_OF_ITEMS = 6;

export const DesktopSiteNavItemLoading: React.FC = () => (
  <div className="DesktopSiteNavItemLoading">
    {range(0, NO_OF_ITEMS).map(item => (
      <span key={item}>{PLACEHOLDER}</span>
    ))}
  </div>
);
