const ANY_TAG = /(<([^>]+)>)/gi;
const BR_TAG = /<br\s*[/]?>/gi;
const NBSP = '&nbsp;';

export const stripHTMLTags = (html: string): string => {
  if (html === '') return '';
  return html.replace(ANY_TAG, '');
};

export const sanitizeHTML = (html: string, wrap = true): string | string[] | null => {
  if (html === '') return null;

  const stripped = stripHTMLTags(
    html
      // Convert non-breaking spaces
      .replace(NBSP, ' ')
      // Convert <br>'s to newlines
      .replace(BR_TAG, '\n')
      // Insert newlines after every tag
      .replace(ANY_TAG, '$1\n')
  );

  const deduped = stripped
    .split('\n')
    .map(string => string.trim())
    .filter(string => string !== '');

  return wrap ? deduped.map(string => `<div>${string}</div>`).join('') : deduped;
};
