import React, { useCallback } from 'react';
import { Clickable } from '@moda/om';
import classNames from 'classnames';
import { Country } from '@moda/portal-stanchions';

import './CountrySelectorItem.scss';

export interface Props {
  country: Country;
  onSelect(country: Country): void;
  active: boolean;
}

export const CountrySelectorItem: React.FC<Props> = ({ country, onSelect, active }) => {
  const handleClick = useCallback(() => onSelect(country), [country, onSelect]);

  return (
    <Clickable
      className={classNames('CountrySelectorItem', {
        'CountrySelectorItem--active': active
      })}
      onClick={handleClick}
    >
      <span className="CountrySelectorItem__name">{country.name}</span>
      <span className="CountrySelectorItem__currency">({country.currencyCode})</span>
    </Clickable>
  );
};
