import { ControlLink, Stack } from '@moda/om';
import classNames from 'classnames';
import React from 'react';
import { useRefreshedMobileSiteNavMenuQuery } from '../useRefreshedMobileSiteNavMenuQuery';

import './RefreshedMobileSiteNavMenuHeader.scss';

interface Props {
  selectedTab?: string;
  onChange: (selectedTab: string) => void;
}

export const RefreshedMobileSiteNavMenuHeader: React.FC<Props> = ({ selectedTab, onChange }) => {
  const { tabs } = useRefreshedMobileSiteNavMenuQuery();

  if (!selectedTab) return null;

  if (!tabs) return null;

  return (
    <Stack
      className="RefreshedMobileSiteNavMenuHeader"
      direction="horizontal"
      justifyContent="space-between"
      space={0}
    >
      {tabs.map(({ label }) => (
        <div
          className={classNames('RefreshedMobileSiteNavMenuHeader__tab', {
            'RefreshedMobileSiteNavMenuHeader__tab--two-items': tabs.length === 2,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            'RefreshedMobileSiteNavMenuHeader__tab--three-items': tabs.length === 3,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            'RefreshedMobileSiteNavMenuHeader__tab--four-items': tabs.length === 4,
            'RefreshedMobileSiteNavMenuHeader__tab--selected': selectedTab === label.toLowerCase()
          })}
          key={label}
          onKeyDown={() => onChange(label.toLowerCase())}
          onClick={() => onChange(label.toLowerCase())}
          role="button"
          tabIndex={-1}
        >
          <ControlLink underlined={selectedTab === label.toLowerCase()}>
            {label.toUpperCase()}
          </ControlLink>
        </div>
      ))}
    </Stack>
  );
};
