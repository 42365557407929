import React, { Attributes } from 'react';
import loadable, { LoadableComponent } from '@loadable/component';
import { StatusCodes } from 'http-status-codes';
import { StatusCodeProvider } from '../hooks/useStatusCode';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { Loading } from '../components/Loading';

const ErrorScreen = loadable(() => import('../components/ErrorScreen'), {
  fallback: <Loading />
});

export function renderWithStatusCode<TProps extends Attributes>(
  Component: React.FC | LoadableComponent<TProps>,
  statusCode?: number
) {
  return ({ staticContext, ...rest }: { staticContext: { statusCode: number } }) => {
    if (staticContext) {
      staticContext.statusCode = statusCode || StatusCodes.OK;
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <StatusCodeProvider value={staticContext ?? { statusCode: StatusCodes.OK }}>
          <Component {...(rest as TProps)} />
        </StatusCodeProvider>
      </ErrorBoundary>
    );
  };
}
