import { gql, TypedDocumentNode } from '@apollo/client';
import React, { useCallback, createContext, useContext } from 'react';
import { serialize } from 'cookie';
import { pick } from 'ramda';
import { UseCookiesQuery } from '../generated/types';
import { throwQueryError } from '../lib/apollo/throwQueryError';
import { serializeUser, NULL_USER } from '../lib/user';
import { getCookies as getAllCookies } from '../lib/getCookies';
import { useLocalQuery } from './useLocalQuery';
import { ENCODED_DEFAULT_PREFERENCES } from './usePreferences/tools';

type Cookies = NonNullable<UseCookiesQuery['cookies']>;

export const COOKIES: Omit<Cookies, '__typename'> = {
  attSignedUpWith: null,
  cartId: null,
  cloudfrontViewerCountry: null,
  fullUser: serializeUser(NULL_USER),
  isAmexCustomer: null,
  modaAnonymousId: null,
  preferences: ENCODED_DEFAULT_PREFERENCES,
  promoCode: '',
  sessionQueryParams: null,
  signifydSessionId: null,
  stateAbbreviation: '',
  stateName: '',
  zipCode: ''
};

const COOKIE_KEYS = Object.keys(COOKIES);

export type Cookie = keyof typeof COOKIES;

export const USE_COOKIES_QUERY: TypedDocumentNode<UseCookiesQuery, void> = gql`
  query UseCookiesQuery {
    cookies @client {
      attSignedUpWith
      cartId
      cloudfrontViewerCountry
      fullUser
      isAmexCustomer
      modaAnonymousId
      preferences
      promoCode
      sessionQueryParams
      signifydSessionId
      stateAbbreviation
      stateName
      zipCode
    }
  }
`;

export const parseCookies = (cookies: { [key: string]: string | undefined }): Cookies => ({
  __typename: 'Cookies',
  ...COOKIES,
  ...pick(COOKIE_KEYS, cookies)
});

export const getCookies = (): Cookies => parseCookies(getAllCookies());

// creating a React context to fix performance problems caused by using a useQuery in every PageFilterFacet
export const CookiesContext = createContext({
  cookies: COOKIES,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteCookie: (name: Cookie | Cookie[]) => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCookie: (name: Cookie, value: string, maxAge?: number) => {
    return;
  }
});

export const CookiesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { data, error, client } = useLocalQuery(USE_COOKIES_QUERY);

  const deleteCookie = useCallback(
    (name: Cookie | Cookie[]) => {
      const names = Array.isArray(name) ? name : [name];

      names.forEach(name => {
        document.cookie = serialize(name, '', { expires: new Date(0), path: '/' });
      });

      const data = client.readQuery({ query: USE_COOKIES_QUERY });

      client.writeQuery({
        query: USE_COOKIES_QUERY,
        data: {
          cookies: {
            __typename: 'Cookies',
            ...COOKIES,
            ...data?.cookies,
            ...names.reduce((names, name) => ({ ...names, [name]: null }), {})
          }
        }
      });
    },
    [client]
  );

  const setCookie = useCallback(
    (name: Cookie, value: string, maxAge?: number) => {
      document.cookie = serialize(name, value, { maxAge, path: '/' });

      const data = client.readQuery({ query: USE_COOKIES_QUERY });

      client.writeQuery({
        query: USE_COOKIES_QUERY,
        data: {
          cookies: {
            __typename: 'Cookies',
            ...COOKIES,
            ...data?.cookies,
            [name]: value
          }
        }
      });
    },
    [client]
  );

  if (error) throwQueryError(error);

  return (
    <CookiesContext.Provider value={{ cookies: data?.cookies || COOKIES, deleteCookie, setCookie }}>
      {children}
    </CookiesContext.Provider>
  );
};

export const useCookies = () => useContext(CookiesContext);
