import waitUntil from 'wait-until-promise';
import { isElementInView } from '@moda/portal-stanchions';

const MAX_WAIT_MS = 2000;
const CHECK_DELAY_MS = 10;

/**
 * Waits until all placeholder cells that are in the viewport get loaded.
 */
export const waitForPlaceholderCellsToLoad = () =>
  waitUntil(
    () =>
      Array.from(document.querySelectorAll('.PlaceholderCell')).every(
        cell => !isElementInView(cell)
      ),
    MAX_WAIT_MS,
    CHECK_DELAY_MS
  );
