import { scrollIntoViewIfNeeded } from './scrollIntoViewIfNeeded';
import { waitForPlaceholderCellsToLoad } from './waitForPlaceholderCellsToLoad';

const scrollIntoView = (cell: Element | null) =>
  scrollIntoViewIfNeeded(cell, { scrollMode: 'always', behavior: 'auto' });

/**
 * Scrolls a lazy cell into view.
 * The scroll can trigger other cells to be loaded and thus the cell to be shifted.
 * This solves it by waiting for all other cells to load and then scrolling the cell into view again.
 * Note: This can potentially create some flickering.
 */
export const scrollLazyCellIntoView = (getCell: () => Element | null) => {
  scrollIntoView(getCell());
  waitForPlaceholderCellsToLoad().then(() => scrollIntoView(getCell()));
};
