import { gql } from '@apollo/client';

const LIST_NAVIGATOR_UNIT_SUBCATEGORY_FRAGMENT = gql`
  fragment ListNavigatorUnitSubcategoryFragment on NavigatorUnitListItem {
    id
    title
    href
    type
  }
`;

const LIST_NAVIGATOR_UNIT_FRAGMENT = gql`
  fragment ListNavigatorUnitFragment on ListNavigatorUnit {
    header
    subcategories: list {
      ...ListNavigatorUnitSubcategoryFragment
    }
  }
  ${LIST_NAVIGATOR_UNIT_SUBCATEGORY_FRAGMENT}
`;

const EDITORIAL_NAVIGATOR_UNIT_FRAGMENT = gql`
  fragment EditorialNavigatorUnitFragment on EditorialNavigatorUnit {
    anchorText
    headline
    href
    imageSrc: image_source
    imageAlt: image_alt
  }
`;

const DESKTOP_NAVIGATOR_VERTICAL_ITEM_FRAGMENT = gql`
  fragment DesktopNavigatorVerticalItemFragment on DesktopNavigatorItem {
    id
    name
    href
    style {
      color
      bold
    }
    units {
      ...ListNavigatorUnitFragment
      ...EditorialNavigatorUnitFragment
    }
  }
  ${LIST_NAVIGATOR_UNIT_FRAGMENT}
  ${EDITORIAL_NAVIGATOR_UNIT_FRAGMENT}
`;

const DESKTOP_NAVIGATOR_VERTICAL_FRAGMENT = gql`
  fragment DesktopNavigatorVerticalFragment on DesktopNavigatorVertical {
    id
    items {
      ...DesktopNavigatorVerticalItemFragment
    }
    title
    href
    style {
      color
      bold
    }
  }
  ${DESKTOP_NAVIGATOR_VERTICAL_ITEM_FRAGMENT}
`;

export const DESKTOP_NAVIGATOR_VERTICALS_QUERY = gql`
  query DesktopNavigatorVerticalsQuery($platform: NavigatorPlatform!) {
    navigatorVerticals: navigator_verticals(platform: $platform) {
      ... on DesktopNavigatorVertical {
        ...DesktopNavigatorVerticalFragment
      }
    }
  }
  ${DESKTOP_NAVIGATOR_VERTICAL_FRAGMENT}
`;
