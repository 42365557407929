import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading, Popover } from '@moda/om';
import loadable from '@loadable/component';
import { hrefFor } from '../../routers';
import { useJustAddedItems } from './useJustAddedItems';
import { MiniCartLink } from './MiniCartLink';

type Props = {
  screen?: 'mobile' | 'desktop';
};

const MiniCartDropdown = loadable(() => import('./MiniCartDropdown'), {
  resolveComponent: exports => exports.MiniCartDropdown,
  fallback: <Loading />
});

export const MiniCart: React.FC<Props> = ({ screen = 'desktop' }) => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { justAddedItems, dismiss, persist } = useJustAddedItems();

  useEffect(() => {
    setShow(justAddedItems.length > 0);
  }, [justAddedItems, setShow]);

  const handleHide = useCallback(() => {
    setShow(false);
    dismiss();
  }, [setShow, dismiss]);

  const handleShow = useCallback(() => {
    setShow(true);
    persist();
  }, [setShow, persist]);

  if (location.pathname === hrefFor.ShoppingBagPage()) return <MiniCartLink />;

  return screen === 'desktop' ? (
    <Popover
      open={show}
      anchor="topRight"
      content={
        <MiniCartDropdown
          justAddedItems={justAddedItems}
          onHide={handleHide}
          onHover={handleShow}
        />
      }
    >
      <MiniCartLink />
    </Popover>
  ) : (
    <>
      <MiniCartLink />

      {show && (
        <div>
          <MiniCartDropdown
            justAddedItems={justAddedItems}
            onHide={handleHide}
            onHover={handleShow}
          />
        </div>
      )}
    </>
  );
};
