import url from 'url';
import React, { useMemo, memo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { stripInternalHost } from '../../lib/stripInternalHost';

export type Props = NavLinkProps;

export const Link: React.FC<Props> = memo(({ className, style, to, ...rest }) => {
  const resolvedTo = useMemo(() => (typeof to === 'string' ? stripInternalHost(to) : to), [to]);
  if (typeof resolvedTo !== 'string' || url.parse(resolvedTo).hostname == null) {
    return (
      <NavLink
        {...rest}
        className={className}
        style={style}
        to={resolvedTo}
        activeClassName="NavLink--active"
      />
    );
  } else {
    return (
      <a
        className={typeof className === 'function' ? className(false) : className}
        style={typeof style === 'function' ? style(false) : style}
        aria-label={rest['aria-label']}
        href={resolvedTo}
        {...rest}
      >
        {rest.children}
      </a>
    );
  }
});
