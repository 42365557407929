import React from 'react';
import { Constrain, Divider, Stack } from '@moda/om';
import { SiteFooterMailingList } from '../SiteFooterMailingList';
import { SiteFooterBasement } from '../SiteFooterBasement';
import { SiteFooterLinks } from '../SiteFooterLinks';

import './SiteFooterDesktop.scss';

export const SiteFooterDesktop = () => {
  return (
    <Stack space={5}>
      <Constrain className="SiteFooterDesktop">
        <Stack space={5}>
          <SiteFooterMailingList className="SiteFooterDesktop__mailing-list" />
          <SiteFooterLinks className="SiteFooterDesktop__links" />
        </Stack>
      </Constrain>
      <Divider className="SiteFooterDesktop__divider" />
      <Constrain>
        <SiteFooterBasement />
      </Constrain>
    </Stack>
  );
};
