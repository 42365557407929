import React, { ReactNode } from 'react';
import { Stack, Text, Button } from '@moda/om';
import { noop } from '@moda/portal-stanchions';
import { ModalOverlay } from '../Modal';

import './Dialog.scss';

export type DialogProps = {
  show: boolean;
  message?: ReactNode;
  buttonText?: string;
  onHide?: () => void;
};

export const Dialog: React.FC<DialogProps> = ({ show, message, buttonText, onHide = noop }) => (
  <ModalOverlay contentClassName="Dialog" onClose={onHide} show={show}>
    <Stack className="Dialog__content" space={6}>
      {message && <Text treatment="h5">{message}</Text>}
      <Button onClick={onHide}>{buttonText}</Button>
    </Stack>
  </ModalOverlay>
);
