import { useQuery } from '@apollo/client';

// there is a bug where useQuery returns loading: true on SSR for skipped queries
// this is a workaround to never return loading: true for skipped queries
export const useSkippableQuery: typeof useQuery = (...args) => {
  const { loading, ...rest } = useQuery(...args);
  return {
    ...rest,
    loading: loading && !args[1]?.skip
  };
};
