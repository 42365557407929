import React from 'react';
import ChevronRightIcon from '@moda/icons/chevron-right-16';
import classNames from 'classnames';
import { Clickable } from '@moda/om';
import { Link } from '../Link';

import './MobileSiteNavOption.scss';

interface Props {
  arrow?: boolean;
  href?: string | null;
  title?: string;
  children: React.ReactNode;
  className?: string;
  onClick?(event: React.MouseEvent): void;
}

export const MobileSiteNavOption: React.FC<Props> = ({
  children,
  arrow,
  href,
  title,
  className,
  ...rest
}) => {
  const props = {
    className: classNames('MobileSiteNavOption', className),
    title,
    ...rest
  };

  const siteNameChildren = (
    <>
      <span className="MobileSiteNavOption__label">{children}</span>

      {arrow && <ChevronRightIcon />}
    </>
  );

  if (href)
    return (
      <Link to={href} {...props}>
        {siteNameChildren}
      </Link>
    );

  return <Clickable {...props}>{siteNameChildren}</Clickable>;
};
