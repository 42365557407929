import { useQuery } from '@apollo/client';
import { tryCatch } from 'ramda';

// there is a bug in the useQuery hook where it returns {loading: true, data: undefined}
// for @client queries on the first render call on SSR
// useLocalQuery works around this by getting the data directly from the Apollo client if useQuery doesn't return it
// WARNING: use this only for local queries, i.e. queries with the @client directive
export const useLocalQuery: typeof useQuery = (...args) => {
  const { data, client, ...rest } = useQuery(...args);
  return {
    data:
      data ??
      tryCatch(
        () => client.readQuery({ query: args[0], ...args[1] }) as typeof data,
        () => undefined
      )() ??
      undefined,
    client,
    ...rest
  };
};
