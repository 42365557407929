import React, { useCallback } from 'react';
import ShoppingBagIcon from '@moda/icons/shopping-bag-24';
import classNames from 'classnames';
import { useCart } from '../../hooks/useCart';
import { Link } from '../Link';
import { hrefFor } from '../../routers';
import { tc } from '../../lib/trackingContext';

import './MiniCartLink.scss';

export const MiniCartLink: React.FC = () => {
  const {
    cart: {
      attributes: { cartItemsCount }
    }
  } = useCart({ ssr: false });

  const trackHrefClick = useCallback(() => {
    tc.track('Mini Cart Icon Clicked');
  }, []);

  return (
    <Link
      className={classNames('MiniCartLink', { 'MiniCartLink--with-count': cartItemsCount > 0 })}
      onClick={trackHrefClick}
      to={hrefFor.ShoppingBagPage()}
      aria-label="Shopping Bag"
    >
      <ShoppingBagIcon />

      {cartItemsCount > 0 && <div className="MiniCartLink__count">({cartItemsCount})</div>}
    </Link>
  );
};
