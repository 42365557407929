import { useLocation } from 'react-router-dom';
import { findRouteFor } from '../routers';

export const usePageType = () => {
  const { pathname } = useLocation();

  const route = findRouteFor(pathname);
  const pageType = route?.pagetype ?? null;

  return pageType;
};
