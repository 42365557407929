import { Divider, Stack } from '@moda/om';
import React, { useCallback, useEffect, useState } from 'react';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-24';
import { useUser } from '../../../hooks/useUser';
import { MobileSiteNavOption } from '../MobileSiteNavOption';
import { useCountry } from '../../CountrySelector';
import { hrefFor } from '../../../routers';
import { MobileSiteNavCountrySelector } from '../MobileSiteNavCountrySelector';
import { Loading } from '../../Loading';
import { RefreshedMobileSiteNavMenuVertical } from './RefreshedMobileSiteNavMenuVertical';
import { useRefreshedMobileSiteNavMenuQuery } from './useRefreshedMobileSiteNavMenuQuery';
import { RefreshedMobileSiteNavMenuHeader } from './RefreshedMobileSiteNavMenuHeader';

import './RefreshedMobileSiteNavMenu.scss';

interface Props {
  onClose: () => void;
  verticallyScrollTo: (top: number) => void;
  getCurrentVerticalScroll: () => number | undefined;
}

type PaneName = 'default' | 'currency-change';

export const RefreshedMobileSiteNavMenu: React.FC<Props> = ({
  onClose,
  verticallyScrollTo,
  getCurrentVerticalScroll
}) => {
  const { tabs, loading } = useRefreshedMobileSiteNavMenuQuery();
  const [selectedPane, setSelectedPane] = useState<PaneName>('default');
  const [previousScroll, setPreviousScroll] = useState<undefined | number>(
    getCurrentVerticalScroll()
  );
  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined);

  const { user } = useUser();
  const {
    country: { alpha2Code: currency }
  } = useCountry();

  useEffect(() => setSelectedTab(tabs?.[0].label.toLowerCase()), [tabs]);

  const handleChangePane = useCallback(
    (pane: PaneName) => {
      if (pane === 'default') {
        setSelectedPane('default');

        if (previousScroll) {
          verticallyScrollTo(previousScroll);
        }

        setPreviousScroll(undefined);
      }

      if (pane === 'currency-change') {
        const currentScroll = getCurrentVerticalScroll();

        setSelectedPane('currency-change');

        setPreviousScroll(currentScroll);

        verticallyScrollTo(0);
      }
    },
    [getCurrentVerticalScroll, previousScroll, verticallyScrollTo]
  );

  if (loading) return <Loading />;

  if (!tabs) return null;

  if (tabs.length === 0) return null;

  return (
    <Stack className="RefreshedMobileSiteNavMenu" space={1}>
      {selectedPane === 'default' && (
        <>
          <RefreshedMobileSiteNavMenuHeader selectedTab={selectedTab} onChange={setSelectedTab} />

          <RefreshedMobileSiteNavMenuVertical
            selectedTab={selectedTab}
            onResetScroll={() => verticallyScrollTo(0)}
            onClose={onClose}
          />

          <Divider className="RefreshedMobileSiteNavMenu__divider" />

          <div className="RefreshedMobileSiteNavMenu__user-actions">
            {!user.isLoggedIn ? (
              <MobileSiteNavOption
                onClick={onClose}
                href={hrefFor.RegisterPage({ redirectTo: location.pathname })}
              >
                Sign In/Register
              </MobileSiteNavOption>
            ) : (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.AccountSettings()}>
                My Account
              </MobileSiteNavOption>
            )}

            <MobileSiteNavOption onClick={() => handleChangePane('currency-change')}>
              My Currency ({currency})
            </MobileSiteNavOption>

            <MobileSiteNavOption onClick={onClose} href={hrefFor.FavoritesPage()}>
              My Favorites <FavoriteOutlineIcon />
            </MobileSiteNavOption>

            {user.isLoggedIn && (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.LogoutPage()}>
                Sign Out
              </MobileSiteNavOption>
            )}
          </div>
        </>
      )}

      {selectedPane === 'currency-change' && (
        <MobileSiteNavCountrySelector onClose={() => handleChangePane('default')} />
      )}
    </Stack>
  );
};
