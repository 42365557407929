import { useQuery, gql, TypedDocumentNode } from '@apollo/client';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { PAGE_LAYOUT_QUERY } from '../components/Layouts/PageLayout';
import { DebugSimpleQuery, DebugSimpleQueryVariables } from '../generated/types';

const DEBUG_SIMPLE_QUERY: TypedDocumentNode<DebugSimpleQuery, DebugSimpleQueryVariables> = gql`
  query DebugSimpleQuery($id: String!) {
    variant(id: $id) {
      id
      name
      designerName: designer_name
      masterVariant: master_variants_data {
        primaryImageUrls: primary_image_urls {
          src: large
        }
      }
    }
  }
`;

const Empty = () => <div>∅</div>;

const PageLayoutData = () => {
  const { search } = useLocation();

  const headerSSR = !search.includes('header=client');

  const { data, loading, error } = useQuery(PAGE_LAYOUT_QUERY, {
    ssr: headerSSR
  });

  if (error) {
    throw error;
  }

  return <pre>{JSON.stringify({ loading, data }, null, 2)}</pre>;
};

const SimpleQuery = ({ id }: { id: string }) => {
  const { search } = useLocation();

  const bodySSR = !search.includes('body=client');

  const { data, loading, error } = useQuery(DEBUG_SIMPLE_QUERY, {
    variables: { id },
    ssr: bodySSR
  });

  if (error) {
    throw error;
  }

  return <pre>{JSON.stringify({ loading, data }, null, 2)}</pre>;
};

const ComplexQuery = ({ id }: { id: string }) => {
  return (
    <>
      <PageLayoutData />
      <SimpleQuery id={id} />
    </>
  );
};

export const DEBUG_ROUTES = [
  <Route exact key="debug:empty" path="/debug/empty" component={() => <Empty />} />,

  <Route
    exact
    key="debug:simple"
    path="/debug/simple/:id"
    component={({ match }: { match: { params: { id: string } } }) => (
      <SimpleQuery id={match.params.id} />
    )}
  />,

  <Route
    exact
    key="debug:query"
    path="/debug/complex/:id"
    component={({ match }: { match: { params: { id: string } } }) => (
      <ComplexQuery id={match.params.id} />
    )}
  />
];
