import { gql } from '@apollo/client';
import React from 'react';
import classNames from 'classnames';
import { DesktopSiteNavItemFragment } from '../../../../generated/types';
import {
  DESKTOP_SITE_NAV_IMAGE_COLUMN_FRAGMENT,
  DesktopSiteNavImageColumn
} from './DesktopSiteNavImageColumn';
import {
  DESKTOP_SITE_NAV_LIST_COLUMN_FRAGMENT,
  DesktopSiteNavListColumn
} from './DesktopSiteNavListColumn';
import './DesktopSiteNavPulldown.scss';

export const DESKTOP_SITE_NAV_PULLDOWN_FRAGMENT = gql`
  fragment DesktopSiteNavPulldownFragment on NavItem {
    desktopChildren {
      ...DesktopSiteNavListColumnFragment
      ...DesktopSiteNavImageColumnFragment
    }
  }
  ${DESKTOP_SITE_NAV_LIST_COLUMN_FRAGMENT}
  ${DESKTOP_SITE_NAV_IMAGE_COLUMN_FRAGMENT}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  navItem: DesktopSiteNavItemFragment;
  active: boolean;
}

export const DesktopSiteNavPulldown: React.FC<Props> = ({ className, navItem, active }) => (
  <div className={classNames('DesktopSiteNavPulldown', className)}>
    {navItem.desktopChildren.map((column, index) => {
      switch (column.__typename) {
        case 'NavListColumn':
          return (
            <DesktopSiteNavListColumn
              className="DesktopSiteNavPulldown__column"
              key={index}
              parentLabel={navItem.label as string}
              navListColumn={column}
            />
          );

        case 'NavImageColumn':
          return (
            <DesktopSiteNavImageColumn
              key={index}
              className="DesktopSiteNavPulldown__column"
              navImageColumn={column}
              active={active}
            />
          );
      }
    })}
  </div>
);
