import { always, tryCatch } from 'ramda';
import { History } from 'history';
import { ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorLink } from '@apollo/client/link/error';
import { hrefFor } from '../../../routers';
import { logger } from '../../../lib/logger';
import { isDevelopment } from '../../../lib/isEnv';
import { getCookies } from '../../../hooks/useCookies';
import { getUserFromCookies } from '../../../hooks/useUser';
import { createCache, initializeCache } from '../cache';
import { getSearchApiHeaders } from '../getSearchApiHeaders';
import { formatFeatureFlags } from '../formatFeatureFlags';
import { createCommonConfiguration, isUnauthorizedError } from './common';

const createClientErrorHandler =
  (history: History): ErrorLink.ErrorHandler =>
  ({ graphQLErrors, networkError, operation, response }) => {
    if (isUnauthorizedError({ graphQLErrors, networkError })) {
      if (response) response.errors = undefined;
      history.replace(hrefFor.LogoutPage());
    }

    logger.error(
      'APOLLO_CLIENT_ERROR',
      `Apollo: GraphQL Error during operation "${operation.operationName}"`,
      {
        graphQLErrors,
        networkError,
        operation,
        response
      }
    );
  };

const flags = JSON.stringify(formatFeatureFlags(window.__FEATURE_FLAGS__ || {}));

export const createClientSideApolloClient = (history: History) => {
  const cache = createCache();
  const headersLink = setContext((_, { headers }) => {
    const cookies = getCookies();
    const searchApiHeaders = getSearchApiHeaders(
      cookies,
      history.location.pathname + history.location.search
    );
    const { accessToken } = getUserFromCookies();

    return {
      headers: {
        ...headers,
        ...searchApiHeaders,
        authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    };
  });

  cache.restore(window.__APOLLO_STATE__);
  initializeCache(cache, {});

  const commonConfiguration = createCommonConfiguration({
    additionalRestHeaders: {
      'X-Mojo-Feature-Flags': flags
    }
  });

  return new ApolloClient({
    ...commonConfiguration,
    link: onError(createClientErrorHandler(history))
      .concat(headersLink)
      .concat(commonConfiguration.link),
    cache,
    connectToDevTools: isDevelopment()
      ? true
      : tryCatch(devTools => JSON.parse(devTools), always(false))(window.__ENV__?.DEV_TOOLS)
  });
};
