import { EventProperties, SegmentEvent } from '@segment/analytics-next';
import {
  all,
  anyPass,
  assoc,
  filter,
  flatten,
  forEachObjIndexed,
  has,
  head,
  is,
  isNil,
  map,
  mergeAll,
  omit,
  pluck,
  reject,
  uniq
} from 'ramda';
import { snakeCase } from 'case-anything';
import {
  Adjuster,
  ConversionInstructions,
  EventParameters,
  Item,
  SegmentModaProduct
} from './deviceModeTypes';

function areItems(maybeItems: unknown): maybeItems is Item[] {
  if (Array.isArray(maybeItems)) {
    return all(
      maybeItem =>
        is(Object, maybeItem) && (has('item_id', maybeItem) || has('item_name', maybeItem)),
      maybeItems
    );
  }
  return false;
}

function isProduct(maybeProduct: unknown): maybeProduct is SegmentModaProduct {
  return is(Object, maybeProduct) && (has('product_id', maybeProduct) || has('name', maybeProduct));
}

function areProducts(maybeProducts: unknown): maybeProducts is SegmentModaProduct[] {
  if (Array.isArray(maybeProducts)) {
    return all(isProduct, maybeProducts);
  }
  return false;
}

export const normalizeName = function (name: string, maxLength = 40) {
  // Limits on event name size:
  // https://support.google.com/analytics/answer/9267744?hl=en&ref_topic=9756175
  return snakeCase(name)
    .slice(0, maxLength)
    .replace(/(.*)_$/, '$1');
};

/**
 * toItems: Converts Segment "Products" to an array of GA4 "Items".
 * @param oneOrMoreProducts
 * @returns Item[]
 */
const toItems = function (oneOrMoreProducts: SegmentModaProduct[] | SegmentModaProduct): Item[] {
  const convertToItem = (product: SegmentModaProduct) => {
    const categories = product.category?.split('/');
    return {
      item_id: product.product_id && String(product.product_id),
      item_name: product.name,
      currency: product.currency,
      index: product.position,
      item_brand: product.brand,
      item_category: categories?.[0],
      item_category2: categories?.[1]?.split(',')[0],
      item_variant: product.variant,
      quantity: product.quantity,
      price: product.price
    } as Item;
  };
  if (Array.isArray(oneOrMoreProducts)) {
    return oneOrMoreProducts.map(convertToItem);
  }
  return [convertToItem(oneOrMoreProducts)];
};

/**
 * toParameters: Converts Segment event properties to GA4 event parameters.
 * @param props
 * @returns EventParameters
 */
const toParameters = function (props: EventProperties | undefined) {
  const MAX_PROPERTY_VALUE_LENGTH = 100;
  let params: EventParameters = {};
  if (props === undefined) return params;
  forEachObjIndexed((value, key) => {
    const newKey = normalizeName(key);
    if (is(String)(value)) {
      params = assoc(newKey, value.slice(0, MAX_PROPERTY_VALUE_LENGTH), params);
    }
    if (is(Number)(value) || is(Boolean)(value) || isNil(value) || areItems(value)) {
      params = assoc(newKey, value, params);
    }
    if (areProducts(value)) {
      params = assoc(newKey, toItems(value), params);
    }
  }, props);
  return params;
};

const isNull = (item: unknown): item is null => item === null;
const isPrimitive = anyPass([is(String), is(Number), is(Boolean), isNull]);

/**
 * copy: A fluent API for giving the converter instructions for changing the
 * Segment event to a GA4 recommended event.
 *
 * Usage: `copy('source').to('destination')`
 * The return value is a function that will take the segment event properties, and
 * return an object of the form `{ destination: value }`.
 * These objects will eventually be merged with the original event, and the whole
 * thing will be converted into GA4 format.
 *
 * If the "source" is ".", then instead of just copying one property, it will copy
 * all properties which have a primitive value (string | number | boolean | null).
 *
 * @param source
 * @returns `(segmentEventProperties) => { destination: value }`
 */
export function copy(source: string) {
  const copyResult = {
    to: function (destination: string) {
      const toFunction = function (segmentEventProperties: EventProperties) {
        if (source === '.') {
          return {
            [destination]: [toParameters(filter(isPrimitive, segmentEventProperties))]
          } as EventProperties;
        }
        if (has(source, segmentEventProperties)) {
          return { [destination]: segmentEventProperties[source] };
        }
        return {} as EventProperties;
      };
      toFunction.operation = 'copy';
      return toFunction;
    }
  };
  return copyResult;
}

export function remove(source: string) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeFunction = function (segmentEventProperties: EventProperties) {
    return { __omit: source } as EventProperties;
  };
  removeFunction.operation = 'remove';
  return removeFunction;
}

/**
 * move: A fluent API for giving the converter instructions for changing the
 * Segment event to a GA4 recommended event.
 *
 * Usage: `move('source').to('destination')`
 * The return value is a function that will take the segment event properties, and
 * return an object of the form `{ destination: value }`.
 * These objects will eventually be merged with the original event, and the whole
 * thing will be converted into GA4 format.
 *
 * If the "source" is ".", then instead of just moving one property, it will move
 * all properties which have a primitive value (string | number | boolean | null).
 *
 * `move` differs from `copy` in that it also returns a list of properties to omit
 * from the final result, which will be stripped during the process, along with the
 * "omit" data.
 * @param source
 * @returns `(segmentEventProperties) => { destination: value, __omit: [string] }`
 */
export function move(source: string) {
  const moveResult = {
    to: function (destination: string) {
      const toFunction = function (segmentEventProperties: EventProperties) {
        if (source === '.') {
          return {
            __omit: Object.keys(segmentEventProperties),
            ...copy(source).to(destination)(segmentEventProperties)
          } as EventProperties;
        }
        return {
          __omit: [source],
          ...copy(source).to(destination)(segmentEventProperties)
        } as EventProperties;
      };
      toFunction.operation = 'move';
      return toFunction;
    }
  };
  return moveResult;
}

export function getNewEventName(originalName: string, using: ConversionInstructions) {
  const maybeInstruction = using[originalName];
  if (maybeInstruction == null) return normalizeName(originalName);
  const newName = head(filter(is(String), maybeInstruction));
  return newName || normalizeName(originalName);
}

/**
 * convert: This is the core function that accepts the instructions for
 * converting a Segment event to a GA4 event.  It's expected to be run
 * once for each event converted.
 *
 * @param event
 * @param using
 * @returns `EventParameters`
 */
export function convert(event: SegmentEvent, using: ConversionInstructions): EventParameters {
  if (event == null || event.properties == null || event.event == null) return {};
  const properties = event.properties;
  if (!has(event.event, using)) return toParameters(event.properties);
  const instructions = using[event.event];
  const adjusters = instructions.filter(
    (instruction): instruction is Adjuster => !is(String, instruction)
  );
  // Adjustments are all of the changes that we're making to the object, as objects
  const adjustments = adjusters.map(fn => fn(properties));
  const omits = reject(isNil, uniq(flatten(pluck('__omit', adjustments))));
  const cleanedAdjustments = map(omit(['__omit']), adjustments);
  const cleanedProperties = omit(omits, properties);
  const finalProperties = mergeAll([cleanedProperties, ...cleanedAdjustments]);
  return toParameters(finalProperties);
}
