import React, { ReactNode, useEffect, useContext, createContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAdminMode } from '../AdminMode/AdminModeProvider';

export const LazyLoadContext = createContext({ enable: false });

export const DisableLazyLoad: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <LazyLoadContext.Provider value={{ enable: false }}>{children}</LazyLoadContext.Provider>
);

export const EnableLazyLoad: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <LazyLoadContext.Provider value={{ enable: true }}>{children}</LazyLoadContext.Provider>
);

export type LazyLoadProps = {
  lazyload?: boolean;
  fallback?: ReactNode;
  onLoad?: () => void;
  children?: React.ReactNode;
};

export const LazyLoad: React.FC<LazyLoadProps> = ({
  lazyload = true,
  fallback,
  onLoad,
  children
}) => {
  const { enable } = useContext(LazyLoadContext);
  const [ref, inView] = useInView({ triggerOnce: true });
  const { enabled: lazyLoadDisabledWhileOnAdminMode } = useAdminMode();

  useEffect(() => {
    if (inView) onLoad?.();
  }, [inView, onLoad]);

  if (!enable || !lazyload || inView || lazyLoadDisabledWhileOnAdminMode) {
    return <>{children}</>;
  }

  return <div ref={ref}>{fallback}</div>;
};
