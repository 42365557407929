export const throwQueryError = (error: Error) => {
  // don't throw an error if the store was reset while query was in flight, the query will be refetched
  if (
    error.message ===
    'Network error: Store reset while query was in flight (not completed in link chain)'
  )
    return;

  throw error;
};
