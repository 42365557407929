import React, { useState } from 'react';
import { Stack, Expandable, ControlLink, Text } from '@moda/om';
import { hrefFor } from '../../../../routers';

import './SiteFooterMobileSections.scss';

type Link = { label: string; href: string };

type Item = Link | React.ReactElement;

type Section = {
  label: string;
  items: Item[];
};

const isLink = (item: Item): item is Link => !React.isValidElement(item);

const SECTIONS: Section[] = [
  {
    label: 'How to Shop',
    items: [
      { label: 'Moda Rewards', href: '/rewards' },
      { label: 'Moda Private', href: '/editorial/moda-private' },
      { label: 'Moda Network', href: '/moda-network' }
    ]
  },
  {
    label: 'Shipping & Returns',
    items: [
      {
        label: 'Track An Order',
        href: 'https://help.modaoperandi.com/hc/en-us/articles/210056706-Tracking-Your-Order'
      },
      {
        label: 'Return An Order',
        href: hrefFor.RequestReturnPage()
      },
      {
        label: 'Returns & Refunds',
        href: 'https://help.modaoperandi.com/hc/en-us/sections/202224966-Returns-Refunds'
      },
      {
        label: 'Shipping Times & Costs',
        href: 'https://help.modaoperandi.com/hc/en-us/articles/211521843-Shipping-Times-Costs'
      }
    ]
  },
  {
    label: 'Client Services',
    items: [
      {
        label: 'Contact Us',
        href: '/about#contact'
      },
      {
        label: 'Email Us',
        href: 'mailto:care@modaoperandi.com'
      },
      {
        label: 'FAQS',
        href: 'https://help.modaoperandi.com/hc/en-us'
      }
    ]
  },
  {
    label: 'About MODA',
    items: [
      { label: 'Careers', href: '/about#careers' },
      { label: 'About Us', href: '/editorial/about-us' },
      { label: 'Affiliates & Partners', href: '/editorial/affiliate' }
    ]
  }
];

export const SiteFooterMobileSections: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<string>();

  const toggleSection = (section: string) =>
    setExpandedSection(expandedSection === section ? undefined : section);

  return (
    <Stack className="SiteFooterMobileSections" space={0}>
      {SECTIONS.map(section => (
        <Expandable
          key={section.label}
          name={<Text className="SiteFooterMobileSections__section-title">{section.label}</Text>}
          icon="chevron"
          expanded={expandedSection === section.label}
          onToggle={() => toggleSection(section.label)}
        >
          <Stack space={3} alignItems="flex-start">
            {section.items.map(item =>
              isLink(item) ? (
                <ControlLink key={item.label} href={item.href} underlined={false} target="_blank">
                  {item.label}
                </ControlLink>
              ) : (
                item
              )
            )}
          </Stack>
        </Expandable>
      ))}
    </Stack>
  );
};
