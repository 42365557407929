import { noop } from '@moda/portal-stanchions';
import React, { createContext, useReducer, useCallback } from 'react';
import { Dialog, DialogProps } from './Dialog';

type ShowDialogOptions = Omit<DialogProps, 'show'>;

export const DialogContext = createContext<{
  showDialog: (options?: ShowDialogOptions) => void;
  hideDialog: () => void;
}>({
  showDialog: noop,
  hideDialog: noop
});

type State = DialogProps;

type Action = { type: 'SHOW_DIALOG'; payload?: ShowDialogOptions } | { type: 'HIDE_DIALOG' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SHOW_DIALOG':
      return {
        ...state,
        show: true,
        message: undefined,
        buttonText: undefined,
        onHide: undefined,
        ...action.payload
      };
    case 'HIDE_DIALOG':
      return { ...state, show: false };
    default:
      return state;
  }
};

export const DialogProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { show: false });

  const showDialog = useCallback((options?: ShowDialogOptions) => {
    dispatch({ type: 'SHOW_DIALOG', payload: options });
  }, []);

  const hideDialog = useCallback(() => {
    dispatch({ type: 'HIDE_DIALOG' });
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog }}>
      <Dialog
        {...state}
        onHide={() => {
          hideDialog();
          state.onHide?.();
        }}
      />
      {children}
    </DialogContext.Provider>
  );
};
