import React, { useMemo, useState } from 'react';
import { Divider, Stack } from '@moda/om';
import { DesktopNavigationMainContentCategory } from '../DesktopNavigationMainContentCategory';
import { DesktopNavigationPulldown } from '../DesktopNavigationPulldown';
import { DesktopSiteNavItemLoading } from '../../SiteNav/DesktopSiteNav/DesktopSiteNavItemLoading';
import { useDesktopNavigationContext } from '../DesktopNavigationProvider';

import './DesktopNavigationMainContent.scss';

export const DesktopNavigationMainContent = () => {
  const { selectedVerticalData, selectedVerticalId, verticals } = useDesktopNavigationContext();

  const [activeCategory, setActiveCategory] = useState<string>();

  const pulldownContent = useMemo(() => {
    if (!selectedVerticalData) return;

    if (!selectedVerticalId) return;

    if (!activeCategory) return;

    const content = selectedVerticalData.items.find(item => item.id === activeCategory);

    if (!content) return;

    if (!content.units.length) return;

    return content;
  }, [activeCategory, selectedVerticalData, selectedVerticalId]);

  if (!verticals) return;

  return (
    <div
      className="DesktopNavigationMainContent"
      data-testid="DesktopNavigationMainContent"
      onMouseLeave={() => setActiveCategory(undefined)}
    >
      <Stack className="DesktopNavigationMainContent__items" space={3}>
        <Divider className="DesktopNavigationMainContent__divider" color="klein-blue" />

        {!selectedVerticalData && <DesktopSiteNavItemLoading />}

        {selectedVerticalData && (
          <Stack
            direction="horizontal"
            className="DesktopNavigationMainContent__item"
            justifyContent="center"
            space={5}
          >
            {selectedVerticalData.items.map(item => (
              <DesktopNavigationMainContentCategory
                key={item.id}
                category={item}
                selected={activeCategory === item.id}
                onSelect={() => setActiveCategory(item.id)}
                onClickCategory={() => setActiveCategory(undefined)}
              />
            ))}
          </Stack>
        )}
      </Stack>

      {pulldownContent && (
        <DesktopNavigationPulldown
          content={pulldownContent}
          onHide={() => setActiveCategory(undefined)}
        />
      )}
    </div>
  );
};
