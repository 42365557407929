import { useEffect, useRef } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { scrollLazyCellIntoView } from '../../lib/scrollLazyCellIntoView';
import { findRouteFor } from '../../routers';

const getVariantCell = (id?: string) => document.querySelector(`[data-variant-pk="${id}"]`);

export const ScrollToTop: React.FC = () => {
  const history = useHistory();
  const previousLocation = useRef(history.location);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const previousPathname = previousLocation.current.pathname;
      previousLocation.current = location;

      if (location.pathname === previousPathname) return;

      const previousRoute = findRouteFor(previousPathname);

      // when going back from a PDP, scroll to the variant cell that led to the PDP if possible
      if (action === 'POP' && previousRoute?.key === 'ProductDetailPage') {
        const match = matchPath<{ variantId: string }>(previousPathname, previousRoute);
        const variantId = match?.params.variantId;
        if (getVariantCell(variantId))
          return setTimeout(() => scrollLazyCellIntoView(() => getVariantCell(variantId)), 0);
      }

      // otherwise scroll to top on every pathname change
      setTimeout(() => window.scrollTo(0, 0), 0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};
