import React, { useState, useEffect } from 'react';
import {
  BreakpointProvider as OmBreakpointProvider,
  BreakpointProviderProps as OmBreakpointProviderProps
} from '@moda/om';

export type BreakpointProviderProps = {
  ssrWidth?: number;
  children?: React.ReactNode;
};

export const BreakpointProvider: React.FC<BreakpointProviderProps> = ({ ssrWidth, children }) => {
  const [defaultWidth, setDefaultWidth] =
    useState<OmBreakpointProviderProps['defaultWidth']>(ssrWidth);

  useEffect(() => {
    setDefaultWidth('auto');
  }, []);

  return <OmBreakpointProvider defaultWidth={defaultWidth}>{children}</OmBreakpointProvider>;
};
