import React, { useCallback, useEffect, useState } from 'react';
import { Clickable, Divider, Stack, Text } from '@moda/om';
import { useFeatureFlag } from '@moda/portal-stanchions';
import ChevronRightIcon from '@moda/icons/chevron-right-12';
import { RefreshedMobileSiteNavMenuVerticalCategory } from '../RefreshedMobileSiteNavMenuVerticalCategory';
import { useRefreshedMobileSiteNavMenuQuery } from '../useRefreshedMobileSiteNavMenuQuery';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';
import { RefreshedMobileSiteNavMenuMainCategoryLink } from '../RefreshedMobileSiteNavMenuMainCategoryLink';
import { useShowHolidayHomeLinkFeatureFlag } from '../../../../hooks/useHolidayLinkFeatureFlag';
import { RefreshedMobileSiteNavMenuVerticalImages } from './RefreshedMobileSiteNavMenuVerticalImages';

import './RefreshedMobileSiteNavMenuVertical.scss';

type ImageCategory = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['imageCategories'][number];

const DEFAULT_IMAGE_CATEOGRY_NAME = 'clothing';
const FASHION_TAB_NAME = 'fashion';

interface Props {
  selectedTab?: string;
  onResetScroll: () => void;
  onClose: () => void;
}

export const RefreshedMobileSiteNavMenuVertical: React.FC<Props> = ({
  selectedTab,
  onResetScroll,
  onClose
}) => {
  const { tabs } = useRefreshedMobileSiteNavMenuQuery();
  const categoryFromFeatureFlag = useFeatureFlag<undefined | string>(
    'refreshed-mobile-nav-fashion-tab-image-category-name',
    [
      {
        default: undefined
      },
      {
        getFromTreatment: treatment => treatment.toString()
      }
    ]
  );

  const showHolidayHomeLink = useShowHolidayHomeLinkFeatureFlag();

  const getCurrentCategory = useCallback(
    (imageCategories: ImageCategory[]) => {
      if (!selectedTab) return;

      if (imageCategories.length === 0) return;

      if (selectedTab === FASHION_TAB_NAME) {
        const imagesFromFeatureFlag = imageCategories.find(
          category => category.name === categoryFromFeatureFlag
        )?.images;

        if (imagesFromFeatureFlag && imagesFromFeatureFlag.length) {
          return imagesFromFeatureFlag;
        }

        const imagesFromDefaultCategory = imageCategories.find(
          category => category.name === DEFAULT_IMAGE_CATEOGRY_NAME
        )?.images;

        return imagesFromDefaultCategory;
      }

      return imageCategories[0].images;
    },
    [categoryFromFeatureFlag, selectedTab]
  );

  useEffect(() => onResetScroll(), [onResetScroll, selectedTab]);

  const tabData = tabs?.find(tab => tab.label.toLowerCase() === selectedTab?.toLowerCase());
  const [expandedSection, setExpandedSection] = useState<string | undefined>(undefined);
  if (!tabData) return null;

  const { mainCategories, imageCategories } = tabData;

  const currentyCategoryImages = getCurrentCategory(imageCategories);

  if (!mainCategories) return null;

  const options =
    mainCategories.length === 1 ? mainCategories[0].mainCategoryItems : mainCategories;

  if (!options) return null;

  return (
    <Stack className="RefreshedMobileSiteNavMenuVertical" space={4}>
      {showHolidayHomeLink && (
        <>
          <Clickable
            className="RefreshedMobileSiteNavMenuMainCategoryLink__title"
            href="/editorial/the-holiday-guide"
          >
            <Stack
              alignItems="center"
              direction="horizontal"
              justifyContent="space-between"
              space={2}
            >
              <Text>Holiday</Text>
              <div>
                <ChevronRightIcon />
              </div>
            </Stack>
          </Clickable>

          <Divider />
        </>
      )}

      <RefreshedMobileSiteNavMenuMainCategoryLink selectedTab={selectedTab} />

      <Divider />

      {options.map((option, index) => (
        <RefreshedMobileSiteNavMenuVerticalCategory
          key={index + tabData.label}
          data={option}
          onClose={onClose}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
          label={tabData.label}
        />
      ))}

      <RefreshedMobileSiteNavMenuVerticalImages images={currentyCategoryImages} />
    </Stack>
  );
};
