import React from 'react';
import { Stack } from '@moda/om';
import SocialInstagramIcon from '@moda/icons/social-instagram-16';
import SocialFacebookIcon from '@moda/icons/social-facebook-16';
import SocialTwitterIcon from '@moda/icons/social-twitter-16';
import SocialPinterestIcon from '@moda/icons/social-pinterest-16';
import './SiteFooterMobileSocialIcons.scss';

export const SiteFooterMobileSocialIcons: React.FC = () => (
  <Stack
    className="SiteFooterMobileSocialIcons"
    space={2}
    direction="horizontal"
    justifyContent="space-between"
  >
    <a
      href="https://www.instagram.com/modaoperandi/"
      data-test-id="moda-on-ig"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Moda Operandi on Instagram"
    >
      <SocialInstagramIcon
        className="SiteFooterMobileSocialIcons__icon"
        height="20px"
        width="20px"
      />
    </a>

    <a
      href="https://www.facebook.com/ModaOperandi"
      target="_blank"
      data-test-id="moda-on-fb"
      rel="noopener noreferrer"
      aria-label="Moda Operandi on Facebook"
    >
      <SocialFacebookIcon
        className="SiteFooterMobileSocialIcons__icon"
        height="20px"
        width="20px"
      />
    </a>

    <a
      href="https://twitter.com/modaoperandi"
      target="_blank"
      data-test-id="moda-on-twitter"
      rel="noopener noreferrer"
      aria-label="Moda Operandi on Twitter"
    >
      <SocialTwitterIcon className="SiteFooterMobileSocialIcons__icon" height="20px" width="20px" />
    </a>

    <a
      href="https://www.pinterest.com/modaoperandi"
      target="_blank"
      data-test-id="moda-on-pinterest"
      rel="noopener noreferrer"
      aria-label="Moda Operandi on Pinterest"
    >
      <SocialPinterestIcon
        className="SiteFooterMobileSocialIcons__icon"
        height="20px"
        width="20px"
      />
    </a>
  </Stack>
);
