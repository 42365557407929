import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Link } from '../../../../Link';
import { DesktopSiteNavListColumnFragment } from '../../../../../generated/types';
import { tc } from '../../../../../lib/trackingContext';
import { NBSP } from '../../../../../constants';

import './DesktopSiteNavListColumn.scss';

export const DESKTOP_SITE_NAV_LIST_COLUMN_FRAGMENT = gql`
  fragment DesktopSiteNavListColumnFragment on NavListColumn {
    columnType
    lists {
      label
      items {
        label
        href: url
      }
    }
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  navListColumn: DesktopSiteNavListColumnFragment;
  parentLabel: string;
}

// Editors use an unspecified number of consecutive dashes to create fake divisions in the menu
const LABEL_DIVIDER_HACK = /^-+$/;

export const DesktopSiteNavListColumn: React.FC<Props> = ({
  className,
  parentLabel,
  navListColumn
}) => {
  const handleTrackNavColumnClicked = useCallback(
    (url: string | null, text: string | null, event: React.MouseEvent) => {
      tc.track('Navigation Item Clicked', {
        addToTrackingEvent: {
          url,
          text,
          label: text,
          hierarchy: `${parentLabel}/${text}`,
          coordinateX: event?.pageX || 0,
          coordinateY: event?.pageY || 0
        }
      });
    },
    [parentLabel]
  );

  return (
    <div className={classNames('DesktopSiteNavListColumn', className)}>
      {navListColumn.lists?.map(({ label, items }, index) => (
        <div className="DesktopSiteNavListColumn__list" key={index}>
          <h4 className="DesktopSiteNavListColumn__header">
            {label
              ? label
              : // Utilize label as a correctly sized placeholder if no label is present
                NBSP}
          </h4>

          {items.map(
            (item, index) =>
              item.href &&
              item.label &&
              (LABEL_DIVIDER_HACK.test(item.label) ? (
                <hr key={index} className="DesktopSiteNavListColumn__divider" />
              ) : (
                <Link
                  className="DesktopSiteNavListColumn__link"
                  key={item.href + index}
                  to={item.href}
                  onClick={event => handleTrackNavColumnClicked(item.href, item.label, event)}
                >
                  {item.label}
                </Link>
              ))
          )}
        </div>
      ))}
    </div>
  );
};
