import { defaultTo, evolve, omit, pipe } from 'ramda';
import { isClientSide } from '@moda/portal-stanchions';
import { UseUserQuery } from '../generated/types';
import { tc } from './trackingContext';
import { AuthPayloadSuccess } from './authenticate';

type User = NonNullable<UseUserQuery['user']>;

export const identifyUser = (user: AuthPayloadSuccess | User) => {
  if (!isClientSide()) return;

  if (user.id != null) {
    tc.identify(user.id, pipe(omit(['id']), evolve({ clientTier: defaultTo('NA') }))(user));
  } else {
    tc.identify();
  }
};
