import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { Loading } from '../components/Loading';
import { renderWithStatusCode } from './renderWithStatusCode';

const NotFoundPage = loadable(() => import('../pages/NotFoundPage'), { fallback: <Loading /> });

export const NotFoundRoute = () => (
  <Route component={renderWithStatusCode(NotFoundPage, StatusCodes.NOT_FOUND)} />
);
