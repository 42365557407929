import React, { Fragment, useState, useEffect, useCallback, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  onResetCache?: () => void;
}

// reload the app when the Apollo cache is reset
// ideally we would just use client.refetchObservableQueries but there are some problems with that:
// - if you want to skip a query for logged out users (e.g. get cart), it will be refetched anyways
// - the useQuery hooks show the old data for a second before switching to the new data,
//   altho this is possibly fixable by using the notifyOnNetworkStatusChange flag
export const ReloadOnResetCache: React.FC<Props> = ({ children, onResetCache }) => {
  const [key, setKey] = useState(Date.now());
  const handleResetCache = useCallback(() => {
    setKey(Date.now());
    onResetCache?.();
  }, [setKey, onResetCache]);

  useEffect(() => {
    window.addEventListener('resetcache', handleResetCache);
    return () => window.removeEventListener('resetcache', handleResetCache);
  });

  return <Fragment key={key}>{children}</Fragment>;
};
