/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-length */
// Much of this is evidon's generated boilerplate tag code, which is converted to TS here.

import waitUntil from 'wait-until-promise';
import { cond, T } from 'ramda';
import { tc } from '../trackingContext';

declare global {
  interface Window {
    // We don't have control over what evidon does, and wants us to do, with
    // the `evidon` namespace.  So, it's most appropriate to leave it as "any".
    evidon: any;
  }
}

const WAIT_TIME_MS = 500;
const INTERVAL = 4;
const WAIT_BEFORE_RELOAD_MS = 500;
const ONE_YEAR = 365;

const GDPR_COUNTRIES = {
  // These are Evidon's numeric codes for countries
  Austria: 19,
  Belgium: 20,
  Bulgaria: 30,
  Croatia: 61,
  Cyprus: 37,
  Czechia: 27,
  Denmark: 15,
  Estonia: 32,
  Finland: 25,
  France: 5,
  Germany: 3,
  Greece: 29,
  Hungary: 24,
  Ireland: 16,
  Italy: 7,
  Latvia: 33,
  Lithuania: 34,
  Luxembourg: 28,
  Malta: 36,
  Netherlands: 8,
  Poland: 18,
  Portugal: 26,
  Romania: 31,
  Slovakia: 23,
  Slovenia: 35,
  Spain: 4,
  Sweden: 22,
  'United Kingdom': 6
};

const RESTRICTED_STATES = ['ca', 'nv'];

function isGDPR(id: number) {
  return Object.values(GDPR_COUNTRIES).includes(id);
}

function isRestrictedState(code?: string) {
  return code != null && RESTRICTED_STATES.includes(code);
}

const existingChoice = cond([
  [(cookie: string) => cookie.includes('_evidon_consent_cookie'), () => 'accepted'],
  [(cookie: string) => cookie.includes('_evidon_suppress_notification_cookie'), () => 'rejected'],
  [T, () => 'undecided']
])(document.cookie);

export async function determinePolicy() {
  const country = await waitUntil(
    () => window.evidon?.notice?.country || window.evidon?.location,
    WAIT_TIME_MS,
    INTERVAL
  ).then(() => window.evidon?.notice?.country || window.evidon?.location);

  // if globalPrivacyControl exists and true, don't load tags. Otherwise, go with the rest of the logic
  if (existingChoice === 'accepted') return { policy: ['load tags'] };
  if (
    existingChoice === 'rejected' ||
    (!isGDPR(country.id) && Object.prototype.hasOwnProperty.call(navigator, 'globalPrivacyControl'))
  )
    return { policy: ["don't load tags"] };

  if (isGDPR(country.id) || isRestrictedState(country.stateCode))
    return { policy: ["don't load tags"] };

  return { policy: ['load tags'] };
}

const MAX_TIMEOUT_MS = 2147483647;
const INTERVAL_MS = 100;

const waitForElement = (selector: string) =>
  waitUntil(
    () => (document.querySelector(selector) as HTMLElement | null) != null,
    MAX_TIMEOUT_MS,
    INTERVAL_MS
  ).then(() => document.querySelector(selector) as HTMLElement);

export function initializeEvidon() {
  const id = 5504; // Evidon client ID / customer number

  waitForElement('.evidon-prefdiag-acceptbtn').then((element: HTMLElement) => {
    if (!isGDPR(window.evidon.notice.country.id)) {
      if ((element as HTMLElement).textContent === 'Opt-Out') {
        if (existingChoice === 'rejected') {
          (element as HTMLElement).textContent = 'Opt-In';
          (element as HTMLElement).addEventListener('click', function () {
            window.evidon.notice.consentGiven();
          });
        } else if (existingChoice === 'undecided') {
          (element as HTMLElement).addEventListener('click', function () {
            window.evidon.notice.dropSuppressionCookie(ONE_YEAR);
            window.evidon.preferencesDialog.doWithdrawConsent();
          });
        }
      } else if (
        (element as HTMLElement).textContent === 'Opt-In' &&
        existingChoice === 'accepted'
      ) {
        (element as HTMLElement).textContent = 'Opt-Out';
        (element as HTMLElement).addEventListener('click', function () {
          window.evidon.notice.dropSuppressionCookie(ONE_YEAR);
          window.evidon.preferencesDialog.doWithdrawConsent();
        });
      }
    }
  });

  function append(scriptid: string, url: string, async: boolean) {
    const d = document;
    const sn = 'script';
    const f = d.getElementsByTagName(sn)[0];
    const s = d.createElement(sn);
    s.async = async;
    s.id = scriptid;
    s.src = url;
    s.charset = 'utf-8';
    if (!f?.parentNode) {
      d.head.appendChild(s);
    } else {
      f.parentNode.insertBefore(s, f);
    }
  }

  function is2parttld(value: string) {
    const tldindicators = ['co', 'com', 'info', 'web', 'info', 'gov', 'edu', 'biz', 'net', 'org'];
    const countryindicators = [
      'uk',
      'us',
      'fr',
      'es',
      'de',
      'at',
      'au',
      'ae',
      'be',
      'br',
      'ca',
      'ch',
      'cn',
      'co',
      'cz',
      'dk',
      'eg',
      'eu',
      'fi',
      'gb',
      'gr',
      'hk',
      'hr',
      'hu',
      'ie',
      'in',
      'jp',
      'mx',
      'nl',
      'no',
      'nz',
      'pl',
      'ro',
      'ru',
      'se'
    ];
    return tldindicators.indexOf(value) !== -1 || countryindicators.indexOf(value) !== -1;
  }

  function getRootDomain() {
    let rootDomain = '';
    const parts = window.location.hostname.split('.');
    if (parts.length === 2) {
      rootDomain = parts[0];
    } else if (parts.length > 2) {
      // see if the next to last value is a common tld
      const part = parts[parts.length - 2];
      if (is2parttld(part)) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        rootDomain = parts[parts.length - 3]; // go back one more
      } else {
        rootDomain = part;
      }
    }

    return rootDomain;
  }

  window.evidon = window.evidon ?? {};
  window.evidon.id ??= id;
  window.evidon.test ??= false; // set to true for non-production testing.

  const cdn = '//c.evidon.com/';
  const rootDomain = getRootDomain();
  const noticecdn = cdn + 'sitenotice/';
  append('evidon-notice', noticecdn + 'evidon-sitenotice-tag.js', false);
  append('evidon-location', cdn + 'geo/country.js', true);
  append('evidon-themes', noticecdn + id + '/snthemes.js', true);
  if (rootDomain) {
    append(
      'evidon-settings',
      noticecdn + id + '/' + rootDomain + (window.evidon.test ? '/test' : '') + '/settings.js',
      true
    );
  }

  window.evidon.priorConsentCallback = function () {
    // add the tags which need to wait for prior consent
    // here.  This should be all your advertising tags and
    // probably most of your social and tracking tags.
  };

  window.evidon.closeCallback = function () {
    // this is executed if the user closed a UI element without either Accepting
    // (providing consent) or Declining (declining to provide consent).
  };

  window.evidon.consentWithdrawnCallback = function () {
    // this is executed if the user withdraws consent and elects to
    // no longer allow technologies to run on the site.
    window.evidon.notice.dropSuppressionCookie(ONE_YEAR);
    tc.track('Consent Withdrawn');
    window.setTimeout(() => window.location.reload(), WAIT_BEFORE_RELOAD_MS);
  };

  window.evidon.consentDeclinedCallback = function () {
    // this is executed if the user explicitly declines giving consent by
    // using a Decline button
    window.evidon.notice.dropSuppressionCookie(ONE_YEAR);
    tc.track('Decline Given');
    window.setTimeout(() => window.location.reload(), WAIT_BEFORE_RELOAD_MS);
  };

  // Evidon doesn't give us a callback that only runs when someone clicks
  // on the "accept" button.  So, we'll wrap the "consentGiven" method to create one.
  waitUntil(() => window.evidon?.notice?.consentGiven, WAIT_TIME_MS, INTERVAL).then(() => {
    const original = window.evidon.notice.consentGiven;
    window.evidon.notice.consentGiven = function (...argv: any[]) {
      original.apply(window.evidon.notice, argv);
      tc.track('Consent Given');
      window.setTimeout(() => window.location.reload(), WAIT_BEFORE_RELOAD_MS);
    };
  });
}
