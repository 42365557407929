import React from 'react';
import { Clickable, Stack, Text } from '@moda/om';
import SocialPhoneIcon from '@moda/icons/social-phone-16';
import SocialFacebookIcon from '@moda/icons/social-facebook-16';
import SocialInstagramIcon from '@moda/icons/social-instagram-16';
import SocialPinterestIcon from '@moda/icons/social-pinterest-16';
import SocialXIcon from '@moda/icons/social-x-20';
import { useCountry } from '../../CountrySelector';

import './SiteFooterBasement.scss';

export const SiteFooterBasement: React.FC = () => {
  const { country } = useCountry();
  const showPrivacyChoicesLink = country.alpha2Code === 'US';

  return (
    <Stack
      space={1}
      direction="horizontal"
      alignItems="center"
      justifyContent="space-between"
      className="SiteFooterBasement"
    >
      <Stack space={1} direction="horizontal" className="SiteFooterBasement__left">
        <a
          className="SiteFooterInlineItem SiteFooterInlineItem__app"
          data-test-id="download-the-moda-app"
          href="https://itunes.apple.com/us/app/moda-operandi/id781702094"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialPhoneIcon className="SiteFooterDesktop__icon" />
          Download the Moda App
        </a>

        <Stack space={2} direction="horizontal" className="SiteFooterInlineItem">
          <a
            href="https://www.instagram.com/modaoperandi/"
            data-test-id="moda-on-ig"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Instagram"
            className="SiteFooterDesktop__icon"
          >
            <SocialInstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/ModaOperandi"
            target="_blank"
            data-test-id="moda-on-fb"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Facebook"
            className="SiteFooterDesktop__icon"
          >
            <SocialFacebookIcon />
          </a>
          <a
            href="https://twitter.com/modaoperandi"
            target="_blank"
            data-test-id="moda-on-twitter"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Twitter"
            className="SiteFooterDesktop__icon"
          >
            <SocialXIcon className="SiteFooterDesktop__icon" />
          </a>
          <a
            href="https://www.pinterest.com/modaoperandi"
            target="_blank"
            data-test-id="moda-on-pinterest"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Pinterest"
            className="SiteFooterDesktop__icon"
          >
            <SocialPinterestIcon />
          </a>
        </Stack>

        <a className="SiteFooterInlineItem" data-test-id="terms-and-conditions" href="/terms">
          Terms &amp; Conditions
        </a>

        <a className="SiteFooterInlineItem" data-test-id="privacy-policy" href="/privacy">
          Privacy Policy
        </a>

        {showPrivacyChoicesLink && (
          <Clickable
            className="SiteFooterInlineItem"
            href="#"
            onClick={() => window.evidon.notice.showOptions()}
          >
            <Text treatment="body2" color="persian-blue">
              Your Privacy Choices
            </Text>
          </Clickable>
        )}

        <a
          className="SiteFooterInlineItem SiteFooterInlineItem__legal"
          data-test-id="terms-and-conditions"
          href="/legal"
        >
          Legal
        </a>
      </Stack>
      <Stack space={2} direction="vertical">
        <img
          className="SiteFooterBasement__shapes"
          src="/dist/public/moda_logo.svg"
          alt="Moda shapes"
        />
        <div
          data-test-id="moda-copyright"
          className="SiteFooterInlineItem SiteFooterInlineItem__copyright"
        >
          © {new Date().getFullYear()} Moda Operandi, Inc. All Rights Reserved.
        </div>
      </Stack>
    </Stack>
  );
};
