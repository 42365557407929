import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import sha from 'sha.js';
import { Stack, Text, TextInput, Clickable, ControlLink } from '@moda/om';
import ChevronRightIcon from '@moda/icons/chevron-right-20';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { hrefFor } from '../../../../routers';
import { useEmailSubscribe } from '../../../../hooks/useEmailSubscribe';

import './SiteFooterMobileMailingList.scss';

export const SiteFooterMobileMailingList: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const {
    subscribe,
    isExistingSubscription,
    error,
    mailValidationError,
    isPhoneNumberValid,
    phoneNumberValidationError,
    phoneNumberEnabled
  } = useEmailSubscribe();

  const [emailStep, setEmailStep] = useState(true);
  const [smsStep, setSmsStep] = useState<boolean>(false);
  const [confirmationStep, setConfirmationStep] = useState<boolean>(false);

  const emailSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setEmailStep(false);
      if (phoneNumberEnabled) {
        setSmsStep(true);
      } else {
        subscribe({ email, label: 'footer' });
        setConfirmationStep(true);
      }
    },
    [email, phoneNumberEnabled, setEmailStep, subscribe, setSmsStep, setConfirmationStep]
  );

  const smsSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!isPhoneNumberValid(phone)) return;
      subscribe({ email, phone, label: 'footer' });
      setSmsStep(false);
      setConfirmationStep(true);
    },
    [email, phone, subscribe, setSmsStep, setConfirmationStep, isPhoneNumberValid]
  );

  // The feature flag is a number, but we'll want a string to display.
  const welcomeOffer = useFeatureFlag('configuration-set-welcome-offer', [
    { default: '15' },
    { getFromTreatment: treatment => String(treatment) || '15' }
  ]);

  return (
    <Stack className="SiteFooterMobileMailingList__wrapper" space={2}>
      {emailStep && (
        <form onSubmit={emailSubmit}>
          <Text className="SiteFooterMobileMailingList__section-title" treatment="eyebrow">
            GET ON THE LIST
          </Text>
          <Text treatment="body1" color="cement">
            Receive {welcomeOffer}% off* your first app purchase and stay up to date on our latest
            arrivals, exclusive offers, and more with alerts sent directly to your inbox{' '}
            {phoneNumberEnabled && <> or phone via text.</>}
          </Text>

          <div className="SiteFooterMobileMailingList__input">
            <TextInput
              className="SiteFooterMobileMailingList__input-button"
              error={mailValidationError}
              onChange={setEmail}
              placeholder="Email Address"
              required
              type="email"
              value={email}
            />

            <Clickable
              aria-label="Submit email for our newsletter"
              className="SiteFooterMobileMailingList__button"
              type="submit"
            >
              <ChevronRightIcon />
            </Clickable>
          </div>
        </form>
      )}

      {smsStep && (
        <form className={classNames('SiteFooterMobileMailingList')} onSubmit={smsSubmit}>
          <Text className="SiteFooterMobileMailingList__section-title" treatment="eyebrow">
            You're Almost There
          </Text>
          <Text treatment="body2" color="cement">
            †By submitting this form, you agree to receive recurring automated promotional and
            personalized marketing text messages (e.g. cart reminders) from Moda Operandi at the
            cell number used when signing up. Consent is not a condition of any purchase. Reply HELP
            for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply.
          </Text>

          <div className="SiteFooterMobileMailingList__input">
            <TextInput
              className="SiteFooterMobileMailingList__input-button"
              error={phoneNumberValidationError}
              onChange={setPhone}
              placeholder="Mobile Phone"
              required
              type="tel"
              value={phone}
            />
            <Clickable
              aria-label="Submit email for our newsletter"
              className="SiteFooterMobileMailingList__button"
              type="submit"
            >
              SIGN UP FOR TEXTS
            </Clickable>
          </div>

          {error && (
            <div
              className={classNames('SiteFooterMobileMailingList__status', {
                'SiteFooterMobileMailingList__status--error': error
              })}
            >
              Unable to subscribe you at this time
            </div>
          )}

          {phoneNumberValidationError && (
            <div
              className={classNames('SiteFooterMailingList__status', {
                'SiteFooterMailingList__status--error': error
              })}
            >
              {phoneNumberValidationError}
            </div>
          )}
        </form>
      )}

      {confirmationStep && (
        <div className={classNames('SiteFooterMobileMailingList')}>
          <Text className="SiteFooterMobileMailingList__section-title" treatment="eyebrow">
            Check your {phoneNumberEnabled ? <span>texts</span> : <span>inbox</span>}
          </Text>
          <div className="SiteFooterMobileMailingList__confirmation">
            <Text
              className="SiteFooterMobileMailingList__confirmationtext"
              treatment="body1"
              color="cement"
            >
              {phoneNumberEnabled ? (
                <span>
                  Reply “Y” to confirm your subscription. We look forward to sharing early access on
                  products, exclusive offers, and more.
                </span>
              ) : (
                <span aria-label="Confirmation message">
                  A confirmation email will be sent to you shortly with your {welcomeOffer}% off
                  code.
                </span>
              )}
            </Text>
          </div>
        </div>
      )}

      {error && (
        <div
          className={classNames('SiteFooterMobileMailingList__status', {
            'SiteFooterMobileMailingList__status--error': error
          })}
        >
          Unable to subscribe you at this time
        </div>
      )}

      {isExistingSubscription && (
        <>
          <div
            className={classNames(
              'SiteFooterMobileMailingList__status',
              'SiteFooterMobileMailingList__status--success'
            )}
          >
            You're already on our newsletter list,{' '}
            <Link
              className="SiteFooterMobileMailingList__manage-subscriptions-link"
              to={`${hrefFor.UnsubscribePage()}?email_hash=${sha('sha256')
                .update(email)
                .digest('hex')}`}
            >
              click here
            </Link>{' '}
            to manage your subscriptions.
          </div>
          <div className="SiteFooterMobileMailingList__opt-out">
            To opt out,{' '}
            <Link
              className="SiteFooterMobileMailingList__opt-out-link"
              to={hrefFor.UnsubscribePage()}
            >
              unsubscribe here
            </Link>{' '}
            or in our emails.
          </div>
        </>
      )}
      <Stack space={2} className="SiteFooterMobileMailingList__terms">
        <Text treatment="body2" color="cement">
          <ControlLink
            to={hrefFor.TermsAndConditionsPage()}
            className="SiteFooterMobileMailingList__footer-link"
            underlined
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text treatment="body2" color="cement">
              Terms
            </Text>
          </ControlLink>{' '}
          &amp;{' '}
          <ControlLink
            to={hrefFor.TermsAndConditionsPage()}
            className="SiteFooterMobileMailingList__footer-link"
            underlined
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text treatment="body2" color="cement">
              Conditions
            </Text>
          </ControlLink>{' '}
          Apply
        </Text>
      </Stack>
    </Stack>
  );
};
