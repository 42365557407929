import React from 'react';
import { Clickable } from '@moda/om';
import ChevronLeftIcon from '@moda/icons/chevron-left-16';
import './MobileSiteNavMenuPaneBack.scss';

interface Props {
  children: string;
  onClick(): void;
}

export const MobileSiteNavMenuPaneBack: React.FC<Props> = ({ children, onClick }) => {
  return (
    <Clickable className="MobileSiteNavMenuPaneBack" onClick={onClick}>
      <ChevronLeftIcon />
      <h3 className="MobileSiteNavMenuPaneBack__name">{children}</h3>
    </Clickable>
  );
};
