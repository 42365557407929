import { ONE_YEAR_SECONDS } from '@moda/portal-stanchions';
import { useCallback, useMemo } from 'react';
import { useCookies } from '../useCookies';
import { Preferences, Preference, encode, decode } from './tools';

export const usePreferences = () => {
  const { cookies, setCookie } = useCookies();

  const preferences = useMemo(
    () => (decode(cookies.preferences || undefined) || {}) as Preferences,
    [cookies.preferences]
  );

  const setPreference = useCallback(
    <T extends Preference>(key: T, value: Preferences[T]) => {
      setCookie('preferences', encode({ ...preferences, [key]: value }), ONE_YEAR_SECONDS);
    },
    [preferences, setCookie]
  );

  const setPreferences = useCallback(
    (values: Partial<Preferences>) => {
      setCookie('preferences', encode({ ...preferences, ...values }), ONE_YEAR_SECONDS);
    },
    [preferences, setCookie]
  );

  return { ...preferences, setPreference, setPreferences };
};
