import { fetchFeatureFlags } from '../../components/DiscoveryFeatureFlagProvider/fetchFeatureFlags';
import { setFeatureFlags } from '../../components/DiscoveryFeatureFlagProvider/setFeatureFlags';
import { reportError } from '../../lib/reportError';

export const refetchFeatureFlags = async () => {
  try {
    await fetchFeatureFlags().then(setFeatureFlags);
  } catch (error) {
    reportError('REFETCH_FEATURE_FLAGS', error);
  }
};
