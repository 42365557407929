import { parameterize, VERTICALS } from '@moda/portal-stanchions';
import { CATEGORIES } from './categories';

export const ROUTE_VERTICALS = Object.values(VERTICALS)
  .map(vertical => parameterize(vertical))
  .join('|');
export const ROUTE_CATEGORIES = Object.values(CATEGORIES)
  .map(category => parameterize(category))
  .join('|');
export const VERTICALS_WITHOUT_HOME_PAGE = 'kids';
export const SECTIONS = 'new|sale';
