import React from 'react';
import loadable from '@loadable/component';
import { Switch } from 'react-router-dom';
import { SanitizeUrl } from '@moda/portal-stanchions';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { Loading } from '../components/Loading';
import { PageLayout } from '../components/Layouts';
import { NotFoundRoute } from './NotFoundRoute';
import { LEGACY_ROUTES, LEGACY_CATEGORY_ROUTE } from './LegacyRoutes';
import { PAGE_ROUTES } from './PageRoutes';
import { DEBUG_ROUTES } from './DebugRoutes';

const ErrorPage = loadable(() => import('../pages/ErrorPage'), {
  fallback: <Loading />
});

export const Router = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <PageLayout>
        <SanitizeUrl>
          <Switch>
            {DEBUG_ROUTES}
            {LEGACY_CATEGORY_ROUTE}
            {PAGE_ROUTES}
            {LEGACY_ROUTES}
            <NotFoundRoute />
          </Switch>
        </SanitizeUrl>
      </PageLayout>
    </ErrorBoundary>
  );
};
