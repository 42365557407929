import { useCallback, useState } from 'react';

export const isEmailValid = (email: string): { isValid: boolean; error: undefined | string } => {
  if (email === '') {
    return { isValid: false, error: 'You must enter your email address first.' };
  }

  if (
    /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
      email
    )
  ) {
    return { isValid: true, error: undefined };
  }

  return { isValid: false, error: 'The email address you provided is not valid.' };
};

export const useEmailVerification = () => {
  const [error, setError] = useState<undefined | string>(undefined);

  const isValid = useCallback((email: string) => {
    const { isValid, error } = isEmailValid(email);

    setError(error);

    return isValid;
  }, []);

  return { isValid, error };
};
