import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RefreshedMobileSiteNavMenuQuery,
  RefreshedMobileSiteNavMenuQueryVariables
} from '../../../generated/types';
import { useSkippableQuery } from '../../../hooks/useSkippableQuery';

export const REFRESHED_MOBILE_SITE_NAV_MENU_QUERY: TypedDocumentNode<
  RefreshedMobileSiteNavMenuQuery,
  RefreshedMobileSiteNavMenuQueryVariables
> = gql`
  query RefreshedMobileSiteNavMenuQuery($vertical: String!, $show_beauty: Boolean) {
    displayPage(slug: $vertical, gender: $vertical, show_beauty: $show_beauty) {
      data: refreshed_mobile_nav {
        tabs {
          label
          mainCategories: main_categories {
            label
            url
            styleProps: style_props {
              isBold: is_bold
              hasDividerAfter: has_divider_after
            }
            mainCategoryItems: main_category_items {
              subItems: sub_items {
                label
                url
                centerTitle: center_title
                listLinks: list_links {
                  label
                  url
                  isHighlighter: highlighter
                }
              }
            }
          }
          imageCategories: image_categories {
            name: category_name
            images {
              image
              headline
              anchor
              secondAnchor: second_anchor
              url
            }
          }
        }
      }
    }
  }
`;

export const useRefreshedMobileSiteNavMenuQuery = () => {
  const { data, loading, error } = useSkippableQuery(REFRESHED_MOBILE_SITE_NAV_MENU_QUERY, {
    variables: { vertical: 'women', show_beauty: true }
  });

  return { tabs: data?.displayPage?.data.tabs, loading, error };
};
