import React from 'react';
import { ControlLink, Stack } from '@moda/om';
import { hrefFor } from '../../../../routers';

import './SiteFooterBasementCheckoutMobile.scss';

export const SiteFooterBasementCheckoutMobile: React.FC = () => (
  <Stack space={2} className="SiteFooterBasementCheckoutMobile">
    <ControlLink
      className="SiteFooterBasementCheckoutMobile__download"
      href="https://itunes.apple.com/us/app/moda-operandi/id781702094"
      underlined={false}
    >
      Download the Moda App
    </ControlLink>

    <Stack space={2} direction="horizontal" justifyContent="center">
      <ControlLink
        to={hrefFor.TermsAndConditionsPage()}
        className="SiteFooterInlineItem"
        underlined={false}
      >
        Terms &amp; Conditions
      </ControlLink>

      <ControlLink
        to={hrefFor.PrivacyPolicyPage()}
        className="SiteFooterInlineItem"
        underlined={false}
      >
        Privacy Policy
      </ControlLink>
    </Stack>

    <Stack space={2} direction="horizontal" justifyContent="center">
      <div className="SiteFooterInlineItem">© {new Date().getFullYear()} Moda Operandi, Inc.</div>

      <div className="SiteFooterInlineItem">All Rights Reserved</div>
    </Stack>
  </Stack>
);
