import React, { FC, useCallback } from 'react';
import loadable from '@loadable/component';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { ModalOverlay } from '../../Modal';
import { Loading } from '../../Loading';

const Authentication = loadable(() => import('../Authentication'), {
  resolveComponent: exports => exports.Authentication,
  fallback: <Loading />
});

export const AuthenticationModal: FC = () => {
  const { show, defaultValues, closeAuthModal, handleAuthSuccess } = useAuthModal();

  const handleAuth = useCallback(() => {
    handleAuthSuccess();
    closeAuthModal();
  }, [closeAuthModal, handleAuthSuccess]);

  return (
    <ModalOverlay data-testid="authentication-modal" onClose={closeAuthModal} show={show}>
      <Authentication onAuth={handleAuth} modal defaultValues={defaultValues} />
    </ModalOverlay>
  );
};
