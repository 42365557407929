import React, { createContext, useReducer, useCallback, useMemo } from 'react';
import { noop } from '@moda/portal-stanchions';
import { EmailVerificationStatus, EmailVerificationStatusProps } from './EmailVerificationStatus';

type OmitFromUnion<U, K extends string> = U extends unknown ? Omit<U, K> : never;

type ShowEmailVerificationStatusModalOptions = OmitFromUnion<
  EmailVerificationStatusProps,
  'show' | 'onHide'
>;

export const EmailVerificationStatusModalContext = createContext<{
  showEmailVerificationStatusModal: (options: ShowEmailVerificationStatusModalOptions) => void;
  hideEmailVerificationStatusModal: () => void;
}>({ showEmailVerificationStatusModal: noop, hideEmailVerificationStatusModal: noop });

type State = OmitFromUnion<EmailVerificationStatusProps, 'onHide'>;

type Action =
  | {
      type: 'SHOW_EMAIL_VERIFICATION_STATUS_MODAL';
      payload: ShowEmailVerificationStatusModalOptions;
    }
  | { type: 'HIDE_EMAIL_VERIFICATION_STATUS_MODAL' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SHOW_EMAIL_VERIFICATION_STATUS_MODAL':
      return {
        show: true,
        ...action.payload
      };
    case 'HIDE_EMAIL_VERIFICATION_STATUS_MODAL':
      return { ...state, show: false };
    default:
      return state;
  }
};

export const EmailVerificationStatusModalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, { show: false, status: 'success' });

  const showEmailVerificationStatusModal = useCallback(
    (options: ShowEmailVerificationStatusModalOptions) => {
      dispatch({ type: 'SHOW_EMAIL_VERIFICATION_STATUS_MODAL', payload: options });
    },
    []
  );

  const hideEmailVerificationStatusModal = useCallback(() => {
    dispatch({ type: 'HIDE_EMAIL_VERIFICATION_STATUS_MODAL' });
  }, []);

  const value = useMemo(
    () => ({ showEmailVerificationStatusModal, hideEmailVerificationStatusModal }),
    [showEmailVerificationStatusModal, hideEmailVerificationStatusModal]
  );

  return (
    <EmailVerificationStatusModalContext.Provider value={value}>
      <EmailVerificationStatus {...state} onHide={() => hideEmailVerificationStatusModal()} />
      {children}
    </EmailVerificationStatusModalContext.Provider>
  );
};
