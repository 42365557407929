import { Country } from '@moda/portal-stanchions';
import React, { useCallback } from 'react';
import { CountrySelector, useSetCountry } from '../CountrySelector';
import { MobileSiteNavMenuPaneBack } from './MobileSiteNavMenuPaneBack';

interface Props {
  onClose(): void;
}

export const MobileSiteNavCountrySelector: React.FC<Props> = ({ onClose }) => {
  const { setCountry } = useSetCountry();

  const handleSelect = useCallback(
    (country: Country) => {
      setCountry(country);
      onClose();
    },
    [onClose, setCountry]
  );

  return (
    <>
      <MobileSiteNavMenuPaneBack onClick={onClose}>My Currency</MobileSiteNavMenuPaneBack>

      <CountrySelector onSelect={handleSelect} />
    </>
  );
};
