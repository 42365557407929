import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { RefreshedMobileSiteNavMenu } from './RefreshedMobileSiteNavMenu';

import './MobileSiteNavMenu.scss';

enum Mode {
  Resting,
  Mounted
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClose(): void;
}

export const MobileSiteNavMenu: React.FC<Props> = ({ onClose, ...rest }) => {
  const [mode, setMode] = useState(Mode.Resting);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleScrollTo = useCallback(
    (top: number) => {
      menuRef?.current?.scrollTo({ top, behavior: 'smooth' });
    },
    [menuRef]
  );

  useEffect(() => {
    setTimeout(() => setMode(Mode.Mounted), 2);
  }, []);

  const getCurrentVerticalScroll = useCallback(() => menuRef?.current?.scrollTop, []);

  return (
    <div
      className={classNames('MobileSiteNavMenu', {
        'MobileSiteNavMenu--active': mode === Mode.Mounted
      })}
      {...rest}
    >
      <div className="MobileSiteNavMenu__menu MobileSiteNavMenu__menu--refreshed" ref={menuRef}>
        <RefreshedMobileSiteNavMenu
          onClose={onClose}
          verticallyScrollTo={handleScrollTo}
          getCurrentVerticalScroll={getCurrentVerticalScroll}
        />
      </div>
    </div>
  );
};
