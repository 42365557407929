import { useCallback, useState } from 'react';
import { queryParamToString } from '@moda/portal-stanchions';
import { useLocation } from 'react-router-dom';
import { logger } from '../../lib/logger';
import { identifyUser } from '../../lib/identifyUser';
import {
  AuthPayloadSuccess,
  AuthAction,
  AuthError,
  AuthPayload,
  isAuthPayloadEmailVerificationRequired,
  authenticate
} from '../../lib/authenticate';
import { tc } from '../../lib/trackingContext';
import { useCookies } from '../useCookies';
import { useUser } from '../useUser';
import { RECAPTCHA_ACTIONS, useRecaptcha } from '../useRecaptcha';
import { useSessionQueryParams } from '../useSessionQueryParams';
import { useAuthModal } from '../useAuthModal';
import { useEmailVerificationStatusModal } from '../../components/EmailVerificationStatus';
import { hrefFor } from '../../routers';
import { CONFIG } from '../../config';

export type HandleAuthBaseProps = {
  guest?: false;
  email: string;
  first_name?: string;
  last_name?: string;
  password: string;
  remember?: boolean;
  opted_in?: boolean;
};

type HandleAuthGuestProps = { guest: true; email: string; remember: boolean; opted_in?: boolean };

export type HandleAuthProps = HandleAuthBaseProps | HandleAuthGuestProps;

export const useAuth = (action: AuthAction) => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const isCheckoutPage = pathname === hrefFor.CheckoutPage();

  const { closeAuthModal } = useAuthModal();
  const { handleRecaptchaV3 } = useRecaptcha();
  const { setUserData } = useUser();
  const {
    cookies: { cartId }
  } = useCookies();
  const {
    params: {
      bypass_rack_attack: bypassRackAttackQueryParameter,
      'email-verification': emailVerificationQueryParam
    }
  } = useSessionQueryParams();
  const { showEmailVerificationStatusModal } = useEmailVerificationStatusModal();

  const bypassRackAttack =
    queryParamToString(bypassRackAttackQueryParameter) || CONFIG.BYPASS_RACK_ATTACK;

  const emailVerificationEnabled = emailVerificationQueryParam === 'true';

  const handleTrackSignedIn = useCallback(
    (user: AuthPayloadSuccess, optedIn?: boolean) => {
      tc.track('Signed In', {
        addToScopeOnce: {
          user
        },
        addToTrackingEvent: { label: user.id }
      });
      if (isCheckoutPage && optedIn == true)
        tc.track('Email Signup Submitted', {
          addToTrackingEvent: {
            label: 'checkout',
            category: 'Marketing Email'
          },
          addToScopeOnce: {
            user
          }
        });
    },
    [isCheckoutPage]
  );

  const handleAuth = useCallback(
    ({ remember, ...values }: HandleAuthProps) => {
      const handleError = (error: AuthError) => {
        setError(error);
        setLoading(false);
        logger.info('AUTH_FAILED', error.message || '', {
          code: error.code,
          message: error.message
        });
        throw error;
      };

      const handleSuccess = async (user: AuthPayloadSuccess) => {
        await setUserData({ remember, isLoggedIn: !values.guest, ...user });
        setLoading(false);
        identifyUser(user);
        handleTrackSignedIn(user, values.opted_in);

        return user;
      };

      const handleEmailVerificationRequired = () => {
        if (values.guest)
          throw new Error(
            'Unexpected email verification required response received for guest user.'
          );

        setLoading(false);
        closeAuthModal();
        showEmailVerificationStatusModal({ status: 'pending', user: { ...values, remember } });

        return new Promise<AuthPayloadSuccess>(() => null);
      };

      const handleResponse = (response: AuthPayload) => {
        if (isAuthPayloadEmailVerificationRequired(response))
          return handleEmailVerificationRequired();

        return handleSuccess(response);
      };

      setLoading(true);

      const attributes = { ...values, cart_id: cartId };

      if (bypassRackAttack) {
        return authenticate({
          action,
          attributes,
          bypassRackAttack,
          emailVerificationEnabled
        })
          .then(handleResponse)
          .catch(handleError);
      }

      return handleRecaptchaV3(RECAPTCHA_ACTIONS[action]).then((tokenV3: string) =>
        authenticate({
          action,
          attributes: {
            recaptcha: {
              token: tokenV3,
              type: 'v3'
            },
            ...attributes
          },
          emailVerificationEnabled
        })
          .then(handleResponse)
          .catch(error => {
            handleError(error);
            return undefined;
          })
      );
    },
    [
      action,
      bypassRackAttack,
      cartId,
      closeAuthModal,
      emailVerificationEnabled,
      handleRecaptchaV3,
      handleTrackSignedIn,
      setUserData,
      showEmailVerificationStatusModal
    ]
  );

  return { error, loading, handleAuth };
};
