import { useCallback, useState } from 'react';
import { PHONE_NUMBER_REGEXP } from '@moda/portal-stanchions';

const validatePhoneNumber = (phoneNumber: string) =>
  new RegExp(`^${PHONE_NUMBER_REGEXP.source}$`).test(phoneNumber);

export const usePhoneNumberValidation = () => {
  const [error, setError] = useState<string>();

  const isValid = useCallback((phoneNumber: string) => {
    if (phoneNumber === '') {
      return true;
    }

    const result = validatePhoneNumber(phoneNumber);

    if (result) {
      setError(undefined);
      return true;
    }

    setError('Phone number format is invalid.');
    return false;
  }, []);

  return { isValid, error };
};
