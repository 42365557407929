import React, { ReactNode } from 'react';
import ExitIcon from '@moda/icons/exit-16';
import ChevronLeftIcon from '@moda/icons/chevron-left-16';
import { Breakpoint, Stack, SlidingPane, Clickable, Text } from '@moda/om';
import { ModalOverlay } from '../Modal';
import './EmailVerificationStatusModal.scss';

type Props = {
  show: boolean;
  title: string;
  children: ReactNode;
  onHide: () => void;
  onBack?: () => void;
};

export const EmailVerificationStatusModal: React.FC<Props> = ({
  show,
  title,
  children,
  onHide,
  onBack
}) => {
  return (
    <>
      <Breakpoint lt="sm">
        <SlidingPane
          className="EmailVerificationStatusModal__sliding-pane"
          title={title}
          visible={show}
          onClose={onHide}
        >
          <Stack className="EmailVerificationStatusModal__content" space={5}>
            {children}
          </Stack>
        </SlidingPane>
      </Breakpoint>
      <Breakpoint gt="sm">
        <ModalOverlay contentClassName="EmailVerificationStatusModal" onClose={onHide} show={show}>
          <Stack space={0}>
            {title && (
              <Stack
                className="EmailVerificationStatusModal__header"
                space={3}
                direction="horizontal"
                justifyContent="space-between"
                alignItems="center"
              >
                {onBack ? (
                  <Clickable aria-label="Back" onClick={onBack}>
                    <ChevronLeftIcon />
                  </Clickable>
                ) : (
                  <Text />
                )}
                <Text treatment="h5" family="serif">
                  {title}
                </Text>
                <Clickable aria-label="Close" onClick={onHide}>
                  <ExitIcon />
                </Clickable>
              </Stack>
            )}
            <Stack className="Dialog__content" space={6}>
              {children}
            </Stack>
          </Stack>
        </ModalOverlay>
      </Breakpoint>
    </>
  );
};
