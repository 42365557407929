import React from 'react';
import { Constrain, Divider, Stack } from '@moda/om';
import { SiteFooterMailingList } from '../SiteFooterMailingList';
import { SiteFooterBasement } from '../SiteFooterBasement';
import { SiteFooterLinks } from '../SiteFooterLinks';

import './SiteFooterLargeDesktop.scss';

export const SiteFooterLargeDesktop = () => {
  return (
    <Stack space={5}>
      <Constrain className="SiteFooterLargeDesktop">
        <Stack space={5}>
          <div className="SiteFooterLargeDesktop__content">
            <SiteFooterLinks />
            <SiteFooterMailingList />
          </div>
        </Stack>
      </Constrain>
      <Divider className="SiteFooterLargeDesktop__divider--refresh" />
      <Constrain>
        <SiteFooterBasement />
      </Constrain>{' '}
    </Stack>
  );
};
