import React from 'react';
import { Constrain } from '@moda/om';
import './ErrorFallback.scss';

type GraphQLError = {
  message: string;
  path: string[];
  locations: [
    {
      line: number;
      column: number;
    }
  ];
};

export interface Props {
  error: Error & { graphQLErrors?: GraphQLError[] };
  componentStack: string;
}

export const ErrorFallback: React.FC<Props> = ({ componentStack, error }) => {
  const errorStack = (error.stack || componentStack)
    .trim()
    .split('\n')
    .map(line => line.trim())
    .join('\n');

  const stackTrace = [
    errorStack,
    error.graphQLErrors && JSON.stringify(error.graphQLErrors, null, 2)
  ]
    .filter(Boolean)
    .join('\n\n');

  return (
    <div className="ErrorFallback">
      <Constrain>
        <h1 className="ErrorFallback__header">Something went wrong</h1>
        {error.message && <h2 className="ErrorFallback__message">{error.message}</h2>}
        <pre className="ErrorFallback__stack">{stackTrace}</pre>
      </Constrain>
    </div>
  );
};
