import { isClientSide } from '@moda/portal-stanchions';

type Config = { default: number; google?: number };

const DEFAULT_CONFIG: Config = { default: 1, google: 3 };

const getConfig = (): Config => {
  return DEFAULT_CONFIG;
};

export const useEmailSignupPagesToViewBeforeModalShows = () => {
  const config = getConfig();

  return isClientSide() && document.referrer.includes('google')
    ? (config.google ?? config.default)
    : config.default;
};
