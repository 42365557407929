import React from 'react';
import loadable from '@loadable/component';
import { useCookies } from '../../hooks/useCookies';
import { usePreferences } from '../../hooks/usePreferences';

const LandingPageForRussia = loadable(() => import('./LandingPageForRussia'));

export const LandingPageForRussiaProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const {
    cookies: { cloudfrontViewerCountry }
  } = useCookies();
  const { showLandingPageForRussia } = usePreferences();

  return cloudfrontViewerCountry === 'RU' && showLandingPageForRussia !== false ? (
    <LandingPageForRussia />
  ) : (
    <>{children}</>
  );
};
