import React from 'react';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';
import { RefreshedMobileSiteNavMenuLinkItem } from '../RefreshedMobileSiteNavMenuLinkItem';
import { RefreshedMobileSiteNavMenuVerticalCategories } from '../RefreshedMobileSiteNavMenuVerticalCategories';
import { RefreshedMobileSiteNavMenuVerticalCategoryFashion } from '../RefreshedMobileSiteNavMenuVerticalCategoryFashion';

type MainCategory = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['mainCategories'][number];

type MainCategoryItem = MainCategory['mainCategoryItems'][number];

type SubItem = MainCategoryItem['subItems'][number];

type ListLink = NonNullable<SubItem['listLinks']>[number];

interface Props {
  key: string;
  data: MainCategory | MainCategoryItem | SubItem | ListLink;
  onClose: () => void;
  expandedSection: string | undefined;
  setExpandedSection: (label: string | undefined) => void;
  label: string;
}

export const RefreshedMobileSiteNavMenuVerticalCategory: React.FC<Props> = ({
  data,
  onClose,
  expandedSection,
  setExpandedSection,
  label
}) => {
  if (label === 'fashion') {
    return (
      <RefreshedMobileSiteNavMenuVerticalCategoryFashion
        data={data}
        onClose={onClose}
        expandedSection={expandedSection}
        setExpandedSection={setExpandedSection}
      />
    );
  }

  if (label !== 'fashion') {
    return (
      <RefreshedMobileSiteNavMenuVerticalCategories
        data={data}
        onClose={onClose}
        expandedSection={expandedSection}
        parentLabel={label}
        setExpandedSection={setExpandedSection}
        key={label}
      />
    );
  }

  if (data.__typename === 'MobileNavListLink') {
    return (
      <RefreshedMobileSiteNavMenuLinkItem
        url={data.url}
        label={data.label}
        onClick={onClose}
        parentLabel={label || 'MobileNavListLink'}
      />
    );
  }

  return null;
};
