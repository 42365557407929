import { noop } from '@moda/portal-stanchions';
import React, { createContext, FC, useState, useCallback } from 'react';
import { DefaultValues } from '../Authentication';

type OpenAuthModalArgs = {
  defaultValues?: DefaultValues;
  onSuccess?: () => void;
  onFailure?: () => void;
};

export type TAuthModalContext = {
  show: boolean;
  defaultValues?: DefaultValues;
  openAuthModal: (args?: OpenAuthModalArgs) => void;
  closeAuthModal: () => void;
  handleAuthSuccess: () => void;
  handleAuthFailure: () => void;
};

export const AuthModalContext = createContext<TAuthModalContext>({
  show: false,
  openAuthModal: noop,
  closeAuthModal: noop,
  handleAuthSuccess: noop,
  handleAuthFailure: noop
});

export const AuthModalProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [defaultValues, setDefaultValues] = useState<DefaultValues>();
  const [onSuccessCallback, setOnSuccessCallback] = useState<typeof noop>(noop);
  const [onFailureCallback, setOnFailureCallback] = useState<typeof noop>(noop);

  const clearCallbacks = useCallback(() => {
    setOnSuccessCallback(() => noop);
    setOnFailureCallback(() => noop);
  }, []);

  const openAuthModal = useCallback(
    ({ defaultValues, onSuccess, onFailure }: OpenAuthModalArgs = {}) => {
      setDefaultValues(defaultValues);

      setOnSuccessCallback(() => () => {
        onSuccess?.();
        clearCallbacks();
      });

      setOnFailureCallback(() => () => {
        onFailure?.();
        clearCallbacks();
      });

      setShow(true);
    },
    [clearCallbacks]
  );

  const closeAuthModal = useCallback(() => {
    clearCallbacks();
    setShow(false);
  }, [clearCallbacks]);

  return (
    <AuthModalContext.Provider
      value={{
        show,
        defaultValues,
        openAuthModal,
        closeAuthModal,
        handleAuthSuccess: onSuccessCallback,
        handleAuthFailure: onFailureCallback
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};
