import React from 'react';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';
import { RefreshedMobileNavMainCategoryItem } from '../RefreshedMobileNavMainCategoryItem';
import { RefreshedMobileSiteNavExpandable } from '../RefreshedMobileSiteNavExpandable';

type MainCategory = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['mainCategories'][number];

type MainCategoryItem = MainCategory['mainCategoryItems'][number];

type SubItem = MainCategoryItem['subItems'][number];

type ListLink = NonNullable<SubItem['listLinks']>[number];

interface Props {
  key: string;
  data: MainCategory | MainCategoryItem | SubItem | ListLink;
  onClose: () => void;
  parentLabel: string;
  expandedSection: string | undefined;
  setExpandedSection: (label: string | undefined) => void;
}

export const RefreshedMobileSiteNavMenuVerticalCategories: React.FC<Props> = ({
  data,
  onClose,
  expandedSection,
  setExpandedSection,
  parentLabel
}) => {
  if (data.__typename !== 'MobileNavMainCategoryItem') return null;

  if (data.subItems.length === 0) return null;

  return (
    <>
      {data.subItems.map((option, index) => {
        // TODO: PLEASE REMOVE, HACK #1 - Beauty Release
        if (option.label == 'HAIR' || option.label == 'TREATMENTS') return null;

        return (
          <RefreshedMobileSiteNavExpandable
            key={index}
            title={option.label}
            url={option.url || undefined}
            styleProps={null}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
            parentLabel={parentLabel}
          >
            {option.listLinks && option.listLinks.length > 0 && (
              <RefreshedMobileNavMainCategoryItem
                key={index}
                data={data.subItems[index]}
                parentLabel={parentLabel}
                onClose={onClose}
              />
            )}
          </RefreshedMobileSiteNavExpandable>
        );
      })}
    </>
  );
};
