import React from 'react';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';
import { RefreshedMobileNavMainCategoryItem } from '../RefreshedMobileNavMainCategoryItem';
import { RefreshedMobileSiteNavExpandable } from '../RefreshedMobileSiteNavExpandable';
import './RefreshedMobileSiteNavMenuVerticalCategoryFashion.scss';

type MainCategory = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['mainCategories'][number];

type MainCategoryItem = MainCategory['mainCategoryItems'][number];

type SubItem = MainCategoryItem['subItems'][number];

type ListLink = NonNullable<SubItem['listLinks']>[number];

interface Props {
  data: MainCategory | MainCategoryItem | SubItem | ListLink;
  onClose: () => void;
  expandedSection: string | undefined;
  setExpandedSection: (label: string | undefined) => void;
  parentLabel?: string;
}

export const RefreshedMobileSiteNavMenuVerticalCategoryFashion: React.FC<Props> = ({
  data,
  onClose,
  expandedSection,
  setExpandedSection,
  parentLabel
}) => {
  if (data.__typename === 'MobileNavMainCategory') {
    return (
      <RefreshedMobileSiteNavExpandable
        title={data.label}
        url={data.url || undefined}
        styleProps={data.styleProps}
        expandedSection={expandedSection}
        setExpandedSection={setExpandedSection}
        parentLabel="fashion"
      >
        {data.mainCategoryItems.map((option, index) => (
          <RefreshedMobileSiteNavMenuVerticalCategoryFashion
            key={index + data.label}
            data={option}
            onClose={onClose}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
            parentLabel={parentLabel || `fashion/${data.label}`}
          />
        ))}
      </RefreshedMobileSiteNavExpandable>
    );
  }

  if (data.__typename === 'MobileNavMainCategoryItem') {
    return (
      <>
        {data.subItems.length > 0 &&
          data.subItems[0].centerTitle === false &&
          data.subItems[0].label !== '' && (
            <span className="RefreshedMobileSiteNavMenuVerticalCategoryFashion_curations">
              {data.subItems[0].label}
            </span>
          )}

        {data.subItems.map((option, index) => (
          <RefreshedMobileNavMainCategoryItem
            key={index}
            data={option}
            onClose={onClose}
            parentLabel={parentLabel || 'fashion'}
          />
        ))}
      </>
    );
  }

  return null;
};
