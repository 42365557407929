import { Clickable, Stack, Text } from '@moda/om';
import React, { useMemo } from 'react';
import ChevronRightIcon from '@moda/icons/chevron-right-12';
import { titleize } from '@moda/portal-stanchions';
import { useShowHolidayHomeLinkFeatureFlag } from '../../../../hooks/useHolidayLinkFeatureFlag';

interface Props {
  selectedTab: string | undefined;
}

export const RefreshedMobileSiteNavMenuMainCategoryLink: React.FC<Props> = ({ selectedTab }) => {
  const url = useMemo(() => {
    switch (selectedTab) {
      case 'fashion':
        return '/';
      case 'beauty':
        return '/beauty';
      case 'home':
        return '/home';
      default:
        return '/';
    }
  }, [selectedTab]);

  // TODO: HACK #2, This can probably be revised
  const TITLE = titleize(selectedTab);

  const showHolidayHomeLink = useShowHolidayHomeLinkFeatureFlag();

  return (
    <Clickable className="RefreshedMobileSiteNavMenuMainCategoryLink__title" href={url}>
      <Stack alignItems="center" direction="horizontal" justifyContent="space-between" space={2}>
        {showHolidayHomeLink ? (
          <Text>{TITLE}</Text>
        ) : (
          <Text>{TITLE == 'Home' ? 'Home' : `${TITLE} Home`}</Text>
        )}
        <div>
          <ChevronRightIcon />
        </div>
      </Stack>
    </Clickable>
  );
};
