import React from 'react';
import { ControlLink } from '@moda/om';
import { hrefFor } from '../../../../routers';

import './SiteFooterBasementCheckoutDesktop.scss';

export const SiteFooterBasementCheckoutDesktop: React.FC = () => {
  return (
    <section className="SiteFooterBasementCheckoutDesktop">
      <div className="SiteFooterInlineItem">
        <ControlLink to={`${hrefFor.AboutPage()}#contact`} underlined={false}>
          Contact Us
        </ControlLink>
      </div>

      <div className="SiteFooterInlineItem">
        <ControlLink to={hrefFor.TermsAndConditionsPage()} underlined={false}>
          Terms &amp; Conditions
        </ControlLink>
      </div>

      <div className="SiteFooterInlineItem">
        <ControlLink to={hrefFor.PrivacyPolicyPage()} underlined={false}>
          Privacy Policy
        </ControlLink>
      </div>

      <div className="SiteFooterInlineItem">© {new Date().getFullYear()} Moda Operandi, Inc.</div>

      <div className="SiteFooterInlineItem">All Rights Reserved.</div>
    </section>
  );
};
