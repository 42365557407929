import { ApolloClient, gql, TypedDocumentNode } from '@apollo/client';
import { useCallback } from 'react';
import { throwQueryError } from '../lib/apollo/throwQueryError';
import { ErrorModalQuery } from '../generated/types';
import { useLocalQuery } from './useLocalQuery';

type ErrorModal = NonNullable<ErrorModalQuery['errorModal']>;

export const ERROR_MODAL_QUERY: TypedDocumentNode<ErrorModalQuery, void> = gql`
  query ErrorModalQuery {
    errorModal @client {
      show
      message
    }
  }
`;

export const ERROR_MODAL_DEFAULTS: ErrorModal = {
  __typename: 'ErrorModal',
  show: false,
  message: null
};

const writeErrorModalQuery = <T>({
  client,
  show,
  message
}: {
  client: ApolloClient<T>;
  show: boolean;
  message: string | null;
}) =>
  client.writeQuery({
    query: ERROR_MODAL_QUERY,
    data: { errorModal: { __typename: 'ErrorModal', show, message } }
  });

export const useErrorModal = () => {
  const { data, error, client } = useLocalQuery(ERROR_MODAL_QUERY);
  const message = data?.errorModal?.message ?? null;

  const showErrorModal = useCallback(
    (message?: string | null) =>
      writeErrorModalQuery({ client, show: true, message: message || null }),
    [client]
  );
  const hideErrorModal = useCallback(
    () => writeErrorModalQuery({ client, show: false, message }),
    [client, message]
  );

  if (error) throwQueryError(error);

  return {
    show: data?.errorModal?.show || false,
    message,
    showErrorModal,
    hideErrorModal
  };
};
