import React, { useMemo } from 'react';
import { LoadingShapesLavenderBlue, LoadingShapesPinkGreen } from '@moda/om';
import random from 'random';
import { ClientSideOnly } from '../ClientSideOnly';
import './Loading.scss';

export const Loading: React.FC = () => {
  const index = useMemo(() => random.boolean(), []);
  return (
    <ClientSideOnly>
      {index ? (
        <div className="Loading Loading--absolute">
          <LoadingShapesLavenderBlue />
        </div>
      ) : (
        <div className="Loading Loading--absolute">
          <LoadingShapesPinkGreen />
        </div>
      )}
    </ClientSideOnly>
  );
};
