import { matchPath, useLocation } from 'react-router-dom';
import { findRouteFor } from '../routers';

/**
 * Used as an useParams() alternative for out-of-the-router situations
 */
export const useCurrentRouteParams = <
  TParams extends { [K in keyof TParams]?: string | undefined }
>() => {
  const { pathname } = useLocation();
  const route = findRouteFor(pathname);

  return matchPath<TParams>(pathname, {
    path: route?.path,
    exact: true,
    strict: false
  })?.params;
};
