import { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { always, omit, tryCatch } from 'ramda';
import {
  QueryParameters,
  parseQueryString,
  generateQueryString,
  HOURS_TO_MINUTES,
  MINUTES_TO_SECONDS,
  SECONDS_TO_MILLISECONDS
} from '@moda/portal-stanchions';
import { logger } from '../lib/logger';
import { useCookies, getCookies } from './useCookies';

const STORE_KEY = 'sessionQueryParams';
const TIMESTAMP_STORE_KEY = 'sessionQueryParamTimestamps';

interface ParamConfig {
  pattern: string | RegExp;
  expiryHours?: number;
  sessionSpecific?: boolean;
}

interface TimestampData {
  [key: string]: number;
}

const PARAM_CONFIG: ParamConfig[] = [
  // { pattern: /^example_param.*$/, expiryHours: 24 }, // expires after 24 hrs
  { pattern: 'cjevent', expiryHours: 24 },
  { pattern: 'PID', expiryHours: 24 },
  { pattern: 'SID', expiryHours: 24 },
  { pattern: 'AID', expiryHours: 24 }
];

const getLocalStorageTimestamps = (): TimestampData => {
  try {
    const data = localStorage.getItem(TIMESTAMP_STORE_KEY);
    return data ? JSON.parse(data) : {};
  } catch {
    return {};
  }
};

const setLocalStorageTimestamps = (timestamps: TimestampData): void => {
  try {
    localStorage.setItem(TIMESTAMP_STORE_KEY, JSON.stringify(timestamps));
  } catch (error) {
    logger.error(
      'SESSION-QUERY-PARAM-TIMESTAMPS',
      `Failed to set timestamps in localStorage: ${error}`
    );
  }
};

export const useSessionQueryParams = () => {
  const { setCookie } = useCookies();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(() => parseQueryString(location.search), [location.search]);
  const parseSafely = tryCatch(JSON.parse, always({}));

  const getParamConfig = useCallback((paramKey: string): ParamConfig | undefined => {
    return PARAM_CONFIG.find(config => {
      if (typeof config.pattern === 'string') {
        return paramKey === config.pattern;
      }
      return config.pattern.test(paramKey);
    });
  }, []);

  const cleanExpiredParams = useCallback(() => {
    const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
    const storedTimestamps = getLocalStorageTimestamps();

    const removeSessionParams = (params: Record<string, string>) => {
      return Object.fromEntries(
        Object.entries(params).filter(([key]) => {
          const config = getParamConfig(key);
          return !config?.sessionSpecific;
        })
      );
    };

    const removeExpiredParams = (
      params: Record<string, string>,
      timestamps: TimestampData,
      now = Date.now()
    ) => {
      const validEntries = Object.entries(params).filter(([key]) => {
        const timestamp = timestamps[key];
        const config = getParamConfig(key);

        if (!config?.expiryHours || !timestamp) return true;

        const expiryTime =
          timestamp +
          config.expiryHours * SECONDS_TO_MILLISECONDS * MINUTES_TO_SECONDS * HOURS_TO_MINUTES;
        return now <= expiryTime;
      });

      return {
        params: Object.fromEntries(validEntries),
        timestamps: Object.fromEntries(
          Object.entries(timestamps).filter(([key]) =>
            validEntries.some(([validKey]) => validKey === key)
          )
        )
      };
    };

    const withoutSessionParams = removeSessionParams(storedParams);
    const { params: cleanedParams, timestamps: cleanedTimestamps } = removeExpiredParams(
      withoutSessionParams,
      storedTimestamps
    );

    const hasChanges =
      Object.keys(storedParams).length !== Object.keys(cleanedParams).length ||
      Object.keys(storedTimestamps).length !== Object.keys(cleanedTimestamps).length;

    if (hasChanges) {
      setCookie(STORE_KEY, JSON.stringify(cleanedParams));
      setLocalStorageTimestamps(cleanedTimestamps);
    }

    return cleanedParams;
  }, [setCookie, parseSafely, getParamConfig]);

  const removeParameter = useCallback(
    (key: string) => {
      const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
      const timestamps = getLocalStorageTimestamps();

      setCookie(STORE_KEY, JSON.stringify(omit([key], storedParams)));
      setLocalStorageTimestamps(omit([key], timestamps));

      if (queryParams[key]) {
        const queryString = generateQueryString(omit([key], queryParams));
        history.replace(`${location.pathname}${queryString ? '?' : ''}${queryString}`);
      }
    },
    [history, location.pathname, queryParams, setCookie, parseSafely]
  );

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
      const timestamps = getLocalStorageTimestamps();
      const now = Date.now();

      const newTimestamps = { ...timestamps };
      Object.entries(queryParams).forEach(([key, value]) => {
        const config = getParamConfig(key);
        if (config?.expiryHours) {
          if (!timestamps[key] || storedParams[key] !== value) {
            newTimestamps[key] = now;
          }
        }
      });

      setCookie(STORE_KEY, JSON.stringify({ ...storedParams, ...queryParams }));
      setLocalStorageTimestamps(newTimestamps);
    }
  }, [queryParams, setCookie, parseSafely, getParamConfig]);

  useEffect(() => {
    const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
    const timestamps = getLocalStorageTimestamps();

    if (Object.keys(timestamps).length === 0 && Object.keys(storedParams).length > 0) {
      const now = Date.now();
      const newTimestamps = { ...timestamps };

      Object.entries(storedParams).forEach(([key]) => {
        const config = getParamConfig(key);
        if (config?.expiryHours && !timestamps[key]) {
          newTimestamps[key] = now;
        }
      });

      setLocalStorageTimestamps(newTimestamps);
    }
  }, [getParamConfig, parseSafely, setCookie]);

  const params: QueryParameters = useMemo(() => {
    const cleanedParams = cleanExpiredParams();
    return { ...cleanedParams, ...queryParams };
  }, [queryParams, cleanExpiredParams]);

  return { params, removeParameter };
};
