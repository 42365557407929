import { ApolloError, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBreakpoint } from '@moda/om';
import { Gender } from '../../generated/types';
import { reportError } from '../../lib/reportError';
import { tc } from '../../lib/trackingContext';
import { usePhoneNumberValidation } from '../usePhoneNumberValidation';
import { useEmailVerification } from '../useEmailVerification';
import { useSessionQueryParams } from '../useSessionQueryParams';
import { useEmailSignupPhoneNumber } from '../../components/EmailSignup/useEmailSignup/useEmailSignupPhoneNumber';
import {
  AttentiveSignUpSource,
  EmailSubscribeMutation,
  EmailSubscribeMutationVariables,
  EMAIL_SUBSCRIBE_MUTATION,
  Placement
} from './useEmailSubscribe.rest';

export type SubscribeArgs = {
  email: string;
  phone?: string;
  label: Placement;
  source?: AttentiveSignUpSource;
};

export const useEmailSubscribe = () => {
  const { vertical = 'women' } = useParams<{
    vertical: Gender | undefined;
    trunkshowSlug: string | undefined;
    designerSlug: string | undefined;
  }>();
  const isMobile = useBreakpoint({ lt: 'md' }).matches;

  const { enabled: phoneNumberEnabled, phone, setPhone } = useEmailSignupPhoneNumber();

  const { isValid: isEmailValid, error: mailValidationError } = useEmailVerification();
  const { isValid: isPhoneNumberValid, error: phoneNumberValidationError } =
    usePhoneNumberValidation();

  const [mutate, { data, loading, error }] = useMutation<
    EmailSubscribeMutation,
    EmailSubscribeMutationVariables
  >(EMAIL_SUBSCRIBE_MUTATION);

  const handleSuccess = useCallback(() => setIsSubscribed(true), []);

  const handleError = useCallback(
    (caughtError: ApolloError) => reportError('EMAIL_SUBSCRIBE', caughtError),
    []
  );

  const [isSubscribed, setIsSubscribed] = useState(false);
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params: { utm_source, utm_medium, utm_campaign, utm_content, utm_term }
  } = useSessionQueryParams();

  const isExistingSubscription = data?.emailSubscribe.table?.isExistingSubscription ?? false;

  const defaultSource: AttentiveSignUpSource = isMobile ? 'mobile' : 'desktop';

  const subscribe = useCallback(
    ({ email, phone = '', label, source = defaultSource }: SubscribeArgs) => {
      if (!isEmailValid(email)) return;
      if (phoneNumberEnabled && phone != undefined && !isPhoneNumberValid(phone)) return;

      tc.track('Email Signup Submitted', {
        addToTrackingEvent: {
          label,
          category: 'Marketing Signup',
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term
        }
      });

      mutate({
        variables: {
          input: {
            user: {
              email,
              gender: Gender[vertical],
              phone
            },
            label,
            category: 'Marketing Signup',
            sign_up_source_target: source
          }
        }
      })
        .then(handleSuccess)
        .catch(handleError);
    },
    [
      handleError,
      handleSuccess,
      isEmailValid,
      isPhoneNumberValid,
      mutate,
      phoneNumberEnabled,
      defaultSource,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      vertical
    ]
  );

  return {
    subscribe,
    loading,
    error,
    mailValidationError,
    isSubscribed,
    isExistingSubscription,
    isPhoneNumberValid,
    phoneNumberEnabled,
    phone,
    setPhone,
    phoneNumberValidationError
  };
};
