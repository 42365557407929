import React from 'react';
import { Constrain, Stack, Toast, ToastProps } from '@moda/om';
import { useNotifications } from '../../hooks/useNotifications';
import './Notifications.scss';

export const Notifications: React.FC = () => {
  const { notifications, removeNotification } = useNotifications();

  if (notifications.length === 0) return null;

  return (
    <Constrain>
      <Stack className="Notifications" space={2}>
        {notifications.map(notification => (
          <Toast
            key={notification.id}
            theme={notification.type as ToastProps['theme']}
            onRemove={() => removeNotification(notification.id)}
          >
            {notification.message}
          </Toast>
        ))}
      </Stack>
    </Constrain>
  );
};
