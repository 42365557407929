import { Clickable, Divider, Stack, Text } from '@moda/om';
import React from 'react';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../../generated/types';

import './RefreshedMobileSiteNavMenuVerticalImages.scss';

type Image = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['imageCategories'][number]['images'][number];

const sanitizeUrl = (url: string | null) => {
  if (!url) return undefined;

  try {
    // we can log this -- here the url looks like https://modaoperandi.com/clothing/etc and it should be strict pathname
    return new URL(url).pathname;
  } catch {
    return url;
  }
};

interface Props {
  images?: Image[];
}

export const RefreshedMobileSiteNavMenuVerticalImages: React.FC<Props> = ({ images }) => {
  if (!images) return null;

  return (
    <>
      <Divider />

      <Stack className="RefreshedMobileSiteNavMenuVerticalImages" direction="horizontal" space={3}>
        {images.map(({ image, url, headline, anchor, secondAnchor }, index) => (
          <Stack key={index} space={2}>
            {headline && <Text>{headline}</Text>}

            {!headline && (
              <div className="RefreshedMobileSiteNavMenuVerticalImages__top-placeholder" />
            )}

            <Clickable href={sanitizeUrl(url)}>
              <img
                alt={`number ${index}`}
                className="RefreshedMobileSiteNavMenuVerticalImages__image"
                key={image}
                src={image}
              />
            </Clickable>

            {anchor && <Text>{anchor}</Text>}

            {secondAnchor && <Text>{secondAnchor}</Text>}
          </Stack>
        ))}
      </Stack>
    </>
  );
};
