import React from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';
import { ROUTE_VERTICALS, SECTIONS, ROUTE_CATEGORIES } from '../constants';
import { usePreferences } from '../../hooks/usePreferences';
import { BareSlugRedirectRoute } from './BareSlugRedirect';

interface LegacyRedirectProps extends Omit<RedirectProps, 'to'> {
  from: string;
  to: string;
  key?: string;
}

const REDIRECTS: LegacyRedirectProps[] = [
  {
    from: '/women',
    to: '/',
    exact: true
  },
  {
    from: '/account',
    to: '/account/settings',
    exact: true
  },
  {
    from: '/users/password/edit',
    to: '/reset-password',
    exact: true
  },
  {
    from: `/:vertical(${ROUTE_VERTICALS})/trunkshows`,
    to: `/collections`,
    exact: true
  },
  {
    from: '/women/collections',
    to: '/collections',
    exact: true
  },
  {
    from: '/women/collections/designer',
    to: '/collections/designers',
    exact: true
  },
  {
    from: '/women/collections/:category/:designer?',
    to: '/collections/designers/:category',
    exact: true
  },
  {
    from: `/designers/:vertical(${ROUTE_VERTICALS})/:category(${ROUTE_CATEGORIES})?`,
    to: `/:vertical(${ROUTE_VERTICALS})/designers/:category(${ROUTE_CATEGORIES})?`,
    exact: true
  },
  {
    from: `/:vertical(${ROUTE_VERTICALS})/:category(${ROUTE_CATEGORIES})?/:subcategory?`,
    to: `/:vertical(${ROUTE_VERTICALS})/products/:category(${ROUTE_CATEGORIES})?/:subcategory?`,
    exact: true
  },
  {
    from: `/:section(${SECTIONS})/:vertical(${ROUTE_VERTICALS})/:category(${ROUTE_CATEGORIES})?/:subcategory?`,
    to: `/:vertical(${ROUTE_VERTICALS})/products/:section(${SECTIONS})/:category(${ROUTE_CATEGORIES})?/:subcategory?`,
    exact: true
  },
  {
    from: `/:designerSlug/:vertical(${ROUTE_VERTICALS})`,
    to: `/:vertical(${ROUTE_VERTICALS})/designer/:designerSlug`,
    exact: true
  },
  {
    from: `/:designerSlug/:vertical(${ROUTE_VERTICALS})/:category(${ROUTE_CATEGORIES})/:subcategory?`,
    to: `/:vertical(${ROUTE_VERTICALS})/products/designer/:designerSlug/:category(${ROUTE_CATEGORIES})/:subcategory?`,
    exact: true
  },
  {
    from: `/:vertical(${ROUTE_VERTICALS})/collection/:trunkshowSlug`,
    to: `/collection/:trunkshowSlug`,
    exact: true
  },
  {
    from: `/:vertical(${ROUTE_VERTICALS})/designer/:designerSlug/collection/:season`,
    to: `/designer/:designerSlug/collection/:season`,
    exact: true
  }
];

const NO_VERTICAL_REDIRECTS: LegacyRedirectProps[] = [
  {
    from: '/trunkshows',
    to: '/collections',
    exact: true
  },
  {
    from: '/men/collections',
    to: '/collections',
    exact: true
  },
  {
    from: `/:section(${SECTIONS})`,
    to: `/women/products/:section(${SECTIONS})`,
    exact: true
  },
  {
    from: `/:section(${SECTIONS})/:category(${ROUTE_CATEGORIES})/:subcategory?`,
    to: `/women/products/:section(${SECTIONS})/:category(${ROUTE_CATEGORIES})/:subcategory?`,
    exact: true
  }
];

export const NoVerticalRedirect: React.FC<LegacyRedirectProps> = ({
  from,
  to,
  ...rest
}: LegacyRedirectProps) => {
  const { vertical } = usePreferences();
  return <Redirect from={from} to={to.replace(/:vertical/, vertical)} {...rest} />;
};

export const RedirectWithQueryParams: React.FC<LegacyRedirectProps> = ({
  from,
  to,
  ...rest
}: LegacyRedirectProps) => {
  const { search } = useLocation();
  return <Redirect key={from} from={from} to={{ pathname: to, search }} {...rest} />;
};

export const LEGACY_ROUTES = [
  ...NO_VERTICAL_REDIRECTS.map(redirectObject => (
    <NoVerticalRedirect key={redirectObject.from} {...redirectObject} />
  )),

  ...REDIRECTS.map(redirectObject => (
    <RedirectWithQueryParams key={redirectObject.from} {...redirectObject} />
  )),
  // Add any additional non-simple redirects:
  <BareSlugRedirectRoute key="BareSlugRedirectRoute" />
];
