import { gql } from '@apollo/client';
import { colors } from '@moda/tokens';
import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { all } from 'ramda';
import { Link } from '../../../Link';
import { tc } from '../../../../lib/trackingContext';
import {
  DesktopSiteNavItemFragment,
  DesktopSiteNavListColumnFragment
} from '../../../../generated/types';
import {
  DesktopSiteNavPulldown,
  DESKTOP_SITE_NAV_PULLDOWN_FRAGMENT
} from '../DesktopSiteNavPulldown';
import { useEnableClubModaTreatment } from '../../../../hooks/useEnableClubModaTreatment';

import './DesktopSiteNavItem.scss';

export const DESKTOP_NAV_ITEM_FRAGMENT = gql`
  fragment DesktopSiteNavItemFragment on NavItem {
    id
    label
    href: url
    mobileOnly: mobile_only
    ...DesktopSiteNavPulldownFragment
  }
  ${DESKTOP_SITE_NAV_PULLDOWN_FRAGMENT}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  navItem: DesktopSiteNavItemFragment;
  active: boolean;
}

export const DesktopSiteNavItem: React.FC<Props> = ({ className, navItem, active }) => {
  const [hover, setHover] = useState(false);

  const isNavItemEmpty = useMemo(() => {
    const { desktopChildren } = navItem;

    return all(item => {
      if (item.columnType !== 'list') return false;

      const { lists } = item as DesktopSiteNavListColumnFragment;

      if (!lists) return true;

      if (lists.length === 0) return true;

      return !all(item => Boolean(item?.label), lists);
    }, desktopChildren);
  }, [navItem]);

  const handleMouseEnter = useCallback(() => {
    if (isNavItemEmpty) return;

    setHover(true);
  }, [isNavItemEmpty]);

  const handleMouseLeave = useCallback(() => {
    if (isNavItemEmpty) return;

    setHover(false);
  }, [isNavItemEmpty]);

  const handleTrackNavItemClicked = useCallback(
    (url: string | null, text: string | null, { pageX, pageY }: React.MouseEvent) => {
      tc.track('Navigation Item Clicked', {
        addToTrackingEvent: {
          url,
          text,
          label: text,
          hierarchy: navItem.label,
          coordinateX: pageX,
          coordinateY: pageY
        }
      });
    },
    [navItem.label]
  );

  const enableClubModaTreatment = useEnableClubModaTreatment();

  if (!navItem.id || !navItem.href) return null;

  return (
    <div
      className={classNames('DesktopSiteNavItem', className)}
      data-label={navItem.label?.toLowerCase()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        className={classNames('DesktopSiteNavItem__link', {
          'DesktopSiteNavItem__link--club': enableClubModaTreatment
        })}
        key={navItem.id}
        to={navItem.href}
        style={{
          color: enableClubModaTreatment
            ? colors.all.snow
            : navItem.label === 'SALE'
              ? colors.all['code-red']
              : colors.all.ink
        }}
        onClick={event => handleTrackNavItemClicked(navItem.href, navItem.label, event)}
      >
        {navItem.label}
      </Link>

      <DesktopSiteNavPulldown
        className={classNames('DesktopSiteNavItem__pulldown', {
          'DesktopSiteNavItem--hidden': !(hover && active)
        })}
        active={active}
        navItem={navItem}
      />
    </div>
  );
};
