import React from 'react';
import { Stack, Text, Button } from '@moda/om';
import { useErrorModal } from '../../hooks/useErrorModal';
import { ModalOverlay } from '../Modal';
import './ErrorModal.scss';

export const ErrorModal: React.FC = () => {
  const { show, message, hideErrorModal } = useErrorModal();

  return (
    <ModalOverlay contentClassName="ErrorModal" onClose={hideErrorModal} show={show}>
      <Stack space={6}>
        <Text family="serif" treatment="h5">
          {message || 'An error occurred. Please retry later.'}
        </Text>
        <Button onClick={hideErrorModal}>OK</Button>
      </Stack>
    </ModalOverlay>
  );
};
