import React from 'react';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import { Link } from '../Link';
import './MinimalNav.scss';

export const MinimalNav: React.FC = () => (
  <div className="MinimalNav">
    <div className="MinimalNav__logo">
      <Link className="MinimalNav__link" aria-label="Moda Operandi Home Page" to="/">
        <LogoHorizontalIcon width="100%" height="100%" />
      </Link>
    </div>
  </div>
);
