import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation';
import { tc } from '../../lib/trackingContext';
import { Link } from '../Link';
import { SearchResultsSet, SearchResult } from './parseSearchResults';

import './QuickSearchResults.scss';

interface Props {
  error?: Error;
  query: string;
  onClose(): void;
  loading: boolean;
  mobile?: boolean;
  results: SearchResult[];
  resultSets: SearchResultsSet[];
  onSelect?: () => void;
  onEnter(href: string, interactive: boolean): void;
}

export const QuickSearchResults: React.FC<Props> = ({
  mobile,
  query,
  loading,
  error,
  results,
  resultSets,
  onEnter,
  onClose,
  onSelect
}) => {
  const { index } = useKeyboardListNavigation({
    list: results,
    waitForInteractive: true,
    onEnter: ({ element: { href }, state: { interactive }, event }) => {
      event.preventDefault();
      return onEnter(href, interactive);
    }
  });
  let resultIndex = -1;

  const trackProductSearched = useCallback(
    (query: string) => {
      tc.track('Products Searched', {
        addToScopeOnce: { query, searchType: 'suggested' },
        addToTrackingEvent: { label: query }
      });
      onClose();
    },
    [onClose]
  );

  const className = classNames('QuickSearchResults', {
    'QuickSearchResults--mobile': mobile
  });

  if (error) {
    return (
      <div className={className}>
        <div className="QuickSearchResults__error">There was a problem with your query</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={className}>
        <div className="QuickSearchResults__loading">
          {query ? `Searching for “${query}”` : 'Searching'}
        </div>
      </div>
    );
  }

  if (results.length === 0) {
    return (
      <div className={className}>
        <div className="QuickSearchResults__none">No suggestions found</div>
      </div>
    );
  }

  return (
    <div className={className}>
      {resultSets.map(
        ({ title, results }) =>
          results.length !== 0 && (
            <div className="QuickSearchResults__set" key={title}>
              <h3 className="QuickSearchResults__header">{title}</h3>

              {results.map(result => {
                resultIndex++;
                return (
                  <Link
                    key={result.name}
                    className={classNames('QuickSearchResults__link', {
                      'QuickSearchResults__link--active': resultIndex === index
                    })}
                    to={result.href}
                    onClick={() => {
                      trackProductSearched(result.name);
                      if (onSelect) onSelect();
                    }}
                  >
                    {result.name}
                  </Link>
                );
              })}
            </div>
          )
      )}
    </div>
  );
};
