import { useLocation } from 'react-router-dom';
import { hrefFor } from '../../routers';
import { CartVersion } from './cart.rest';

export const useCartVersion = () => {
  const { pathname } = useLocation();

  if (pathname === hrefFor.ShoppingBagPage()) return CartVersion.ShoppingBag;
  if (pathname === hrefFor.CheckoutPage()) return CartVersion.Checkout;
  return CartVersion.MiniBag;
};
