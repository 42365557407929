import {
  COUNTRIES,
  DEFAULT_COUNTRY,
  parseQueryString,
  QueryParameters
} from '@moda/portal-stanchions';
import { always, tryCatch } from 'ramda';
import { UseCookiesQuery } from '../../generated/types';
import { decode as decodePreferences } from '../../hooks/usePreferences/tools';
import { parseUser } from '../user';

type Cookies = NonNullable<UseCookiesQuery['cookies']>;

const getQueryParameters = (cookies: Cookies, url: string) => {
  const queryParams = parseQueryString(url.split('?')[1] || '');
  const parseSafely = tryCatch(JSON.parse, always({}));
  const sessionQueryParams: QueryParameters = parseSafely(cookies.sessionQueryParams ?? '{}');

  return {
    ...sessionQueryParams,
    ...queryParams
  };
};

export const getSearchApiHeaders = (cookies: Cookies, url: string) => {
  const user = parseUser(cookies.fullUser);
  const { modaAnonymousId } = cookies;

  const queryParams = getQueryParameters(cookies, url);
  const urlCountry = queryParams.country
    ? COUNTRIES.find(
        ({ alpha2Code, alpha3Code }) =>
          queryParams.country === alpha2Code || queryParams.country === alpha3Code
      )
    : undefined;

  const { cloudfrontViewerCountry } = cookies;
  const preferences = decodePreferences(cookies.preferences);
  const preferencesCountry =
    COUNTRIES.find(country => country.id === preferences.countryId) || DEFAULT_COUNTRY;

  return {
    'client-country-code': urlCountry
      ? urlCountry.alpha2Code
      : cloudfrontViewerCountry && cloudfrontViewerCountry !== 'US'
        ? cloudfrontViewerCountry
        : preferencesCountry.alpha2Code,
    'user-token': user.id ?? modaAnonymousId
  };
};
