import { gql, TypedDocumentNode } from '@apollo/client';
import { UseUserQuery } from '../../../generated/types';

type RestUserData = NonNullable<UseUserQuery['user']>;

export type UpdateUserMutationUserAttributes = {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  country_code?: string;
  phone?: string | null;
  user_loyalty_status?: number | null;
  status_level?: number | null;
};

export type UpdateUserMutationPasswordAttributes = {
  current_password?: string;
  password: string;
  password_confirmation: string;
};

export type UpdateUserMutationVariables = {
  id: string;
  input: {
    data: {
      type: 'user';
      attributes: UpdateUserMutationUserAttributes | UpdateUserMutationPasswordAttributes;
    };
  };
};

export type UpdateUserMutation = {
  updateUser:
    | {
        __typename: 'RestUser';
        data: {
          attributes: RestUserData;
        };
      }
    | undefined;
};

const USER_ATTRIBUTES_FRAGMENT = gql`
  fragment UserAttributesFragment on RestUserAttributesData {
    firstName: first_name
    lastName: last_name
    email
    password
    optedIn: opted_in
    countryCode: country_code
    phone
    userLoyaltyStatus: user_loyalty_status
    statusLevel: status_level
  }
`;

const USER_FRAGMENT = gql`
  fragment UserFragment on RestUserData {
    type
    attributes @type(name: "RestUserAttributesData") {
      ...UserAttributesFragment
    }
  }
  ${USER_ATTRIBUTES_FRAGMENT}
`;

export const UPDATE_USER_MUTATION: TypedDocumentNode<
  UpdateUserMutation,
  UpdateUserMutationVariables
> = gql`
  mutation UpdateUserMutation($id: Int!, $input: UserMutationVariables) {
    updateUser(input: $input, id: $id)
      @rest(type: "RestUser", endpoint: "user", path: "/v1/users/{args.id}", method: "PATCH") {
      data @type(name: "RestUserData") {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
