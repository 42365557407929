import { gql } from '@apollo/client';
import React from 'react';
import classNames from 'classnames';
import { Link } from '../../../../Link';
import { ResponsiveImage } from '../../../../ResponsiveImage';
import { DesktopSiteNavImageColumnFragment } from '../../../../../generated/types';

import './DesktopSiteNavImageColumn.scss';

export const DESKTOP_SITE_NAV_IMAGE_COLUMN_FRAGMENT = gql`
  fragment DesktopSiteNavImageColumnFragment on NavImageColumn {
    columnType
    image {
      url
      ctaUrl
    }
    headlineText {
      body
    }
    anchorText {
      body
    }
    anchorLineTwo {
      body
    }
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  navImageColumn: DesktopSiteNavImageColumnFragment;
  active: boolean;
}

export const DesktopSiteNavImageColumn: React.FC<Props> = ({
  className,
  navImageColumn,
  active
}) => {
  const { headlineText, anchorText, anchorLineTwo, image } = navImageColumn;

  return (
    <div className={classNames('DesktopSiteNavImageColumn', className)}>
      <Link className="DesktopSiteNavImageColumn__anchor" to={image?.ctaUrl || '#'}>
        <h3 className="DesktopSiteNavImageColumn__header">
          {headlineText && headlineText.body ? headlineText.body : <>&nbsp;</>}
        </h3>

        {image && image.url && active && (
          <ResponsiveImage src={image.url} maxWidth={160} maxHeight={260} />
        )}

        {anchorText && anchorText.body && (
          <h4 className="DesktopSiteNavImageColumn__sub-header">{anchorText.body}</h4>
        )}

        {anchorLineTwo && anchorLineTwo.body && (
          <h5 className="DesktopSiteNavImageColumn__sub-sub-header">{anchorLineTwo.body}</h5>
        )}
      </Link>
    </div>
  );
};
