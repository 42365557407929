import { gql, TypedDocumentNode } from '@apollo/client';

export type GetFavoritesQuery = {
  userFavorites?: {
    __typename: 'RestUserFavorites';
    data: {
      __typename: 'RestUserFavoritesData';
      attributes: {
        __typename: 'RestUserFavoritesAttributes';
        variants: {
          id: string;
        }[];
      };
    };
  };
};

export type GetFavoritesQueryVariables = {
  userId: string | null;
};

export const GET_FAVORITES_QUERY: TypedDocumentNode<GetFavoritesQuery, GetFavoritesQueryVariables> =
  gql`
    query GetFavoritesQuery($userId: String!) {
      userFavorites(userId: $userId)
        @rest(
          type: "RestUserFavorites"
          endpoint: "recommendations"
          path: "/likes?userId={args.userId}"
        ) {
        data @type(name: "RestUserFavoritesData") {
          attributes @type(name: "RestUserFavoritesAttributes") {
            variants {
              id
            }
          }
        }
      }
    }
  `;

export type AddFavoriteMutation = {
  addFavorite: {
    __typename: 'RestAddFavorite';
    NoResponse: null;
  };
};

export type AddFavoriteMutationVariables = {
  id: string;
};

export const ADD_FAVORITE_MUTATION: TypedDocumentNode<
  AddFavoriteMutation,
  AddFavoriteMutationVariables
> = gql`
  mutation AddFavoriteMutation($id: Int!) {
    addFavorite(id: $id, input: {})
      @rest(
        type: "RestAddFavorite"
        endpoint: "preferences"
        path: "/v1/likes/{args.id}"
        method: "POST"
      ) {
      NoResponse
    }
  }
`;

export type RemoveFavoriteMutation = {
  removeFavorite: {
    __typename: 'RestRemoveFavorite';
    NoResponse: null;
  };
};

export type RemoveFavoriteMutationVariables = {
  id: string;
};

export const REMOVE_FAVORITE_MUTATION: TypedDocumentNode<
  RemoveFavoriteMutation,
  RemoveFavoriteMutationVariables
> = gql`
  mutation RemoveFavoriteMutation($id: Int!) {
    removeFavorite(id: $id)
      @rest(
        type: "RestRemoveFavorite"
        endpoint: "preferences"
        path: "/v1/likes/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;
