import { useMemo, useCallback } from 'react';
import { useHistory, useParams, useLocation, matchPath } from 'react-router-dom';
import { mapObjIndexed, omit, pickBy } from 'ramda';
import { generateQueryString, usePrevious, useQueryParams } from '@moda/portal-stanchions';
import { findRouteFor, hrefFor } from '../routers';

interface PathnameParams {
  [key: string]: string;
}

export interface UrlParams {
  [key: string]: string | string[];
}

const mergeParams = (queryParams: UrlParams, urlParams: PathnameParams): UrlParams => ({
  ...urlParams,
  ...mapObjIndexed(
    (value, key) =>
      urlParams[key] ? [urlParams[key], ...(Array.isArray(value) ? value : [value])] : value,
    queryParams
  )
});

const convertUrlParamsToPathnameParams = (urlParams: UrlParams): PathnameParams =>
  pickBy(
    value => value != null,
    mapObjIndexed(value => (Array.isArray(value) ? value[0] : value), urlParams)
  );

const getParamDiff = (first: string | string[], second?: string | string[]) => {
  if (!second) return first;

  if (!Array.isArray(first)) return null;

  if (!Array.isArray(second)) return first.filter(item => item !== second);

  return first.filter(item => !second.some(otherItem => otherItem === item));
};

const getParamsDiff = (first: UrlParams, second: UrlParams): UrlParams =>
  Object.keys(first).reduce((params, key) => {
    const diff = getParamDiff(first[key], second[key]);
    return diff ? { ...params, [key]: diff } : params;
  }, {});

const usePathnameParams = () => {
  const location = useLocation();
  const params = useParams<PathnameParams>();
  // memoize pathname params based on location.pathname to avoid it being a different object every time useUrlParams is called
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => params, [location.pathname]);
};

export const useUrlParams = () => {
  const history = useHistory();
  const location = useLocation();
  const pathnameParams = usePathnameParams();
  const { queryParams } = useQueryParams();

  const params = useMemo(
    () => mergeParams(queryParams, pathnameParams),
    [queryParams, pathnameParams]
  );

  const prevParams = usePrevious(params);

  const route = useMemo(() => findRouteFor(location.pathname), [location.pathname]);

  const getHrefFor = useCallback(
    (params: UrlParams) => {
      if (!route) throw new Error('useUrlParams: route not found');

      const pathnameParams = convertUrlParamsToPathnameParams(params);
      const pathname = hrefFor[route.key](pathnameParams);
      const match = matchPath(pathname, route);

      if (!match) throw new Error('useUrlParams: no match');

      const queryParams = getParamsDiff(params, match.params);
      const queryString = generateQueryString(queryParams);

      return queryString ? `${pathname}?${queryString}` : pathname;
    },
    [route]
  );

  const setParams = useCallback(
    (params: UrlParams, { replace }: { replace: boolean } = { replace: false }) => {
      const href = getHrefFor(params);
      if (replace) {
        history.replace(href);
      } else {
        history.push(href);
      }
    },
    [getHrefFor, history]
  );

  const addParams = useCallback(
    (newParams: UrlParams) => setParams({ ...params, ...newParams }),
    [params, setParams]
  );

  const removeParams = useCallback(
    (paramsToBeRemoved: string[]) => setParams(omit(paramsToBeRemoved, params)),
    [params, setParams]
  );

  return {
    params,
    prevParams,
    queryParams,
    pathnameParams,
    getHrefFor,
    setParams,
    addParams,
    removeParams
  };
};
