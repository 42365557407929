import { CONFIG } from '../config';

export const loadScript = (src: string, id?: string) => {
  if (document.querySelector(`script[src="${src}"]`)) {
    return;
  }

  const script = document.createElement('script');

  script.async = true;
  script.defer = true;
  script.src = src;

  if (id) {
    script.id = id;
  }

  document.body.appendChild(script);
};

export const loadAmazonLoginScript = () => {
  window.onAmazonLoginReady = () => {
    window.amazon?.Login.setClientId(CONFIG.AMAZON_CLIENT_ID);
  };

  loadScript('https://assets.loginwithamazon.com/sdk/na/login1.js', 'amazon-login-sdk');
};

export const loadAmazonWidgetScript = () => {
  window.onAmazonLoginReady = () => {
    window.amazon?.Login.setClientId(CONFIG.AMAZON_CLIENT_ID);
  };

  loadScript(CONFIG.AMAZON_WIDGET_SCRIPT_LOCATION, 'amazon-widgets');
};

export const loadRecaptchaV2Script = () => {
  if (document.querySelector('.g-recaptcha')) {
    return;
  }

  const div = document.createElement('div');

  div.className = 'g-recaptcha';
  div.dataset.size = 'invisible';
  div.dataset.sitekey = CONFIG.RECAPTCHA_V2_KEY;
  div.dataset.callback = 'onResolveRecaptchaV2';
  document.body.appendChild(div);
  loadScript('https://www.google.com/recaptcha/api.js', 'recaptcha-v2-sdk');
};

export const loadRecaptchaV3Script = () => {
  loadScript(
    `https://www.google.com/recaptcha/api.js?render=${CONFIG.RECAPTCHA_V3_KEY}`,
    'recaptcha-v3-sdk'
  );
};

export const loadAdyenScript = () => {
  loadScript(CONFIG.ADYEN_SCRIPT_LOCATION, 'adyen-sdk');
};

export const loadPayPalScript = () => {
  loadScript('https://www.paypalobjects.com/api/checkout.js', 'paypal-sdk');
};

export const loadBraintreeScript = () => {
  loadScript('https://js.braintreegateway.com/web/3.62.2/js/client.min.js', 'braintree-sdk');
};

export const loadBraintreePayPalCheckoutScript = () => {
  loadScript(
    'https://js.braintreegateway.com/web/3.62.2/js/paypal-checkout.min.js',
    'braintree-paypal-checkout-sdk'
  );
};
