import { GoogleAnalyticsProxy } from './googleAnalyticsTools';

export const makeGoogleAnalyticsAdjustments = function () {
  const GAP = new GoogleAnalyticsProxy();

  // I don't know why, but GA doesn't automatically pick up the hostname.
  GAP.asap(ga => ga('set', 'hostname', document.location.hostname));

  GAP.on('buildHitTask', model => {
    // Don't send feature flag-relates CDs unless tracking an Experiment.
    if (model.get('eventAction') !== 'Experiment Viewed') {
      model.set('dimension17', undefined);
      model.set('dimension18', undefined);
    }
    // Don't use the category of a product as the GA category.
    if (model.get('eventAction') === 'Product Viewed') {
      model.set('eventCategory', 'Product Discovery');
    }
    if (model.get('eventAction') === 'Product Added') {
      model.set('eventCategory', 'Bag');
    }
  });
};
