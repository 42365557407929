export const getActiveVariantBy = <T>(
  { masterVariant, otherVariants }: { masterVariant?: T | null; otherVariants?: (T | null)[] },
  key: string | ((variant: T) => unknown),
  value: unknown
): T => {
  const xs = [masterVariant, ...(otherVariants || [])].filter(Boolean) as T[];
  return (
    xs.find(
      variant =>
        variant && (typeof key === 'function' ? key(variant) : variant[key as keyof T]) === value
    ) || xs[0]
  );
};
