import React from 'react';
import classNames from 'classnames';
import { Text } from '@moda/om';
import { Link } from 'react-router-dom';
import { ResponsiveImage } from '../../ResponsiveImage';
import { EditorialNavigatorUnitFragment } from '../../../generated/types';
import { DesktopNavigationEditorialColumnText } from './DesktopNavigationEditorialColumnText';

import './DesktopNavigationEditorialColumn.scss';

interface Props {
  className?: string;
  content: EditorialNavigatorUnitFragment;
  onClick: () => void;
}

export const DesktopNavigationEditorialColumn: React.FC<Props> = ({
  className,
  content: { imageSrc, imageAlt, href, headline, anchorText },
  onClick
}) => (
  <div className={classNames('DesktopNavigationEditorialColumn', className)}>
    <Link className="DesktopNavigationEditorialColumn__anchor" to={href || ''} onClick={onClick}>
      {headline && (
        <Text treatment="eyebrow" className="DesktopNavigationEditorialColumn__header">
          {headline}
        </Text>
      )}

      {!headline && <div className="DesktopNavigationEditorialColumn__header-placeholder" />}

      {imageSrc && (
        <ResponsiveImage
          className="DesktopNavigationEditorialColumn__image"
          src={imageSrc}
          maxWidth={160}
          maxHeight={260}
          alt={imageAlt || 'Editorial Image'}
        />
      )}

      {anchorText && <DesktopNavigationEditorialColumnText text={anchorText} />}
    </Link>
  </div>
);
