import React, { useCallback, useEffect, useState } from 'react';
import { FeatureFlagProvider } from '@moda/portal-stanchions';
import { trackFeatureFlagView } from '../../lib/trackFeatureFlagView';

export const DiscoveryFeatureFlagProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const [key, setKey] = useState(Date.now());

  const handleResetFlags = useCallback(() => setKey(Date.now()), [setKey]);

  useEffect(() => {
    window.addEventListener('resetcache', handleResetFlags);
    return () => window.removeEventListener('resetcache', handleResetFlags);
  });

  return (
    <FeatureFlagProvider
      key={key}
      flags={window.__FEATURE_FLAGS__ || {}}
      onFlagRequested={trackFeatureFlagView}
    >
      {children}
    </FeatureFlagProvider>
  );
};
