import React from 'react';
import { ROUTE_CATEGORIES } from '../constants';
import { RedirectWithQueryParams } from './LegacyRoutes';

export const LEGACY_CATEGORY_ROUTE = [
  <RedirectWithQueryParams
    key="LegacyCategoryRoute"
    from={`/shop/:category(${ROUTE_CATEGORIES})/:subcategory?`}
    to={`/women/products/:category(${ROUTE_CATEGORIES})/:subcategory?`}
    exact
  />,
  <RedirectWithQueryParams
    key="LegacyCategoryShopRoute"
    from="/shop"
    to="/women/products/clothing"
    exact
  />,
  <RedirectWithQueryParams
    key='"DesignerMistakeRoute'
    from="/designer/zimmermann/collection/r233"
    to="/designer/zimmermann/collection/r23"
    exact
  />,
  <RedirectWithQueryParams
    key="LegacyCategoryShopNewRoute"
    from="/shop/new"
    to="/women/products/new"
    exact
  />,
  <RedirectWithQueryParams
    key="LegacyBridalEditorial"
    from="/editorial/the-wedding-salon"
    to="/editorial/top-micro-wedding-2021-trends"
    exact
  />,
  <RedirectWithQueryParams
    key="VogueArticle"
    from="/beauty/p/dr-devgan-scientific-beauty/find-your-golden-ratio-with-dr-devgan/571069"
    to="/beauty/p/dr-lara-devgan-scientific-beauty/find-your-golden-ratio-with-dr-devgan/580412"
    exact
  />,
  <RedirectWithQueryParams
    key="LegacyLaurenEditorial"
    from="/editorial/lauren-santo-domingo"
    to="/shop/laurens-closet"
    exact
  />,
  <RedirectWithQueryParams
    key="LegacyModaPrivate"
    from="/editorial/moda-private"
    to="/moda-private"
    exact
  />,
  <RedirectWithQueryParams
    key="ClubModa"
    from="/designer/club-moda-looks/collection/r23"
    to="/shop/club-moda"
    exact
  />,
  <RedirectWithQueryParams
    key="ClubModaSummer"
    from="/designer/club-moda-summer/collection/pf23"
    to="/shop/club-moda-summer"
    exact
  />,
  <RedirectWithQueryParams
    key="LaurensHomeEditorial"
    from="/editorial/lauren-santo-domingo-home"
    to="/shop/laurens-closet/laurens-home"
    exact
  />
];
