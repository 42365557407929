import { useEffect } from 'react';
import { tc } from '../../lib/trackingContext';
import { useCountry } from '../../components/CountrySelector';
import { useCookies } from '../useCookies';
import { useSkippableQuery } from '../useSkippableQuery';
import { useCartVersion } from './useCartVersion';
import { GET_CART_QUERY, Cart, CartPrice, CartVersion } from './cart.rest';

export { CartVersion };

const NULL_PRICE: CartPrice = {
  __typename: 'RestCartPrice',
  currency: 'usd',
  price: '0'
};

export const DEFAULT_CART: Cart = {
  __typename: 'RestUserCartData',
  type: 'carts',
  attributes: {
    __typename: 'RestUserCartAttributes',
    addressId: null,
    cartItemsCount: 0,
    isGiftingAllowed: false,
    giftNotes: null,
    isGiftOrder: false,
    currency: 'USD',
    cartItems: [],
    country2AlphaCode: 'US',
    countryCode: 'US',
    giftCode: null,
    error: null,
    userPromotions: null,
    paymentSummary: {
      __typename: 'RestCartPaymentSummary',
      subtotal: NULL_PRICE,
      total: NULL_PRICE,
      estimatedTaxes: NULL_PRICE,
      estimatedVat: NULL_PRICE,
      estimatedDuty: NULL_PRICE,
      estimatedGst: NULL_PRICE,
      estimatedSalesTax: NULL_PRICE,
      totalTaxes: NULL_PRICE,
      vat: NULL_PRICE,
      duties: NULL_PRICE,
      gst: NULL_PRICE,
      salesTax: NULL_PRICE,
      estimatedShipping: NULL_PRICE,
      totalShipping: NULL_PRICE,
      promotionDiscount: NULL_PRICE,
      siteCredit: NULL_PRICE,
      payToday: NULL_PRICE,
      payLater: NULL_PRICE,
      salesLabel: 'Sales Tax',
      gstLabel: 'GST',
      vatLabel: 'VAT',
      dutyLabel: 'Taxes/Duties',
      isShippingPromotionApplied: false,
      rewardsAmount: NULL_PRICE,
      cardPaymentToday: NULL_PRICE,
      giftCardPaymentToday: NULL_PRICE
    },
    priceChangedMessage: null,
    shippingMethods: null,
    siteCreditAvailable: null,
    selectedShippingMethodId: null,
    paymentMethodId: null,
    paymentSource: null,
    paypalClientToken: null,
    rewardPoints: null,
    rewardSummary: null,
    totalEarnedRewardPoints: null,
    applePaymentSummary: null,
    version: CartVersion.MiniBag,
    giftCard: null
  }
};

export const useCart = ({ ssr = true }: { ssr?: boolean } = { ssr: true }) => {
  const {
    cookies: { cartId },
    deleteCookie
  } = useCookies();
  const { country } = useCountry();
  const cartVersion = useCartVersion();
  const {
    data,
    loading: loadingQuery,
    error,
    refetch
  } = useSkippableQuery(GET_CART_QUERY, {
    variables: {
      cartId: cartId || '',
      cartVersion,
      countryCode: country.alpha2Code
    },
    skip: !cartId,
    ssr
  });
  const cart = data?.userCart?.data[0];
  // loadingQuery remains false even when we refetch, so we also check if the version or country has changed
  const loading =
    loadingQuery ||
    (cart?.attributes.version && cart?.attributes.version !== cartVersion) ||
    (cart && cart.attributes.countryCode !== country.alpha2Code);

  // refetch cart if the required version has changed (e.g. plp => shopping bag)
  // or if the user selects another country
  useEffect(() => {
    if (
      cart &&
      (cart.attributes.version !== cartVersion ||
        cart.attributes.countryCode !== country.alpha2Code)
    ) {
      refetch({ cartId: cart.id, cartVersion, countryCode: country.alpha2Code });
    }
  }, [cart, cartVersion, country, refetch]);

  // clear cartId cookie if the cart is not found
  useEffect(() => {
    if (data && data.userCart == null) {
      deleteCookie('cartId');
    }
  }, [data, deleteCookie]);

  useEffect(() => {
    if (cart) tc.setCurrent('cart', cart);
  }, [cart]);

  return {
    cart: cart || DEFAULT_CART,
    loaded: typeof cart !== 'undefined' && !loading,
    loading: loading,
    error
  };
};
