import React, { useEffect, useState } from 'react';
import { Button, ControlLink, Text, Stack } from '@moda/om';
import { AuthAction } from '../../lib/authenticate';
import { HandleAuthBaseProps, useAuth } from '../../hooks/useAuth';
import { useAuthModal } from '../../hooks/useAuthModal';
import { tc } from '../../lib/trackingContext';
import { EmailVerificationStatusModal } from './EmailVerificationStatusModal';

export type EmailVerificationStatusPendingProps = {
  show: boolean;
  user: HandleAuthBaseProps;
  onHide: () => void;
};

export const EmailVerificationStatusPending: React.FC<EmailVerificationStatusPendingProps> = ({
  show,
  user,
  onHide
}) => {
  const [emailResent, setEmailResent] = useState(false);
  const { handleAuth } = useAuth(AuthAction.Register);
  const { openAuthModal } = useAuthModal();

  useEffect(() => {
    if (show) tc.track('Verify Email Window Viewed');
  }, [show]);

  const onBack = () => {
    tc.track('Verify Email Window Back Clicked');
    onHide();
    openAuthModal({
      defaultValues: {
        registerFirstName: user.first_name,
        registerLastName: user.last_name,
        registerEmail: user.email,
        registerPassword: user.password,
        registerRemember: user.remember,
        registerEmailList: user.opted_in
      }
    });
  };

  const onResendEmail = () => {
    tc.track('Verify Email Window Resend Clicked');
    handleAuth(user);
    setEmailResent(true);
  };

  return (
    <EmailVerificationStatusModal
      show={show}
      title="Verify Your Email"
      onHide={() => {
        tc.track('Verify Email Window Quit Clicked');
        onHide();
      }}
      onBack={onBack}
    >
      <Text>
        To begin using your account, please complete the email verification process by clicking the
        link sent to your email address at <Text treatment="bold1">{user.email}</Text>.
      </Text>
      <Stack space={4}>
        <Button
          onClick={() => {
            tc.track('Verify Email Window Done Clicked');
            window.location.reload();
          }}
        >
          Done
        </Button>
        {emailResent ? (
          <Text>
            Email was sent. If you did not get the email contact us at{' '}
            <ControlLink href="mailto:care@modaoperandi.com">care@modaoperandi.com</ControlLink>
          </Text>
        ) : (
          <Text>
            <ControlLink underlined={false} onClick={onResendEmail}>
              Resend Email
            </ControlLink>
          </Text>
        )}
      </Stack>
    </EmailVerificationStatusModal>
  );
};
