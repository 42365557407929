import {
  YEARS_TO_DAYS,
  DAYS_TO_HOURS,
  HOURS_TO_MINUTES,
  MINUTES_TO_SECONDS,
  SECONDS_TO_MILLISECONDS
} from '@moda/portal-stanchions';

export const REDIRECT_WHEN_TRUNKSHOW_EXPIRED_SINCE_MS =
  (YEARS_TO_DAYS / 2) *
  DAYS_TO_HOURS *
  HOURS_TO_MINUTES *
  MINUTES_TO_SECONDS *
  SECONDS_TO_MILLISECONDS;

export const TRUNKSHOW_SEASON_REGEXP = /^[a-z]{1,2}\d{2}$/;
