import React, { useCallback, useContext, useEffect, useReducer, useMemo } from 'react';
import { tryCatch, uniq } from 'ramda';
import { noop } from '@moda/portal-stanchions';
import { useShowAdminModeToggle } from '../useShowAdminModeToggle';

const DEFAULT_VALUE = {
  enabled: false,
  enable: noop,
  disable: noop,
  productIds: [] as string[],
  variantIds: [] as string[],
  addProductId: noop as (productId: string) => void,
  removeProductId: noop as (productId: string) => void,
  addVariantId: noop as (variantId: string) => void,
  removeVariantId: noop as (variantId: string) => void,
  removeVariantIds: noop,
  removeProductIds: noop,
  setProductIds: noop as (productIds: string[]) => void,
  setVariantIds: noop as (variantIds: string[]) => void
};

type Action =
  | { type: 'ENABLE' }
  | { type: 'DISABLE' }
  | { type: 'SET_PRODUCT_IDS'; payload: { value: string[] } }
  | { type: 'SET_VARIANT_IDS'; payload: { value: string[] } };

type State = {
  enabled: boolean;
  productIds: string[];
  variantIds: string[];
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ENABLE': {
      return {
        ...state,
        enabled: true
      };
    }

    case 'DISABLE': {
      return {
        ...state,
        enabled: false
      };
    }

    case 'SET_PRODUCT_IDS': {
      return {
        ...state,
        productIds: action.payload.value
      };
    }

    case 'SET_VARIANT_IDS': {
      return {
        ...state,
        variantIds: action.payload.value
      };
    }

    default:
      return state;
  }
};

const AdminModeContext = React.createContext(DEFAULT_VALUE);

const ADMIN_MODE_DATA_LOCAL_STORAGE_KEY = 'adminModeData';

const useAdminModeDataFromLocalStorage = () => {
  const featureEnabled = useShowAdminModeToggle();

  return useMemo(() => {
    if (!featureEnabled) return;
    if (typeof window === 'undefined') return;

    const {
      localStorage: { [ADMIN_MODE_DATA_LOCAL_STORAGE_KEY]: adminModeData }
    } = window;

    if (!adminModeData) return;

    return tryCatch(
      () => JSON.parse(adminModeData) as State,
      () => undefined
    )();
  }, [featureEnabled]);
};

export const AdminModeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const adminModeDataFromLocalStorage = useAdminModeDataFromLocalStorage();

  const [state, dispatch] = useReducer(reducer, {
    enabled: false,
    productIds: [],
    variantIds: []
  });

  const addProductId = useCallback(
    (productId: string) => {
      dispatch({
        type: 'SET_PRODUCT_IDS',
        payload: { value: uniq([...state.productIds, productId]) }
      });
    },
    [state.productIds]
  );

  const removeProductId = useCallback(
    (productId: string) => {
      dispatch({
        type: 'SET_PRODUCT_IDS',
        payload: {
          value: state.productIds.filter(existingProductId => existingProductId !== productId)
        }
      });
    },
    [state.productIds]
  );

  const addVariantId = useCallback(
    (variantId: string) => {
      dispatch({
        type: 'SET_VARIANT_IDS',
        payload: { value: uniq([...state.variantIds, variantId]) }
      });
    },
    [state.variantIds]
  );

  const removeVariantId = useCallback(
    (variantId: string) => {
      dispatch({
        type: 'SET_VARIANT_IDS',
        payload: {
          value: state.variantIds.filter(existingVariantId => existingVariantId !== variantId)
        }
      });
    },
    [state.variantIds]
  );

  const setProductIds = useCallback(
    (productIds: string[]) =>
      dispatch({
        type: 'SET_PRODUCT_IDS',
        payload: {
          value: productIds
        }
      }),
    []
  );

  const setVariantIds = useCallback(
    (variantIds: string[]) =>
      dispatch({
        type: 'SET_VARIANT_IDS',
        payload: {
          value: variantIds
        }
      }),
    []
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.localStorage.setItem(ADMIN_MODE_DATA_LOCAL_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (adminModeDataFromLocalStorage) {
      dispatch({ type: adminModeDataFromLocalStorage.enabled ? 'ENABLE' : 'DISABLE' });
      setProductIds(adminModeDataFromLocalStorage.productIds);
      setVariantIds(adminModeDataFromLocalStorage.variantIds);
    }
  }, [adminModeDataFromLocalStorage, setProductIds, setVariantIds]);

  return (
    <AdminModeContext.Provider
      value={{
        productIds: state.productIds,
        variantIds: state.variantIds,
        enabled: state.enabled,
        addProductId,
        removeProductId,
        removeProductIds: () => dispatch({ type: 'SET_PRODUCT_IDS', payload: { value: [] } }),
        addVariantId,
        removeVariantId,
        removeVariantIds: () => dispatch({ type: 'SET_VARIANT_IDS', payload: { value: [] } }),
        enable: () => dispatch({ type: 'ENABLE' }),
        disable: () => dispatch({ type: 'DISABLE' }),
        setProductIds,
        setVariantIds
      }}
    >
      {children}
    </AdminModeContext.Provider>
  );
};

export const useAdminMode = () => useContext(AdminModeContext);
