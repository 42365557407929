/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperationDefinitionNode, FieldNode } from 'graphql';
import { Cache, InMemoryCache, InMemoryCacheConfig, NormalizedCacheObject } from '@apollo/client';
import readOnlyQueries from '../../generated/readOnlyQueries.json';

const getQueryName = (query: Cache.ReadOptions['query']) => {
  const definition = query.definitions.find(
    definition => (definition as OperationDefinitionNode).operation === 'query'
  );

  if (!definition) return null;

  return (definition as OperationDefinitionNode).name?.value;
};

const getQueryFields = (query: Cache.ReadOptions['query']) => {
  const definition = query.definitions.find(
    definition => (definition as OperationDefinitionNode).operation === 'query'
  );

  if (!definition) return null;

  return (definition as OperationDefinitionNode).selectionSet.selections
    .filter(selection => selection.kind === 'Field')
    .map(selection => (selection as FieldNode).name.value);
};

const isReadOnlyQuery = (query: Cache.ReadOptions['query']) => {
  const fields = getQueryFields(query);
  return fields ? fields.every(field => readOnlyQueries.includes(field)) : false;
};

const getCacheKey = ({
  query,
  variables
}: {
  query: Cache.ReadOptions['query'];
  variables?: Cache.ReadOptions['variables'];
}) => `${getQueryName(query)}(${JSON.stringify(variables)})`;

/*
  Optimized version of Apollo's InMemoryCache
  The optimization comes from storing the result of read-only queries without normalization and
  all the other processing Apollo does (watching fields for changes, etc.).
  We're taking advantage of the fact that the results of search-api queries never change in the SPA's lifetime.
*/
export class OptimizedInMemoryCache extends InMemoryCache {
  readOnlyQueryData: { [key: string]: any };

  constructor(config: InMemoryCacheConfig) {
    super(config);
    this.readOnlyQueryData = {};
  }

  extract(optimistic?: boolean) {
    return {
      ...super.extract(optimistic),
      readOnlyQueryData: this.readOnlyQueryData
    };
  }

  restore(data: NormalizedCacheObject) {
    const { readOnlyQueryData, ...rest } = data || {};
    this.readOnlyQueryData = readOnlyQueryData || {};
    return super.restore(data && rest);
  }

  reset() {
    this.readOnlyQueryData = {};
    return super.reset();
  }

  read<T>(options: Cache.ReadOptions) {
    if (isReadOnlyQuery(options.query)) {
      return this.readOnlyQueryData[getCacheKey(options)] ?? null;
    }

    return super.read<T>(options);
  }

  write(options: Cache.WriteOptions) {
    if (isReadOnlyQuery(options.query)) {
      this.readOnlyQueryData[getCacheKey(options)] = options.result;
      this.broadcastWatches();
      return options.result;
    }

    return super.write(options);
  }

  diff<T>(options: Cache.DiffOptions) {
    if (isReadOnlyQuery(options.query)) {
      const result = this.readOnlyQueryData[getCacheKey(options)] ?? null;

      return {
        result,
        complete: result != null
      };
    }

    return super.diff<T>(options);
  }
}
