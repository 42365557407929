import { gql } from '@apollo/client';
import React from 'react';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import { Constrain } from '@moda/om';
import classNames from 'classnames';
import { Link } from '../../Link';
import { useEnableClubModaTreatment } from '../../../hooks/useEnableClubModaTreatment';
import { DesktopNavigation } from '../../DesktopNavigation';
import { DesktopNavigationMainContent } from '../../DesktopNavigation/DesktopNavigationMainContent';
import { DesktopSiteNavActions } from './DesktopSiteNavActions';
import { DESKTOP_NAV_ITEM_FRAGMENT } from './DesktopSiteNavItem';

import './DesktopSiteNav.scss';

export const DESKTOP_SITE_NAV_FRAGMENT = gql`
  fragment DesktopSiteNavFragment on DisplayPage {
    navItems {
      ...DesktopSiteNavItemFragment
    }
  }
  ${DESKTOP_NAV_ITEM_FRAGMENT}
`;

export const DesktopSiteNav: React.FC = ({ ...rest }) => {
  const enableClubModaTreatment = useEnableClubModaTreatment();

  return (
    <nav className="DesktopSiteNav" {...rest}>
      <Constrain className="DesktopSiteNav__primary">
        <DesktopNavigation />
        <div className="DesktopSiteNav__logo" data-testid="logo">
          <Link aria-label="Moda Operandi Home Page" to="/">
            <LogoHorizontalIcon
              width="100%"
              height="100%"
              className={classNames('DesktopSiteNav__logo-icon', {
                'DesktopSiteNav__logo-icon--club': enableClubModaTreatment
              })}
            />
          </Link>
        </div>
        <DesktopSiteNavActions />
      </Constrain>

      <Constrain>
        <DesktopNavigationMainContent />
      </Constrain>
    </nav>
  );
};
