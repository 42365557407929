import { useQuery, gql, TypedDocumentNode } from '@apollo/client';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Vertical, VERTICALS } from '@moda/portal-stanchions';
import { useUrlParams } from '../../hooks/useUrlParams';
import { Loading } from '../../components/Loading';
import { NotFoundRoute } from '../NotFoundRoute';
import { hrefFor } from '../PageRoutes';
import { BareSlugRedirectQuery, BareSlugRedirectQueryVariables } from '../../generated/types';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { RedirectWithQueryParams } from './LegacyRoutes';

export const BARE_SLUG_REDIRECT_QUERY: TypedDocumentNode<
  BareSlugRedirectQuery,
  BareSlugRedirectQueryVariables
> = gql`
  query BareSlugRedirectQuery($slug: String!, $productHref: String!) {
    trunkshow(slug: $slug) {
      id
    }
    designer(slug: $slug) {
      id
    }
    variant(href: $productHref) {
      gender
      designerSlug: designer_slug
      masterVariant: master_variants_data {
        id
        color
      }
      otherVariants: other_variants_data {
        id
        color
      }
    }
  }
`;

export const BareSlugRedirect = ({
  match: {
    params: { slug, productSlug, vertical }
  }
}: {
  match: {
    params: {
      slug: string;
      productSlug?: string;
      vertical: string;
    };
  };
}) => {
  const { data, loading, error } = useQuery(BARE_SLUG_REDIRECT_QUERY, {
    variables: {
      slug,
      productHref: productSlug ? `/${slug}/${productSlug}` : 'none'
    }
  });
  const {
    params: { color }
  } = useUrlParams();

  if (error) throwQueryError(error);
  if (loading) return <Loading />;

  if (productSlug && data?.variant?.designerSlug) {
    const { masterVariant, otherVariants } = data.variant;
    const vertical = VERTICALS[data.variant.gender as Vertical];
    const variant = color
      ? [masterVariant, ...otherVariants].find(variant => variant?.color === color) || masterVariant
      : masterVariant;
    return (
      <RedirectWithQueryParams
        from="/:slug/:productSlug?"
        to={hrefFor.ProductDetailPage({
          vertical,
          designerSlug: data.variant.designerSlug,
          productSlug,
          variantId: variant.id
        })}
      />
    );
  }

  if (data?.trunkshow) {
    return (
      <Redirect
        to={hrefFor.TrunkshowPage({
          trunkshowSlug: slug,
          vertical: vertical
        })}
      />
    );
  }

  if (data?.designer) {
    return (
      <Redirect
        to={hrefFor.DesignerPage({
          vertical: vertical,
          designerSlug: slug
        })}
      />
    );
  }

  return <NotFoundRoute />;
};

export const BareSlugRedirectRoute = () => (
  <Route path="/:slug/:productSlug?" component={BareSlugRedirect} />
);
