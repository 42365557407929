import React from 'react';
import { ControlLink, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import { PopupLink } from '../../PopupLink';
import { SITE_FOOTER_LINKS_CONFIG } from './siteFooterLinksConfig';

import './SiteFooterLinks.scss';

interface Props {
  className?: string;
}

export const SiteFooterLinks: React.FC<Props> = ({ className }) => {
  return (
    <Stack
      className={classNames('SiteFooterLinks', className)}
      direction="horizontal"
      justifyContent="space-between"
      space={4}
    >
      {SITE_FOOTER_LINKS_CONFIG.map(siteFooterLinkCategory => (
        <Stack className="SiteFooterLinks__category" key={siteFooterLinkCategory.name} space={4}>
          <Text className="SiteFooterLinks__category-name" treatment="eyebrow">
            {siteFooterLinkCategory.name}
          </Text>

          <Stack space={2}>
            {siteFooterLinkCategory.description?.map(descriptionLine => (
              <Text key={descriptionLine} treatment="body2">
                {descriptionLine}
              </Text>
            ))}

            {siteFooterLinkCategory.links.map(siteFooterLink => (
              <div key={siteFooterLink.label}>
                {siteFooterLink.isPopup && (
                  <PopupLink
                    href={siteFooterLink.href}
                    underlined={false}
                    rel="noopener noreferrer"
                  >
                    {siteFooterLink.label}
                  </PopupLink>
                )}

                {!siteFooterLink.isPopup && (
                  <ControlLink
                    href={siteFooterLink.href}
                    underlined={false}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {siteFooterLink.label}
                  </ControlLink>
                )}
              </div>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
