import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Text, useBreakpoint } from '@moda/om';
import { hrefFor } from '../../routers';
import { useAuthModal } from '../../hooks/useAuthModal';
import { tc } from '../../lib/trackingContext';
import { EmailVerificationStatusModal } from './EmailVerificationStatusModal';

export type EmailVerificationStatusErrorProps = { show: boolean; onHide: () => void };

export const EmailVerificationStatusError: React.FC<EmailVerificationStatusErrorProps> = ({
  show,
  onHide
}) => {
  const { matches: isMobile } = useBreakpoint({ lt: 'sm' });
  const history = useHistory();
  const { openAuthModal } = useAuthModal();

  useEffect(() => {
    if (show) tc.track('Link Expired Window Viewed');
  }, [show]);

  const handleHide = () => {
    tc.track('Link Expired Window Quit Clicked');
    onHide();
    history.replace(hrefFor.HomePage());
  };

  const handleButtonClick = () => {
    tc.track('Link Expired Window Re-Enter Info Clicked');
    onHide();
    if (isMobile) return history.replace(hrefFor.RegisterPage());
    history.replace(hrefFor.HomePage());
    openAuthModal({ defaultValues: { registerEmail: '' } });
  };

  return (
    <EmailVerificationStatusModal show={show} title="Verify Your Email" onHide={handleHide}>
      <Text>
        The email we have sent you has expired. Please create an account again and verify your email
        address.
      </Text>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <Button autoFocus onClick={handleButtonClick}>
        Create Account
      </Button>
    </EmailVerificationStatusModal>
  );
};
