import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSignifydSessionId } from '../../hooks/useSignifydSessionId';
import { ClientSideOnly } from '../ClientSideOnly';

export const SignifydScript: React.FC = () => {
  const { signifydSessionId } = useSignifydSessionId({ createIfNotExists: true });

  if (!signifydSessionId) return null;

  return (
    <ClientSideOnly>
      <Helmet>
        <script
          id="sig-api"
          src="https://cdn-scripts.signifyd.com/api/script-tag.js"
          async
          defer
          data-order-session-id={signifydSessionId}
        />
      </Helmet>
    </ClientSideOnly>
  );
};
