import React from 'react';
import { Stack } from '@moda/om';
import { RefreshedMobileSiteNavMenuLinkItem } from '../RefreshedMobileSiteNavMenuLinkItem';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';

type SubItem = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['mainCategories'][number]['mainCategoryItems'][number]['subItems'][number];

interface Props {
  data: SubItem;
  onClose: () => void;
  parentLabel: string;
}

export const RefreshedMobileNavMainCategoryItem: React.FC<Props> = ({
  data: { listLinks, label: parentCategory },
  onClose,
  parentLabel
}) => {
  if (!listLinks) return null;

  if (!listLinks.length) return null;

  return (
    <Stack className="RefreshedMobileNavMainCategoryItem" space={0}>
      {listLinks.map(({ url, label }, index) => (
        <RefreshedMobileSiteNavMenuLinkItem
          key={index}
          url={url}
          label={label}
          onClick={onClose}
          parentLabel={`${parentLabel}/${parentCategory}`}
        />
      ))}
    </Stack>
  );
};
