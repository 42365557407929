import { Stack } from '@moda/om';
import React, { useCallback, useState } from 'react';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-24';
import { useUser } from '../../../hooks/useUser';
import { hrefFor } from '../../../routers';
import { useCountry } from '../../CountrySelector';
import { MobileSiteNavOption } from '../../SiteNav/MobileSiteNavOption';
import { MobileNavV2Header } from '../MobileNavV2Header';
import { MobileNavV2Tab } from '../MobileNavV2Tab';
import { MobileSiteNavCountrySelector } from '../../SiteNav/MobileSiteNavCountrySelector';

import './MobileNavV2Content.scss';

enum Pane {
  Default,
  CurrencyChange
}

interface Props {
  onClose: () => void;
}

export const MobileNavV2Content: React.FC<Props> = ({ onClose }) => {
  const [selectedPane, setSelectedPane] = useState(Pane.Default);
  const { user } = useUser();
  const {
    country: { alpha2Code: currency }
  } = useCountry();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeTab = useCallback((position: number) => setSelectedTabIndex(position), []);

  return (
    <Stack className="MobileNavV2Content" space={0}>
      {selectedPane === Pane.Default && (
        <>
          <MobileNavV2Header selectedTabIndex={selectedTabIndex} onChange={handleChangeTab} />

          <div className="MobileNavV2Content__tab-content">
            <MobileNavV2Tab selectedTabIndex={selectedTabIndex} onClose={onClose} />
          </div>

          <div className="MobileNavV2Content__user-actions">
            {!user.isLoggedIn ? (
              <MobileSiteNavOption
                onClick={onClose}
                href={hrefFor.RegisterPage({ redirectTo: location.pathname })}
              >
                Sign In/Register
              </MobileSiteNavOption>
            ) : (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.AccountSettings()}>
                My Account
              </MobileSiteNavOption>
            )}

            <MobileSiteNavOption onClick={() => setSelectedPane(Pane.CurrencyChange)}>
              My Currency ({currency})
            </MobileSiteNavOption>

            <MobileSiteNavOption onClick={onClose} href={hrefFor.FavoritesPage()}>
              My Favorites <FavoriteOutlineIcon />
            </MobileSiteNavOption>

            {user.isLoggedIn && (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.LogoutPage()}>
                Sign Out
              </MobileSiteNavOption>
            )}
          </div>
        </>
      )}

      {selectedPane === Pane.CurrencyChange && (
        <MobileSiteNavCountrySelector onClose={() => setSelectedPane(Pane.Default)} />
      )}
    </Stack>
  );
};
