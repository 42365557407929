import { gql, TypedDocumentNode } from '@apollo/client';
import { PaymentSource } from '../../constants';

export type LineItemSKU = {
  __typename: 'RestLineItemSKU';
  id: number;
  size: string;
  available: number;
  disabled: boolean;
  selected: boolean;
};

export type CartPrice = {
  __typename: 'RestCartPrice';
  currency: string;
  price: number | string;
};

export type UserPromotions = {
  __typename: 'RestUserPromotions';
  detail: string;
  isValid: boolean;
  name: string;
  offerCode: string;
  discountPercentage: string | null | undefined;
};

export type GiftCard = {
  __typename: 'RestGiftCard';
  balance: string;
  currency: string;
  paymentId: number;
  cardNumber: string;
  expiryDate: string;
  giftCardError: string;
};

export type CartError = {
  __typename: 'RestCartError';
  message: string;
  type: {
    __typename: 'RestCartErrorType';
    name: string;
  };
};

export type LineItemType = 'cart_items' | 'saved_items';

export type LineItemError = {
  __typename: 'RestLineItemError';
  message: string;
  type: string;
};

export type LineItem = {
  __typename: 'RestLineItemData';
  id: string;
  type: LineItemType;
  attributes: {
    __typename: 'RestLineItemAttributes';
    activeEndAt: number | null | undefined;
    backInStock: boolean;
    color: string | null | undefined;
    category: string[];
    customerDeliveryNotes: string | null | undefined;
    customization: string | null | undefined;
    designerName: string;
    error: LineItemError | null | undefined;
    expectedDeliveryTime: string | null | undefined;
    fulfilmentType: number | null;
    imageSrc: string;
    inventory: LineItemSKU[];
    isAvailableNow: boolean;
    isPreorder: boolean;
    isProductAvailable: boolean;
    isSoldOut: boolean;
    isTrunkshow: boolean;
    legacyUrl: string;
    movedAutomatically: boolean | null;
    markdownPercentage: number;
    discountedPrice: CartPrice;
    prices: {
      __typename: 'RestLineItemPrices';
      currentPrice: CartPrice;
      depositPrice: CartPrice;
      isDiscounted: boolean;
      originalPrice: CartPrice;
      totalBalancePayment: CartPrice;
      markdownDiscount: CartPrice;
      promotionDiscount: CartPrice;
    };
    productId: number;
    productName: string;
    promotionApplicableMessage: string | null;
    quantity: number;
    returnPolicy: string | undefined;
    returnPolicyCode: number;
    scarcityAlert: string | null | undefined;
    skuId: number;
    subcategory: string[];
    tag: string;
    variantHref: string;
    variantId: number;
    vatOnProductPrice: {
      __typename: 'RestLineItemVat';
      currency: string;
      human: string;
      price: number | string;
    };
  };
};

export type ShippingMethod = {
  __typename: 'RestShippingMethod';
  arrivalDate: string;
  charges: number | undefined;
  currency: string | undefined;
  displayName: string;
  transitTime: string;
  freeShipping: boolean;
  method: number;
};

export type RewardSummary = {
  __typename: 'RestRewardSummary';
  useLoyaltyRewards: boolean;
  enrolledForLoyalty: boolean;
  pointsRequired: number;
  rewardsExpired: boolean;
  currentPoints: number;
  totalRewardsPoints: number;
  redeemableAmount: RedeemableAmount;
  rewardsData: RewardsData;
};

export type RewardsData = {
  __typename: 'RestRewardsData';
  thresholdPointI: number;
  currencySymbol: string;
  currencyDisplay: string;
  thresholdPoint: string;
  thresholdAmount: number;
  bonusPoints: number;
  currencyUnit: string;
};

export type RedeemableAmount = {
  __typename: 'RestRedeemableAmount';
  human: string;
  price: number;
  currency: string;
  currencyHtmlCode: string;
};

export type ApplePaymentSummary = {
  __typename: 'RestCartApplePaymentSummary';
  data: {
    __typename: 'RestCartApplePaymentSummaryData';
    currency: string;
    total: {
      __typename: 'RestApplePaymentSummaryTotal';
      label: string;
      amount: string;
    };
    status: {
      __typename: 'RestApplePaymentSummaryStatus';
      code: string;
    };
    shippingContact: {
      __typename: 'RestApplePaymentSummaryShippingContact';
    } & ApplePayJS.ApplePayPaymentContact;
    lineItems: ({
      __typename: 'RestApplePaymentSummaryLineItem';
    } & ApplePayJS.ApplePayLineItem)[];
    shippingMethods: ({
      __typename: 'RestApplePaymentSummaryShippingMethod';
    } & ApplePayJS.ApplePayShippingMethod)[];
  };
};

export enum CartVersion {
  MiniBag = 'minibag',
  ShoppingBag = 'shopping_bag',
  Checkout = 'checkout'
}

type CommonCartAttributes = {
  __typename: 'RestUserCartAttributes';
  cartItemsCount: number;
  cartItems: { __typename: 'RestLineItem'; data: LineItem }[];
  country2AlphaCode: string;
  countryCode: string;
  currency: string;
  isGiftingAllowed: boolean;
  isGiftOrder: boolean;
  giftNotes: string | null;
  giftCard: GiftCard | null;
  userPromotions: UserPromotions[] | null;
  paymentSummary: {
    __typename: 'RestCartPaymentSummary';
    subtotal: CartPrice;
    total: CartPrice;
    estimatedTaxes: CartPrice;
    estimatedVat: CartPrice | null;
    estimatedDuty: CartPrice | null;
    estimatedGst: CartPrice | null;
    estimatedSalesTax: CartPrice | null;
    totalTaxes: CartPrice;
    vat: CartPrice | null;
    duties: CartPrice | null;
    gst: CartPrice | null;
    salesTax: CartPrice | null;
    estimatedShipping: CartPrice;
    totalShipping: CartPrice;
    promotionDiscount: CartPrice;
    siteCredit: CartPrice;
    payToday: CartPrice;
    payLater: CartPrice;
    salesLabel: string | null;
    gstLabel: string | null;
    vatLabel: string | null;
    dutyLabel: string | null;
    isShippingPromotionApplied: boolean;
    rewardsAmount: CartPrice;
    cardPaymentToday: CartPrice;
    giftCardPaymentToday: CartPrice | null;
  };
  priceChangedMessage: string | null;
};

type ShoppingBagCartAttributes = {
  giftCode: string | null;
  error: CartError | null;
  rewardPoints: number;
  shippingMethods: ShippingMethod[];
  siteCreditAvailable: CartPrice;
  paypalClientToken: string;
  rewardSummary: RewardSummary;
  totalEarnedRewardPoints: number;
  applePaymentSummary: ApplePaymentSummary;
};

export type CheckoutCartAttributes = {
  addressId: number | undefined;
  paymentMethodId: number | null | undefined;
  paymentSource: PaymentSource;
  selectedShippingMethodId: number;
};

type NullValues<T> = { [TKey in keyof T]: null };

export type MiniBagCart = {
  __typename: 'RestUserCartData';
  id?: string;
  type: 'carts';
  attributes: CommonCartAttributes &
    NullValues<ShoppingBagCartAttributes> &
    NullValues<CheckoutCartAttributes> & {
      version: CartVersion.MiniBag;
    };
};

export type ShoppingBagCart = Omit<MiniBagCart, 'attributes'> & {
  attributes: CommonCartAttributes &
    ShoppingBagCartAttributes &
    NullValues<CheckoutCartAttributes> & {
      version: CartVersion.ShoppingBag;
    };
};

export type CheckoutCart = Omit<MiniBagCart, 'attributes'> & {
  attributes: CommonCartAttributes &
    ShoppingBagCartAttributes &
    CheckoutCartAttributes & {
      version: CartVersion.Checkout;
    };
};

export type Cart = MiniBagCart | ShoppingBagCart | CheckoutCart;

export type GetCartQueryVariables = {
  cartId: string;
  cartVersion: CartVersion;
  countryCode: string;
};

export type GetCartQuery = {
  userCart: {
    __typename: 'RestUserCart';
    data: Cart[];
  };
};

const LINE_ITEM_SKU_FRAGMENT = gql`
  fragment LineItemSKUFragment on RestLineItemSKU {
    id: sku_id
    size: value
    available: quantity
    disabled
    selected
  }
`;

export const CART_PRICE_FRAGMENT = gql`
  fragment CartPriceFragment on RestCartPrice {
    currency
    price
  }
`;

const SHIPPING_METHOD_FRAGMENT = gql`
  fragment ShippingMethodFragment on RestShippingMethod {
    arrivalDate: arrival_date
    charges: charges
    currency: currency
    displayName: display_name
    transitTime: transit_time
    freeShipping: free_shipping
    method: method
  }
`;

const REWARD_SUMMARY_FRAGMENT = gql`
  fragment RewardSummaryFragment on RestRewardSummary {
    useLoyaltyRewards: use_loyalty_rewards
    enrolledForLoyalty: enrolled_for_loyalty
    pointsRequired: points_required
    rewardsExpired: rewards_expired
    currentPoints: current_points
    totalRewardsPoints: total_rewards_points
    redeemableAmount: redeemable_amount
    rewardsData: rewards_data
  }
`;

const USER_PROMOTIONS = gql`
  fragment UserPromotionsFragment on RestUserPromotions {
    detail: details
    isValid: is_valid
    name: name
    offerCode: offer_code
    discountPercentage: discount_percentage
  }
`;

const GIFT_CARD = gql`
  fragment GiftCardFragment on RestGiftCard {
    balance: balance
    currency: currency
    paymentId: payment_id
    cardNumber: card_number
    expiryDate: expiry_date
    giftCardError: gift_card_error
  }
`;

export const LINE_ITEM_FRAGMENT = gql`
  fragment LineItemFragment on RestLineItemData {
    id
    type
    attributes @type(name: "RestLineItemAttributes") {
      activeEndAt: active_end_i
      backInStock: back_in_stock
      color
      customerDeliveryNotes: customer_delivery_notes
      customization: customization
      designerName: designer
      error: errors @type(name: "RestLineItemError") {
        message
        type
      }
      expectedDeliveryTime: expected_delivery_time
      fulfilmentType: fulfilment_type
      imageSrc: image_medium
      inventory: size_options @type(name: "RestLineItemSKU") {
        ...LineItemSKUFragment
      }
      isAvailableNow: pay_in_full
      isPreorder: presell
      isProductAvailable: is_available
      isSoldOut: sold_out
      isTrunkshow: is_trunkshow
      legacyUrl: url
      movedAutomatically: moved_automatically
      markdownPercentage: markdown_percentage
      discountedPrice: discounted_price @type(name: "RestCartPrice") {
        ...CartPriceFragment
      }
      prices @type(name: "RestLineItemPrices") {
        currentPrice: current_price @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        depositPrice: deposit_price @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        originalPrice: original_price @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        totalBalancePayment: total_balance_payment @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        markdownDiscount: markdown_discount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        promotionDiscount: promotion_discount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        isDiscounted: markdown
      }
      productId: product_id
      productName: name
      promotionApplicableMessage: promotion_applicable_message
      quantity
      returnPolicy: return_policy
      returnPolicyCode: return_policy_code
      scarcityAlert: scarcity_alert
      skuId: sku_id
      tag
      variantHref: url
      variantId: variant_id
      vatOnProductPrice: vat_on_product_price @type(name: "RestLineItemVat") {
        currency
        human
        price
      }
    }
  }
  ${CART_PRICE_FRAGMENT}
  ${LINE_ITEM_SKU_FRAGMENT}
`;

export const CART_FRAGMENT = gql`
  fragment CartFragment on RestUserCartData {
    id
    type
    attributes @type(name: "RestUserCartAttributes") {
      addressId: address_id
      cartItemsCount: cart_items_count
      cartItems: order_items @type(name: "RestLineItem") {
        data @type(name: "RestLineItemData") {
          ...LineItemFragment
        }
      }
      giftNotes: gift_notes
      isGiftingAllowed: gifting_allowed
      isGiftOrder: is_gift_order
      countryCode: country_code
      country2AlphaCode: country_2_alpha_code
      currency
      giftCode: offer_code
      error @type(name: "RestCartError") {
        message
        type @type(name: "RestCartErrorType") {
          name
        }
      }
      userPromotions: user_promotions @type(name: "RestUserPromotions") {
        ...UserPromotionsFragment
      }
      paymentSummary: payment_summary @type(name: "RestCartPaymentSummary") {
        subtotal: cart_total @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        total: order_total @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedTaxes: estimated_taxes @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedVat: estimated_vat_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedGst: estimated_gst_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedDuty: esitmated_duty_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedSalesTax: estimated_sales_tax_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        vat: vat_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        duties: duty_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        gst: gst_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        salesTax: sales_tax_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        totalTaxes: total_taxes @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        estimatedShipping: estimated_shipping_charges @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        totalShipping: shipping_and_packaging @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        promotionDiscount: promotion_discount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        siteCredit: site_credit @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        payToday: card_payment_today @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        payLater: pay_later @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        salesLabel: sales_tax_label
        gstLabel: gst_label
        vatLabel: vat_label
        dutyLabel: duty_label
        isShippingPromotionApplied: shipping_promotion_applied
        rewardsAmount: rewards_amount @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        cardPaymentToday: card_payment_today @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
        giftCardPaymentToday: gift_card_payment_today @type(name: "RestCartPrice") {
          ...CartPriceFragment
        }
      }
      applePaymentSummary: apple_summary @type(name: "RestCartApplePaymentSummary") {
        data @type(name: "RestCartApplePaymentSummaryData") {
          currency: currency
          total: total @type(name: "RestApplePaymentSummaryTotal") {
            label
            amount
          }
          lineItems: line_items @type(name: "RestApplePaymentSummaryLineItem") {
            label
            amount
          }
          shippingMethods: shipping_methods @type(name: "RestApplePaymentSummaryShippingMethod") {
            label
            identifier
            amount
            detail
          }
          status: status
          shippingContact: shipping_contact @type(name: "RestApplePaymentSummaryShippingContact") {
            emailAddress
            givenName
            familyName
            phoneNumber
            addressLines
            locality
            country
            postalCode
            administrativeArea
            countryCode
          }
        }
      }
      siteCreditAvailable: site_credit_available @type(name: "RestCartPrice") {
        ...CartPriceFragment
      }
      shippingMethods: shipping_methods @type(name: "RestShippingMethod") {
        ...ShippingMethodFragment
      }
      paymentMethodId: payment_id
      paymentSource: payment_source
      paypalClientToken: paypal_client_token
      priceChangedMessage: price_changed_message
      selectedShippingMethodId: shipment_method
      rewardPoints: reward_points
      rewardSummary: reward_summary @type(name: "RestRewardSummary") {
        ...RewardSummaryFragment
      }
      totalEarnedRewardPoints: total_earned_reward_points
      version: rendered_by
      giftCard: gift_card @type(name: "RestGiftCard") {
        ...GiftCardFragment
      }
    }
  }
  ${LINE_ITEM_FRAGMENT}
  ${SHIPPING_METHOD_FRAGMENT}
  ${REWARD_SUMMARY_FRAGMENT}
  ${USER_PROMOTIONS}
  ${GIFT_CARD}
`;

export const GET_CART_QUERY: TypedDocumentNode<GetCartQuery, GetCartQueryVariables> = gql`
  query GetCartQuery($cartId: String!, $cartVersion: String!, $countryCode: String!) {
    userCart(cartId: $cartId, countryCode: $countryCode, cartVersion: $cartVersion)
      @rest(
        type: "RestUserCart"
        endpoint: "moda"
        path: "/v3.5/carts/{args.cartId}?target={args.cartVersion}&country_code={args.countryCode}"
      ) {
      data @type(name: "RestUserCartData") {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`;
