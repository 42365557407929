export const getNavHeight = () => {
  if (typeof document === 'undefined') return 0;

  const nav = document.querySelector('.SiteNav') as HTMLElement;
  const navHeight = nav ? nav.offsetHeight : 0;

  const underNav = document.querySelector('.StickUnderNav') as HTMLElement;
  const underNavHeight = underNav ? underNav.offsetHeight : 0;

  return navHeight + underNavHeight;
};
