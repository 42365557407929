import React, { useState, useCallback } from 'react';
import { SearchInput } from '@moda/om';
import classNames from 'classnames';
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation';
import { COUNTRIES, Country } from '@moda/portal-stanchions';
import { CountrySelectorItem } from './CountrySelectorItem';

import './CountrySelector.scss';

export interface CountrySelectorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  autoFocus?: boolean;
  onSelect(country: Country): void;
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  autoFocus,
  onSelect,
  className
}) => {
  const [countries, setCountries] = useState(COUNTRIES);

  const { index } = useKeyboardListNavigation({
    list: countries,
    onEnter: ({ element }) => onSelect(element)
  });

  const handleChangeValue = useCallback(
    (value: string) =>
      setCountries(
        COUNTRIES.filter(
          country =>
            country.name.toLowerCase().includes(value.toLowerCase()) ||
            country.alpha2Code.toLowerCase() === value.toLowerCase() ||
            country.alpha3Code.toLowerCase() === value.toLowerCase()
        ).sort((leftCountry, rightCountry) => {
          const leftStarts = leftCountry.name.toLowerCase().startsWith(value.toLowerCase());
          const rightStarts = rightCountry.name.toLowerCase().startsWith(value.toLowerCase());
          if (leftStarts && rightStarts) return 0;
          if (!leftStarts && !rightStarts) {
            if (leftCountry.alpha2Code.toLowerCase() === value.toLowerCase()) return -1;
            if (leftCountry.alpha3Code.toLowerCase() === value.toLowerCase()) return -1;
            if (rightCountry.alpha2Code.toLowerCase() === value.toLowerCase()) return 1;
            if (rightCountry.alpha3Code.toLowerCase() === value.toLowerCase()) return 1;
            return 0;
          }
          if (leftStarts && !rightStarts) return -1;
          if (!leftStarts && rightStarts) return 1;
          return 0;
        })
      ),
    []
  );

  return (
    <div className={classNames('CountrySelector', className)}>
      <SearchInput
        className="CountrySelector__input"
        placeholder="Search by country or region"
        onChange={handleChangeValue}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />

      <div className="CountrySelector__list">
        {countries.length > 0 ? (
          countries.map((country, countryIndex) => (
            <CountrySelectorItem
              key={country.id}
              country={country}
              onSelect={onSelect}
              active={index == countryIndex}
            />
          ))
        ) : (
          <div className="CountrySelector__empty">No suggestions found</div>
        )}
      </div>
    </div>
  );
};
