import React, { useMemo } from 'react';
import {
  DesktopNavigatorVerticalItemFragment,
  EditorialNavigatorUnitFragment,
  ListNavigatorUnitFragment
} from '../../../generated/types';
import { DesktopNavigationListColumn } from '../DesktopNavigationListColumn';
import { DesktopNavigationEditorialColumn } from '../DesktopNavigationEditorialColumn';

import './DesktopNavigationPulldown.scss';

type Props = {
  content: DesktopNavigatorVerticalItemFragment;
  onHide: () => void;
};

export const DesktopNavigationPulldown: React.FC<Props> = ({ content, onHide }) => {
  const [listNavigatorUnits, editorialNavigatorUnits] = useMemo(() => {
    const listUnits: ListNavigatorUnitFragment[] = [];

    const editorialUnits: EditorialNavigatorUnitFragment[] = [];

    content.units.forEach(unit => {
      if (unit.__typename === 'ListNavigatorUnit') {
        listUnits.push(unit);
        return;
      }

      if (unit.__typename === 'EditorialNavigatorUnit') {
        editorialUnits.push(unit);
        return;
      }
    });

    return [listUnits, editorialUnits] as const;
  }, [content.units]);

  return (
    <div className="DesktopNavigationPulldown">
      {listNavigatorUnits.map((unit, index) => (
        <DesktopNavigationListColumn
          key={index}
          className="DesktopNavigationPulldown__column"
          parentLabel={content.name}
          content={unit}
          onClick={onHide}
        />
      ))}

      {editorialNavigatorUnits.map((unit, index) => (
        <DesktopNavigationEditorialColumn
          key={index}
          className="DesktopNavigationPulldown__column"
          content={unit}
          onClick={onHide}
        />
      ))}
    </div>
  );
};
