import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ListNavigatorUnitFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { useDesktopNavigationContext } from '../DesktopNavigationProvider';

import './DesktopNavigationListColumn.scss';

interface Props {
  className?: string;
  parentLabel: string;
  content: ListNavigatorUnitFragment;
  onClick: () => void;
}

export const DesktopNavigationListColumn: React.FC<Props> = ({
  className,
  parentLabel,
  content,
  onClick
}) => {
  const { selectedVerticalData } = useDesktopNavigationContext();

  return (
    <div className={classNames('DesktopNavigationListColumn', className)}>
      <h4 className="DesktopNavigationListColumn__header">{content.header}</h4>

      {content.subcategories.map((subcategory, index) =>
        subcategory.href === '' ? (
          <h4
            key={subcategory.title + index}
            className="DesktopNavigationListColumn__header-secondary"
          >
            {subcategory.title}
          </h4>
        ) : (
          <Link
            className="DesktopNavigationListColumn__link"
            key={subcategory.href + index}
            to={subcategory.href}
            onClick={event => {
              onClick();
              tc.track('Navigation Item Clicked', {
                addToTrackingEvent: {
                  url: subcategory.href,
                  text: subcategory.title,
                  label: subcategory.title,
                  hierarchy: `${selectedVerticalData?.title}/${parentLabel}/${subcategory.title}`,
                  coordinateX: event?.pageX || 0,
                  coordinateY: event?.pageY || 0
                }
              });
            }}
          >
            {subcategory.title}
          </Link>
        )
      )}
    </div>
  );
};
