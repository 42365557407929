import React from 'react';
import classNames from 'classnames';
import { mapCursorToMax } from 'map-cursor-to-max';
import { useDuration } from './useDuration';

import './Crossfader.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  duration?: number;
  transition?: number;
  active?: boolean;
};

const DEFAULT_DURATION = 2000;
const DEFAULT_TRANSITION = 500;

export const Crossfader: React.FC<Props> = ({
  children,
  className,
  duration = DEFAULT_DURATION,
  transition = DEFAULT_TRANSITION,
  active = true,
  ...rest
}) => {
  const frames = React.Children.toArray(children);
  const { cursor, transitioning } = useDuration({ duration, transition, active });

  const [currentIndex, nextIndex] = [
    mapCursorToMax(cursor, frames.length),
    mapCursorToMax(cursor + 1, frames.length)
  ];

  return (
    <div
      className={classNames('Crossfader', className, {
        'Crossfader--transitioning': transitioning
      })}
      {...rest}
    >
      <div
        key={cursor + 1}
        className="Crossfader__next"
        style={{ transition: `opacity ${transition}ms ease` }}
      >
        {frames[nextIndex]}
      </div>
      <div
        key={cursor}
        className="Crossfader__current"
        style={{ transition: `opacity ${transition}ms ease` }}
      >
        {frames[currentIndex]}
      </div>
    </div>
  );
};
