import url from 'url';

const INTERNAL_HOST = 'modaoperandi.com';
const ALLOWED_SUBDOMAINS = ['help.modaoperandi.com'];

export const stripInternalHost = (href: string) => {
  const { hostname, path, hash } = url.parse(href);

  if (hostname && (!hostname.endsWith(INTERNAL_HOST) || ALLOWED_SUBDOMAINS.includes(hostname))) {
    return href;
  }

  if (hostname?.endsWith(INTERNAL_HOST) && hostname?.indexOf('img.modaoperandi') == -1) {
    return [path, hash].filter(Boolean).join('');
  }

  // Likely an external URL
  return href;
};
