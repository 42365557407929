import { gql, TypedDocumentNode } from '@apollo/client';
import { StatusCodes } from 'http-status-codes';
import { Gender } from '../../generated/types';

export const ATTENTIVE_ERROR_RESPONSE_CODES: StatusCodes[] = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNAUTHORIZED,
  StatusCodes.FORBIDDEN,
  StatusCodes.NOT_FOUND,
  StatusCodes.INTERNAL_SERVER_ERROR
];

export type RestEmailSubscribeTableAttentiveResponseTableContent = {
  errorStatusCode?: (typeof ATTENTIVE_ERROR_RESPONSE_CODES)[number];
  error?: string;
  message: string;
  subscriptionResponses?: {
    __typename: 'RestEmailSubscribeTableAttentiveResponseTableContentSubscriptionResponses';
    subscription: {
      __typename: 'RestEmailSubscribeTableAttentiveResponseTableContentSubscriptionResponsesSubscription';
      channel: 'TEXT' | 'MARKETING';
      type: 'MARKETING';
    };
    subscriptionCreationStatus: 'NEWLY_CREATED' | 'PREVIOUSLY_CREATED';
  }[];
  user?: {
    __typename: 'RestEmailSubscribeTableAttentiveResponseTableContentUser';
    email: string;
    phone: string;
  };
};

export type EmailSubscribeMutation = {
  emailSubscribe: {
    __typename: 'RestEmailSubscribePayload';
    table: {
      __typename: 'RestEmailSubscribeTable';
      isExistingSubscription: boolean;
      attentiveResponse: {
        __typename: 'RestEmailSubscribeTableAttentiveResponse';
        table: {
          __typename: 'RestEmailSubscribeTableAttentiveResponseTable';
          statusCode: string;
          content: string;
        };
      } | null;
    };
  };
};

export type Placement =
  | 'footer'
  | 'emailPumoModule'
  | 'welcomePopup'
  | 'productDetails'
  | 'smsPumoModule'
  | 'trunkshow';

export type AttentiveSignUpSource = 'mobile' | 'desktop' | 'beauty';

type CommonEmailSubscribeMutationVariables = {
  input: {
    user: {
      email: string;
      gender: Gender;
      phone: string;
    };
    label: Placement;
    category: string;
    sign_up_source_target: AttentiveSignUpSource;
  };
};

export type NewsletterEmailSubscribeMutationVariables = CommonEmailSubscribeMutationVariables;

export type DesignerEmailSubscribeMutationVariables = CommonEmailSubscribeMutationVariables & {
  input: {
    label: 'trunkshow';
    category: string;
    entity_id: number;
    entity_type: 'Trunkshow';
    trunkshow_preview: true;
  };
};

export type EmailSubscribeMutationVariables =
  | NewsletterEmailSubscribeMutationVariables
  | DesignerEmailSubscribeMutationVariables;

export const EMAIL_SUBSCRIBE_MUTATION: TypedDocumentNode<
  EmailSubscribeMutation,
  EmailSubscribeMutationVariables
> = gql`
  mutation EmailSubscribeMutation($input: EmailSubscribeMutationVariables!) {
    emailSubscribe(input: $input)
      @rest(
        type: "RestEmailSubscribePayload"
        path: "/v3.5/users/set_email_newsletter_preferences"
        method: "POST"
      ) {
      table @type(name: "RestEmailSubscribeTable") {
        isExistingSubscription: is_existing_subscription
        attentiveResponse: attentive_response
          @type(name: "RestEmailSubscribeTableAttentiveResponse") {
          table @type(name: "RestEmailSubscribeTableAttentiveResponseTable") {
            statusCode: code
            content
          }
        }
      }
    }
  }
`;
