import { ControlLink, ControlLinkProps } from '@moda/om';
import { triggerPopup } from '@moda/portal-stanchions';
import React from 'react';

type Props = React.HTMLProps<HTMLAnchorElement> & ControlLinkProps;

export const PopupLink: React.FC<Props> = ({ href, children, ...rest }) => (
  <ControlLink href={href} onClick={triggerPopup} {...rest}>
    {children}
  </ControlLink>
);
