import { useState } from 'react';
import { useIsCloudFrontViewerCountryUS } from '../../../hooks/useIsCloudfrontViewerCountryUS';

export const useEmailSignupPhoneNumber = () => {
  const isCloudFrontViewerCountryUS = useIsCloudFrontViewerCountryUS();

  const enabled = isCloudFrontViewerCountryUS;

  const [phone, setPhone] = useState<string>('');

  return { phone, setPhone, enabled };
};
