import { ControlLink, Stack } from '@moda/om';
import classNames from 'classnames';
import React from 'react';
import { useMobileNavigator } from '../../../hooks/useNavigator';

import './MobileNavV2Header.scss';

interface Props {
  onChange: (index: number) => void;
  selectedTabIndex: number;
}

export const MobileNavV2Header: React.FC<Props> = ({ onChange, selectedTabIndex }) => {
  const { data: verticals } = useMobileNavigator();

  if (!verticals) return null;

  return (
    <Stack
      className="MobileNavV2Header"
      direction="horizontal"
      justifyContent="space-between"
      space={0}
    >
      {verticals.map(({ title }, index) => (
        <div
          className={classNames('MobileNavV2Header__tab', {
            'MobileNavV2Header__tab--selected': selectedTabIndex == index,
            'MobileNavV2Header__tab--two-items': verticals.length === 2,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            'MobileNavV2Header__tab--three-items': verticals.length === 3,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            'MobileNavV2Header__tab--four-items': verticals.length === 4
          })}
          onClick={() => onChange(index)}
          onKeyDown={() => onChange(index)}
          key={`${name} -- ${index}`}
          role="button"
          tabIndex={selectedTabIndex === index ? -1 : 0}
        >
          <ControlLink underlined={selectedTabIndex === index}>{title.toUpperCase()}</ControlLink>
        </div>
      ))}
    </Stack>
  );
};
