import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { COUNTRIES, Country, DEFAULT_COUNTRY } from '@moda/portal-stanchions';
import { USER_QUERY } from '../useUser';
import { usePreferences } from '../../usePreferences';
import {
  UPDATE_USER_MUTATION,
  UpdateUserMutationUserAttributes
} from './useUpdateUserMutation.rest';

export const useUpdateUserMutation = () => {
  const client = useApolloClient();
  const { countryId } = usePreferences();

  const country: Country = countryId
    ? COUNTRIES.find(({ id }) => id === countryId) || DEFAULT_COUNTRY
    : DEFAULT_COUNTRY;

  const [mutate, { loading, error }] = useMutation(UPDATE_USER_MUTATION);
  const updateUser = useCallback(
    async (attributes: Partial<UpdateUserMutationUserAttributes>) => {
      const {
        data: { user }
      } = await client.query({ query: USER_QUERY });
      if (!user) return;
      const { firstName, lastName, countryCode, phone, id, userLoyaltyStatus } = user;
      if (id) {
        const result = await mutate({
          variables: {
            id,
            input: {
              data: {
                type: 'user',
                attributes: {
                  first_name: firstName || '',
                  last_name: lastName || '',
                  country_code: countryCode || country.alpha2Code,
                  phone: phone || '',
                  user_loyalty_status: userLoyaltyStatus || 0,
                  ...attributes
                }
              }
            }
          }
        });
        return result.data?.updateUser?.data;
      }
      return Promise.reject('User id not found.');
    },
    [client, country.alpha2Code, mutate]
  );
  return [updateUser, { loading, error }] as const;
};
