import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamToString, useQueryParams } from '@moda/portal-stanchions';
import { ApolloError } from '@apollo/client';
import { useCurrentRouteParams } from '../../../hooks/useCurrentRouteParams';
import { useDesktopNavigator } from '../../../hooks/useNavigator';
import { DesktopNavigatorVerticalFragment } from '../../../generated/types';

type VerticalId = string;

type TDesktopNavigationContext = {
  verticals?: DesktopNavigatorVerticalFragment[];
  selectedVerticalId?: VerticalId;
  selectedVerticalData?: DesktopNavigatorVerticalFragment;
  handleChangeSelectedVertical?: (verticalId: VerticalId) => void;
  loading: boolean;
  error?: ApolloError;
};

const DesktopNavigationContext = createContext<TDesktopNavigationContext>({
  verticals: undefined,
  selectedVerticalId: undefined,
  selectedVerticalData: undefined,
  handleChangeSelectedVertical: undefined,
  loading: false,
  error: undefined
});

interface Props {
  children: React.ReactNode;
}

export const DesktopNavigationProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const params = useCurrentRouteParams<{ vertical?: string }>();

  const { data: verticals = [], loading, error } = useDesktopNavigator();

  const getDefaultVerticalId = () => {
    const idFromPathname = verticals.find(vertical => vertical.href === pathname)?.id;
    if (idFromPathname) return idFromPathname;

    const idFromVerticalSlug = verticals.find(
      ({ title }) =>
        title === params?.vertical || title === queryParamToString(queryParams.vertical)
    )?.id;
    if (idFromVerticalSlug) return idFromVerticalSlug;

    const idFromEditorialData = verticals.find(vertical =>
      vertical.items.some(item =>
        item.units.some(unit =>
          unit.__typename === 'EditorialNavigatorUnit'
            ? unit.href === pathname
            : unit.subcategories.some(subcategory => subcategory.href === pathname)
        )
      )
    )?.id;
    if (idFromEditorialData) return idFromEditorialData;

    const idFromBaseVerticalHref = verticals.find(vertical => vertical.href === '/')?.id;
    if (idFromBaseVerticalHref) return idFromBaseVerticalHref;

    const idFromFirstVertical = verticals[0]?.id;
    if (idFromFirstVertical) return idFromFirstVertical;
  };

  const defaultVerticalId = getDefaultVerticalId();

  const [selectedVerticalId, setSelectedVerticalId] = useState(defaultVerticalId);

  useEffect(() => {
    if (!selectedVerticalId || !verticals.some(vertical => vertical.id === selectedVerticalId)) {
      setSelectedVerticalId(defaultVerticalId);
    }
  }, [verticals, selectedVerticalId, defaultVerticalId]);

  const handleChangeSelectedVertical = useCallback(
    (verticalId: VerticalId) => {
      if (!verticalId) return;

      if (!verticals.some(vertical => vertical.id === verticalId))
        throw new Error('Incorrect vertical id.');

      setSelectedVerticalId(verticalId);
    },
    [verticals]
  );

  const selectedVerticalData = verticals.find(vertical => vertical.id === selectedVerticalId);

  return (
    <DesktopNavigationContext.Provider
      value={{
        selectedVerticalId,
        selectedVerticalData,
        handleChangeSelectedVertical,
        loading,
        verticals,
        error
      }}
    >
      {children}
    </DesktopNavigationContext.Provider>
  );
};

export const useDesktopNavigationContext = () => useContext(DesktopNavigationContext);
