import React, { Component } from 'react';
import { LoadableComponent } from '@loadable/component';
import { logger } from '../../lib/logger';
import { reportError } from '../../lib/reportError';
import { isDevelopment } from '../../lib/isEnv';
import { ErrorFallback } from '../ErrorFallback';

type Props = {
  FallbackComponent?: React.FC<FallbackProps> | LoadableComponent<FallbackProps>;
  onError?: (error: Error, componentStack: string) => void;
  children?: React.ReactNode;
};

type FallbackProps = Partial<ErrorInfo> & {
  error?: Error;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error?: Error;
  info?: ErrorInfo;
};

export class ErrorBoundary extends Component<Props, State> {
  static defaultProps: Partial<Props>;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      info: undefined
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        onError.call(this, error, info ? info.componentStack : '');
      } catch (ignoredError) {
        logger.info('ERROR_BOUNDARY_IGNORED_ERROR', `ignoredError: ${ignoredError}`);
      }
    }

    reportError('ERROR_BOUNDARY', error);

    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;
    const FallbackComponent = isDevelopment() ? ErrorFallback : this.props.FallbackComponent;

    if (error && FallbackComponent) {
      return <FallbackComponent componentStack={info ? info.componentStack : ''} error={error} />;
    }

    return children || null;
  }
}
