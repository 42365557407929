import React from 'react';
import classNames from 'classnames';
import { AspectRatioBox, AspectRatioBoxProps } from '@moda/om';
import { Image, ImageProps } from '../Image';

import './ResponsiveImage.scss';

export type ResponsiveImageProps = ImageProps &
  AspectRatioBoxProps & { maxHeight: number; maxWidth: number };

export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  maxHeight,
  maxWidth,
  aspectHeight,
  aspectWidth,
  className,
  children,
  ...rest
}) => (
  <AspectRatioBox
    className={classNames('ResponsiveImage', className)}
    {...{ maxHeight, maxWidth, aspectHeight, aspectWidth }}
  >
    <Image
      className="ResponsiveImage__image"
      nativeImageSize={{ height: maxHeight, width: maxWidth }}
      width="360"
      height="640"
      {...rest}
    />
    {children}
  </AspectRatioBox>
);
