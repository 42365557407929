import React from 'react';
import { ControlLink, Text } from '@moda/om';
import { useCountry } from '../../../CountrySelector';

import './SiteFooterMobileBasement.scss';

export const SiteFooterMobileBasement: React.FC = () => {
  const { country } = useCountry();
  const showPrivacyChoicesLink = country.alpha2Code === 'US';

  return (
    <div className="SiteFooterMobileBasement">
      <ControlLink
        className="SiteFooterMobileBasement__item"
        href="/privacy"
        target="_blank"
        underlined={false}
      >
        Privacy Policy
      </ControlLink>
      <ControlLink
        className="SiteFooterMobileBasement__item"
        href="/terms"
        target="_blank"
        underlined={false}
      >
        Terms &amp; Conditions
      </ControlLink>

      {showPrivacyChoicesLink && (
        <ControlLink
          className="SiteFooterMobileBasement__item"
          href="#"
          underlined={false}
          onClick={() => window.evidon.notice.showOptions()}
        >
          Your Privacy Choices
        </ControlLink>
      )}

      <ControlLink
        className="SiteFooterMobileBasement__item"
        target="_blank"
        underlined={false}
        href="/legal"
      >
        Legal
      </ControlLink>
      <div>
        <Text className="SiteFooterMobileBasement__copyright" color="cement">
          © {new Date().getFullYear()} Moda Operandi, Inc. All Rights Reserved.
        </Text>
        <img
          className="SiteFooterMobileBasement__shapes"
          src="/dist/public/moda_logo.svg"
          alt="Moda shapes"
        />
      </div>
    </div>
  );
};
