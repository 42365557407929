import { getNetworkErrors, isGenericError } from './error';
import { logger } from './logger';

const ERRORS_TO_IGNORE = ["Please confirm your credit card's security code to place your order."];

export const reportError = <T extends string>(key: T extends '' ? never : T, error: unknown) => {
  if (!isGenericError(error)) return logger.error(key, 'Unknown error.', { error });

  const networkErrorMessage = getNetworkErrors(error)?.[0]?.detail;
  const message =
    networkErrorMessage || error.displayMessage || 'An error occurred. Please retry later.';

  if (!ERRORS_TO_IGNORE.includes(message)) {
    logger.error(key, error.message ?? message, error);
  }
};
