import { GraphQLError, print } from 'graphql';
import { StatusCodes } from 'http-status-codes';
import { ApolloLink, createHttpLink } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RestLink } from 'apollo-link-rest';
import fetch from 'isomorphic-fetch';
import sha from 'sha.js';
import { deepPropertyCount } from '@moda/portal-stanchions';
import { CONFIG } from '../../../config';
import { localTypeDefs } from '../localTypeDefs';
import { setRestHeaders } from '../setRestHeaders';
import { USER_API_URL } from '../../../constants';

type NetworkError = {
  response: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  statusCode: number;
};

type GraphQLErrorWithCode = GraphQLError & { code: string };

export const isUnauthorizedError = ({ networkError, graphQLErrors }: Partial<ErrorResponse>) =>
  (Array.isArray(graphQLErrors) &&
    graphQLErrors.some(error => (error as GraphQLErrorWithCode).code === 'unauthorized')) ||
  (networkError as NetworkError)?.statusCode === StatusCodes.UNAUTHORIZED;

const persistedQueriesCount = Number(CONFIG.PERSISTED_QUERIES_MAX_VARIABLES_COUNT);
const defaultPersistedQueriesCount = 100;

const PERSISTED_QUERIES_MAX_VARIABLES_COUNT = isNaN(persistedQueriesCount)
  ? defaultPersistedQueriesCount
  : persistedQueriesCount;

export const createCommonConfiguration = ({
  additionalRestHeaders = {}
}: {
  additionalRestHeaders?: { [key: string]: string };
}) => ({
  link: ApolloLink.from([
    setRestHeaders('recommendations', { 'x-api-key': 'abracadabra' }),
    new RestLink({
      customFetch: fetch,
      endpoints: {
        moda: CONFIG.MODA_API_URL,
        preferences: CONFIG.PREFERENCES_API_URL,
        recommendations: CONFIG.RECOMMENDATIONS_API_URL,
        user: USER_API_URL,
        self: CONFIG.DISCOVERY_API_URL // TODO: try to figure out if there's a way to use a relative path
      },
      uri: CONFIG.MODA_API_URL,
      headers: {
        ...additionalRestHeaders,
        'Content-Type': 'application/vnd.api+json'
      },
      fieldNameNormalizer: name => name.replace(/[^a-zA-Z0-9_]/gi, '')
    }),
    new ApolloLink((operation, forward) => forward(operation)).split(
      operation => deepPropertyCount(operation.variables) <= PERSISTED_QUERIES_MAX_VARIABLES_COUNT,
      createPersistedQueryLink({
        generateHash: node => sha('sha256').update(print(node)).digest('hex'),
        useGETForHashedQueries: true
      }).concat(
        createHttpLink({
          uri: CONFIG.SEARCH_API_GRAPHQL_ENDPOINT,
          fetch
        })
      ),
      createHttpLink({
        uri: CONFIG.SEARCH_API_GRAPHQL_ENDPOINT,
        fetch
      })
    )
  ]),
  typeDefs: localTypeDefs,
  resolvers: {}
});
