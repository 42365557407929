import { gql, TypedDocumentNode } from '@apollo/client';
import { useCallback } from 'react';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { useLocalQuery } from '../useLocalQuery';
import { PaymentErrorQuery } from '../../generated/types';

type PaymentError = NonNullable<PaymentErrorQuery['paymentError']>;

export const PAYMENT_ERROR_QUERY: TypedDocumentNode<PaymentErrorQuery, void> = gql`
  query PaymentErrorQuery {
    paymentError @client {
      error
    }
  }
`;

export const PAYMENT_ERROR_DEFAULTS: PaymentError = {
  __typename: 'PaymentError',
  error: ''
};

export const usePaymentError = () => {
  const { data, error, client } = useLocalQuery(PAYMENT_ERROR_QUERY);

  const setPaymentError = useCallback(
    (error: string | null) =>
      client.writeQuery({
        query: PAYMENT_ERROR_QUERY,
        data: { paymentError: { __typename: 'PaymentError', error } }
      }),
    [client]
  );

  if (error) throwQueryError(error);

  return {
    paymentError: data?.paymentError?.error ?? null,
    setPaymentError
  };
};
