import { UseUserQuery } from '../generated/types';
import { SetUserProps } from '../hooks/useUser';

type UseUserQueryUser = NonNullable<UseUserQuery['user']>;

export const NULL_USER: UseUserQueryUser = {
  __typename: 'User',
  affiliate: null,
  accessToken: null,
  clientTier: null,
  countryCode: '',
  email: null,
  firstName: null,
  hasPassword: null,
  id: null,
  isAmazonConnected: null,
  isLoggedIn: false,
  isMember: null,
  lastName: null,
  phone: null,
  modaPrivate: null,
  statusLevel: null,
  userLoyaltyStatus: null
};

export const serializeUser = ({ ...user }: UseUserQueryUser | SetUserProps) => JSON.stringify(user);

export const parseUser = (cookie: string): UseUserQueryUser => {
  let user;
  try {
    user = JSON.parse(cookie);
  } catch {
    user = {};
  }
  return { ...NULL_USER, ...user };
};
