import React from 'react';
import loadable from '@loadable/component';
import { Loading } from '../components/Loading';
import { UseUserQuery } from '../generated/types';
import { PageType, UserRewardStatus, UserStatusLevel } from '../constants';
import { renderWithStatusCode } from './renderWithStatusCode';
import { PageRoute } from './PageRoutes';

type UseUserQueryUser = NonNullable<UseUserQuery['user']>;

const AccountPage = loadable(() => import('../pages/AccountPage'), { fallback: <Loading /> });

const AccountLoading = () => (
  <AccountPage>
    <Loading />
  </AccountPage>
);

const AccountSettings = loadable(() => import('../components/AccountSettings'), {
  fallback: <AccountLoading />
});
const AccountDesignerPreferences = loadable(
  () => import('../components/AccountDesignerPreferences'),
  {
    fallback: <AccountLoading />
  }
);
const AccountPaymentMethods = loadable(
  () => import('../components/PaymentMethod/AccountPaymentMethods'),
  {
    fallback: <AccountLoading />
  }
);
const AccountOrderHistory = loadable(() => import('../components/AccountOrderHistory'), {
  fallback: <AccountLoading />
});
const AccountOpenItems = loadable(() => import('../components/AccountOpenItems'), {
  fallback: <AccountLoading />
});
const AccountShippingAddresses = loadable(() => import('../components/AccountShippingAddresses'), {
  fallback: <AccountLoading />
});
const AccountShipments = loadable(() => import('../components/AccountShipments'), {
  fallback: <AccountLoading />
});
const AccountReturns = loadable(() => import('../components/AccountReturns'), {
  fallback: <AccountLoading />
});
const SiteCredits = loadable(() => import('../components/SiteCredits'), {
  fallback: <AccountLoading />
});
const AccountRewards = loadable(() => import('../components/AccountRewards'), {
  fallback: <AccountLoading />
});

export type AccountLabel =
  | 'Settings'
  | 'My Designer Preferences'
  | 'Shipping Addresses'
  | 'Payment Methods'
  | 'Order History'
  | 'Open Items'
  | 'Shipments'
  | 'Returns'
  | 'Site Credits'
  | `M'O Rewards`;

export type AccountRoute = PageRoute & {
  label: AccountLabel;
  hideFromNav?: (user: UseUserQueryUser) => boolean;
};

export const ACCOUNT_ROUTES: AccountRoute[] = [
  {
    key: 'AccountSettings',
    label: 'Settings',
    path: '/account/settings',
    component: renderWithStatusCode(AccountSettings),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountDesignerPreferences',
    label: 'My Designer Preferences',
    path: '/account/preferences',
    component: renderWithStatusCode(AccountDesignerPreferences),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountShippingAddresses',
    label: 'Shipping Addresses',
    path: '/account/addresses',
    component: renderWithStatusCode(AccountShippingAddresses),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountPaymentMethods',
    label: 'Payment Methods',
    path: '/account/payment-methods',
    component: renderWithStatusCode(AccountPaymentMethods),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountOrderHistory',
    label: 'Order History',
    path: '/account/orders',
    component: renderWithStatusCode(AccountOrderHistory),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountOpenItems',
    label: 'Open Items',
    path: '/account/open-items',
    component: renderWithStatusCode(AccountOpenItems),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountShipments',
    label: 'Shipments',
    path: '/account/shipments',
    component: renderWithStatusCode(AccountShipments),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountReturns',
    label: 'Returns',
    path: '/account/returns',
    component: renderWithStatusCode(AccountReturns),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountSiteCredits',
    label: 'Site Credits',
    path: '/account/credits',
    component: renderWithStatusCode(SiteCredits),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'AccountRewards',
    label: `M'O Rewards`,
    path: '/account/rewards',
    component: renderWithStatusCode(AccountRewards),
    exact: true,
    hideFromNav: user =>
      user.statusLevel === UserStatusLevel.Employee ||
      user.userLoyaltyStatus === UserRewardStatus.NoAccess,
    pagetype: PageType.Account
  }
];
