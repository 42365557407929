import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';
import { useCookies } from './useCookies';

interface WithPS {
  promo: string;
}

type Params = WithPS & ParsedQs;

const PROMO_CODE_COOKIE_NAME = 'promoCode';

export const usePreservePromoCodeInCookie = () => {
  const location = useLocation();
  const { setCookie } = useCookies();
  const { promo = '' } = qs.parse(location.search.slice(1)) as Params;

  useEffect(() => {
    if (promo) {
      setCookie(PROMO_CODE_COOKIE_NAME, promo.toUpperCase());
    }
  }, [promo, setCookie]);
};
