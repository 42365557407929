import uuid from 'uuid-random';
import { ApolloClient, gql, TypedDocumentNode } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { NotificationsQuery } from '../generated/types';
import { throwQueryError } from '../lib/apollo/throwQueryError';
import { useLocalQuery } from './useLocalQuery';

type Notification = NotificationsQuery['notifications'][number];

export const NOTIFICATIONS_QUERY: TypedDocumentNode<NotificationsQuery, void> = gql`
  query NotificationsQuery {
    notifications @client {
      id
      type
      message
    }
  }
`;

const updateCache = <T>(
  client: ApolloClient<T>,
  update: (notifications: Notification[]) => Notification[]
) =>
  client.writeQuery({
    query: NOTIFICATIONS_QUERY,
    data: {
      notifications: update(client.readQuery({ query: NOTIFICATIONS_QUERY })?.notifications || [])
    }
  });

export const useNotifications = () => {
  const { data, error, client } = useLocalQuery(NOTIFICATIONS_QUERY);
  const notifications = useMemo(() => data?.notifications || [], [data]);

  const addNotification = useCallback(
    (notification: Omit<Notification, '__typename' | 'id'>) =>
      updateCache(client, notifications => [
        ...notifications,
        { __typename: 'Notification', id: uuid(), ...notification }
      ]),
    [client]
  );

  const removeNotification = useCallback(
    (id: string) =>
      updateCache(client, notifications =>
        notifications.filter(notificaton => notificaton.id !== id)
      ),
    [client]
  );

  if (error) throwQueryError(error);

  return {
    notifications,
    addNotification,
    removeNotification
  };
};
