import { useLazyQuery } from '@apollo/client';
import React, { createContext, useMemo, useCallback } from 'react';
import { NULL_USER } from '../../lib/user';
import { useUser } from '../../hooks/useUser';
import {
  GetFavoritesQuery,
  GetFavoritesQueryVariables,
  GET_FAVORITES_QUERY
} from './favorites.rest';

// creating a React context for favorites to fix performance problems caused by using a useQuery in every VariantCell
export const FavoritesContext = createContext({
  user: NULL_USER,
  favorites: [] as string[],
  getFavorites: () => {
    return;
  },
  loaded: false
});

export const FavoritesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { user } = useUser();

  // remove { ssr: false } from this once https://github.com/apollographql/apollo-client/issues/9108 is resolved
  const [fetchFavorites, { data, called }] = useLazyQuery<
    GetFavoritesQuery,
    GetFavoritesQueryVariables
  >(GET_FAVORITES_QUERY, {
    ssr: false,
    variables: {
      userId: user.id
    },
    context: {
      headers: {
        'x-user-preferences-api-token': `Bearer ${user.accessToken}`
      }
    }
  });

  const favorites = useMemo(
    () => data?.userFavorites?.data.attributes.variants.map(({ id }) => id) || [],
    [data]
  );

  const getFavorites = useCallback(
    () => user.isLoggedIn && !called && fetchFavorites(),
    [user.isLoggedIn, called, fetchFavorites]
  );

  return (
    <FavoritesContext.Provider value={{ user, favorites, getFavorites, loaded: data != null }}>
      {children}
    </FavoritesContext.Provider>
  );
};
