import React, { useCallback, useState } from 'react';
import { Popover } from '@moda/om';
import classNames from 'classnames';
import { Country } from '@moda/portal-stanchions';
import { Z_INDEXES } from '../../generated/zIndexes';
import { CountrySelector, useCountry, useSetCountry } from '../CountrySelector';

import './SitePromoBannerCountrySelector.scss';

type Props = {
  className?: string;
};

export const SitePromoBannerCountrySelector: React.FC<Props> = ({ className }) => {
  const { country } = useCountry();
  const { setCountry } = useSetCountry();

  const [open, setOpen] = useState(false);

  const handleSelect = useCallback(
    (country: Country) => {
      setCountry(country);
      setOpen(false);
    },
    [setCountry]
  );

  const handleMouseEnter = useCallback(() => setOpen(true), []);
  const handleMouseLeave = useCallback(() => setOpen(false), []);

  return (
    <Popover
      className={classNames('SitePromoBannerCountrySelector', className)}
      zIndex={Z_INDEXES['site-promo-banner-popover']}
      open={open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      content={
        <CountrySelector
          className="SitePromoBannerCountrySelector__selector"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onSelect={handleSelect}
        />
      }
    >
      <>
        Currency: {country.alpha3Code} ({country.currencyCode})
      </>
    </Popover>
  );
};
