import scrollIntoView from 'scroll-into-view-if-needed';
import { getNavHeight } from './getNavHeight';

export const scrollIntoViewIfNeeded = (
  element: Element | null,
  {
    scrollMode = 'if-needed',
    behavior = 'smooth'
  }: { scrollMode?: 'always' | 'if-needed'; behavior?: 'auto' | 'smooth' } = {
    scrollMode: 'if-needed',
    behavior: 'smooth'
  }
) => {
  if (!element) return;

  scrollIntoView(element, {
    scrollMode,
    behavior: actions =>
      actions.forEach(({ el, top }) => {
        if (el.nodeName === 'HTML') top = top - getNavHeight();
        el.scrollTo({ top, behavior });
      }),
    block: 'start'
  });
};
