import React from 'react';
import { Stack, Text } from '@moda/om';

import './DesktopNavigationEditorialColumnText.scss';

export const DesktopNavigationEditorialColumnText: React.FC<{ text: string | null }> = ({
  text
}) => (
  <Stack alignItems="center" className="DesktopNavigationEditorialColumnText" space={0}>
    <Text family="serif" treatment="h6">
      {text}
    </Text>
  </Stack>
);
