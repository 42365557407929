import React, { createContext, useState, useEffect } from 'react';
import { CONFIG } from '../../config';

export const ApplePayContext = createContext({ isApplePaySupported: false });

const checkApplePaySupport = () =>
  typeof ApplePaySession !== 'undefined'
    ? ApplePaySession.canMakePaymentsWithActiveCard(CONFIG.APPLE_MERCHANT_IDENTIFIER)
    : Promise.resolve(false);

export const ApplePayProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isApplePaySupported, setIsApplePaySupported] = useState(false);

  useEffect(() => {
    checkApplePaySupport().then(setIsApplePaySupported);
  }, []);

  return (
    <ApplePayContext.Provider value={{ isApplePaySupported: isApplePaySupported }}>
      {children}
    </ApplePayContext.Provider>
  );
};
