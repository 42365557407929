import { isbot } from 'isbot';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useVisitedUrls, ONE_DAY_SECONDS, isClientSide } from '@moda/portal-stanchions';
import { useEmailSubscribe } from '../../../hooks/useEmailSubscribe';
import { usePageType } from '../../../hooks/usePageType';
import { usePreferences } from '../../../hooks/usePreferences';
import { useUser } from '../../../hooks/useUser';
import { tc } from '../../../lib/trackingContext';
import { hrefFor } from '../../../routers';
import { useCookies } from '../../../hooks/useCookies';
import { useEmailSignupPagesToViewBeforeModalShows } from './useEmailSignupPagesToViewBeforeModalShows';

enum Step {
  Email = 'email',
  Sms = 'sms',
  Confirmation = 'confirmation'
}

const THREE_SECONDS = 3000;
const SIXTY = 60;
const SIXTY_DAY_SECONDS = SIXTY * ONE_DAY_SECONDS;

type TrackSignupAction = 'Viewed' | 'Dismissed' | 'Completed';

enum TrackSignupActionEmailOnly {
  Viewed = 'Welcome Email Modal Viewed',
  Dismissed = 'Welcome Email Modal Dismissed',
  Completed = 'Welcome Email Modal Sign Up Finished'
}

enum TrackSignupActionEmailAndSms {
  Viewed = 'Welcome Email and SMS Modal Viewed',
  Dismissed = 'Welcome Email and SMS Modal Dismissed',
  Completed = 'Welcome Email and SMS Modal Sign Up Finished'
}

export const useEmailSignup = () => {
  const [email, setEmail] = useState<string>('');
  const { showEmailSignup, setPreference } = usePreferences();
  const [isModalVisible, setIsModalVisible] = useState<boolean>();

  const visitedUrls = useVisitedUrls();
  const {
    user: { id: userId, isLoggedIn }
  } = useUser();
  const { pathname } = useLocation();
  const [step, setStep] = useState(Step.Email);

  const isEmailStep = step === Step.Email;

  const isSmsStep = step === Step.Sms;

  const isConfirmationStep = step === Step.Confirmation;

  const {
    subscribe,
    isSubscribed,
    isExistingSubscription,
    error,
    mailValidationError,
    phone,
    setPhone,
    isPhoneNumberValid,
    phoneNumberEnabled,
    phoneNumberValidationError
  } = useEmailSubscribe();

  const { setCookie } = useCookies();

  const pagetype = usePageType();

  const pagesToViewBeforeModalShows = useEmailSignupPagesToViewBeforeModalShows();

  const trackAction = useCallback(
    (action: TrackSignupAction) => {
      const actionText = isSmsStep
        ? TrackSignupActionEmailAndSms[action]
        : TrackSignupActionEmailOnly[action];

      tc.track(actionText, {
        addToTrackingEvent: {
          pagetype,
          label: userId,
          step: step
        }
      });
    },
    [isSmsStep, pagetype, step, userId]
  );

  const trackModalDismissed = useCallback(() => trackAction('Dismissed'), [trackAction]);

  useEffect(() => {
    if (isLoggedIn && showEmailSignup) setPreference('showEmailSignup', false);
  }, [showEmailSignup, setPreference, isLoggedIn]);

  const showModal =
    isClientSide() &&
    visitedUrls.length >= pagesToViewBeforeModalShows &&
    showEmailSignup &&
    !isLoggedIn &&
    !isbot(navigator.userAgent) &&
    pathname !== hrefFor.CheckoutPage() &&
    pathname !== hrefFor.ShoppingBagPage();

  useEffect(() => {
    if (showModal) {
      trackAction('Viewed');
      setIsModalVisible(true);
    }
  }, [pagetype, showModal, trackAction]);

  const handleClose = useCallback(() => {
    setIsModalVisible(false);
    setPreference('showEmailSignup', false);
    trackModalDismissed();
  }, [setPreference, trackModalDismissed]);

  const handleSmsSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (!isPhoneNumberValid(phone)) return;

      subscribe({ email, phone, label: 'welcomePopup' });

      trackAction('Completed');

      setStep(Step.Confirmation);

      setCookie('attSignedUpWith', email, SIXTY_DAY_SECONDS);

      setTimeout(() => {
        handleClose();
      }, THREE_SECONDS);
    },
    [isPhoneNumberValid, phone, subscribe, email, trackAction, setStep, setCookie, handleClose]
  );

  const handleEmailSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      trackAction('Completed');

      setCookie('attSignedUpWith', email, SIXTY_DAY_SECONDS);

      subscribe({ email, label: 'welcomePopup' });

      if (phoneNumberEnabled) {
        setStep(Step.Sms);
        return;
      }

      setStep(Step.Confirmation);

      setTimeout(() => {
        handleClose();
      }, THREE_SECONDS);
    },
    [trackAction, setCookie, email, subscribe, phoneNumberEnabled, setStep, handleClose]
  );

  return {
    handleSmsSubmit,
    handleEmailSubmit,
    error,
    phoneNumberEnabled,
    setPhone,
    isSubscribed,
    isExistingSubscription,
    setEmail,
    handleClose,
    showModal,
    email,
    mailValidationError,
    setPreference,
    phone,
    phoneNumberValidationError,
    step,
    isEmailStep,
    isSmsStep,
    isConfirmationStep,
    isModalVisible
  };
};
