import { useCallback } from 'react';
import { COUNTRIES, Country, DEFAULT_COUNTRY } from '@moda/portal-stanchions';
import { usePreferences } from '../../hooks/usePreferences';
import { useUpdateUserMutation } from '../../hooks/useUser/useUpdateUserMutation';
import { useUser } from '../../hooks/useUser';
import { useSessionQueryParams } from '../../hooks/useSessionQueryParams';
import { refetchFeatureFlags } from '../../hooks/useUser/refetchFeatureFlags';

export const useCountry = () => {
  const { params: sessionQueryParams } = useSessionQueryParams();
  const { countryId } = usePreferences();

  const country: Country = sessionQueryParams.country
    ? COUNTRIES.find(
        ({ alpha2Code, alpha3Code }) =>
          sessionQueryParams.country === alpha2Code || sessionQueryParams.country === alpha3Code
      ) || DEFAULT_COUNTRY
    : countryId
      ? COUNTRIES.find(({ id }) => id === countryId) || DEFAULT_COUNTRY
      : DEFAULT_COUNTRY;

  return { country };
};

export const useSetCountry = () => {
  const { setPreference } = usePreferences();
  const { user, setUserData } = useUser();
  const [updateUser] = useUpdateUserMutation();
  const { removeParameter } = useSessionQueryParams();

  const setCountry = useCallback(
    ({ id, alpha2Code }: Country) => {
      if (!user.isLoggedIn) {
        setPreference('countryId', id);
        removeParameter('country');
        refetchFeatureFlags().then(() => window.dispatchEvent(new CustomEvent('resetcache')));
        return;
      }

      updateUser({
        country_code: alpha2Code
      }).then(response => {
        if (response) {
          const { attributes } = response;
          setUserData({ ...user, ...attributes });
          setPreference('countryId', id);
          removeParameter('country');
          refetchFeatureFlags().then(() => window.dispatchEvent(new CustomEvent('resetcache')));
        }
      });
    },
    [setUserData, setPreference, updateUser, user, removeParameter]
  );

  return { setCountry };
};
