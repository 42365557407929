import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import { logger } from '../../lib/logger';
import { LazyLoad, LazyLoadProps, LazyLoadContext } from '../LazyLoad';
import { useImageSources, ImageSourceOptions } from './useImageSources';

import './Image.scss';

export type ImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> &
  ImageSourceOptions &
  LazyLoadProps & {
    onError?(): void;
    onLoad?(): void;
  };

enum Mode {
  Resting,
  Error
}

export const Image = React.forwardRef(
  (
    {
      src: rawSrc,
      alt,
      onError,
      onLoad,
      className,
      lazyload,
      width,
      height,
      srcMode = 'static',
      nativeImageSize,
      quality,
      ...rest
    }: ImageProps,
    forwardedRef: React.Ref<HTMLImageElement>
  ) => {
    const { enable: enableLazyLoad } = useContext(LazyLoadContext);
    const [mode, setMode] = useState(Mode.Resting);

    const ref = React.useRef<HTMLImageElement>(null);

    const { src, srcSet } = useImageSources({
      src: rawSrc,
      quality,
      nativeImageSize,
      srcMode
    });

    const isLazyLoaded = enableLazyLoad && lazyload !== false;

    const handleError = useCallback(() => {
      setMode(Mode.Error);
      onError?.();
      logger.warn('IMAGE', `Failed to load image ${src}`);
    }, [onError, src]);

    useEffect(() => {
      if (ref.current?.complete) {
        onLoad?.();
      }
    }, [onLoad, ref]);

    useEffect(() => {
      setMode(Mode.Resting);
    }, [rawSrc]);

    return (
      <LazyLoad lazyload={lazyload}>
        {!isLazyLoaded && (
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={src}
              {...(srcSet ? { imageSrcSet: srcSet } : {})}
            />
          </Helmet>
        )}
        <img
          alt={alt}
          className={classNames(`Image Image--${Mode[mode].toLowerCase()}`, className)}
          height={height}
          onError={handleError}
          onLoad={onLoad}
          src={src}
          srcSet={srcSet}
          ref={composeRefs(ref, forwardedRef)}
          width={width}
          {...rest}
        />
      </LazyLoad>
    );
  }
);

Image.displayName = 'Image';
