import { DataProxy } from '@apollo/client';
import { lensPath, view, set, uniq } from 'ramda';
import possibleTypes from '../../generated/possibleTypes.json';
import {
  UseUserQuery,
  UseCookiesQuery,
  ErrorModalQuery,
  PaymentErrorQuery,
  NotificationsQuery
} from '../../generated/types';
import { USER_QUERY, getUserFromCookies } from '../../hooks/useUser';
import { USE_COOKIES_QUERY, getCookies } from '../../hooks/useCookies';
import { ERROR_MODAL_QUERY, ERROR_MODAL_DEFAULTS } from '../../hooks/useErrorModal';
import {
  PAYMENT_ERROR_QUERY,
  PAYMENT_ERROR_DEFAULTS
} from '../../hooks/useCheckout/usePaymentError';
import { NOTIFICATIONS_QUERY } from '../../hooks/useNotifications';
import { OptimizedInMemoryCache } from './OptimizedInMemoryCache';

type User = NonNullable<UseUserQuery['user']>;
type Cookies = NonNullable<UseCookiesQuery['cookies']>;
type ErrorModal = NonNullable<ErrorModalQuery['errorModal']>;
type PaymentError = NonNullable<PaymentErrorQuery['paymentError']>;
type Notification = NotificationsQuery['notifications'][number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeArrayFields = (existing: any, incoming: any) => {
  if (existing == null) return incoming;
  return Object.keys(existing).reduce(
    (result, key) => ({
      ...result,
      [key]:
        Array.isArray(existing[key]) && Array.isArray(incoming[key])
          ? uniq([...existing[key], ...incoming[key]])
          : incoming[key]
    }),
    {}
  );
};

export const createCache = () =>
  new OptimizedInMemoryCache({
    possibleTypes,
    typePolicies: {
      RestLineItemSKU: {
        keyFields: ['id', 'selected']
      },
      Query: {
        fields: {
          getOpenOrderList: {
            keyArgs: ['countryCode'],
            merge: mergeArrayFields
          },
          getOrderList: {
            keyArgs: ['countryCode'],
            merge: mergeArrayFields
          },
          getReturns: {
            keyArgs: ['countryCode'],
            merge: mergeArrayFields
          },
          getShipmentList: {
            keyArgs: ['countryCode'],
            merge: mergeArrayFields
          },
          getRewardsSummary: {
            keyArgs: ['country_code', 'selected_currency'],
            merge: (existing, incoming) => {
              if (!existing) return incoming;

              const rewardsArrayPath = lensPath([
                'data@type({"name":"RestRewardsSummary"})',
                0,
                'attributes@type({"name":"RestRewardsSummaryAttributes"})',
                'get_rewards_summary@type({"name":"RestGetRewardsSummary"})',
                'summary@type({"name":"GetRewardsSummarySummary"})'
              ]);

              return set(
                rewardsArrayPath,
                uniq([
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ...view<any, any>(rewardsArrayPath, existing),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ...view<any, any>(rewardsArrayPath, incoming)
                ]),
                incoming
              );

              return incoming;
            }
          }
        }
      }
    }
  });

export type ClientOnlyCacheData = {
  user?: User | null;
  cookies?: Cookies | null;
  errorModal?: ErrorModal | null;
  paymentError?: PaymentError | null;
  notifications?: Notification[];
};

export const initializeCache = (
  cache: DataProxy,
  {
    user = getUserFromCookies(),
    cookies = getCookies(),
    errorModal = ERROR_MODAL_DEFAULTS,
    paymentError = PAYMENT_ERROR_DEFAULTS,
    notifications = []
  }: ClientOnlyCacheData
) => {
  cache.writeQuery({ query: USER_QUERY, data: { user } });
  cache.writeQuery({ query: USE_COOKIES_QUERY, data: { cookies } });
  cache.writeQuery({ query: ERROR_MODAL_QUERY, data: { errorModal } });
  cache.writeQuery({ query: PAYMENT_ERROR_QUERY, data: { paymentError } });
  cache.writeQuery({ query: NOTIFICATIONS_QUERY, data: { notifications } });
};
