import { isClientSide } from '@moda/portal-stanchions';
import { logger } from '../logger';

const clientSide = isClientSide();

const waitForGa = function () {
  return new Promise<UniversalAnalytics.ga | undefined>((resolve, reject) => {
    const TIME_BETWEEN_ATTEMPTS_MS = 4;
    const TIMEOUT = 10000;
    let outOfTime = false;

    if (!clientSide) return undefined;

    window.setTimeout(() => {
      outOfTime = true;
    }, TIMEOUT);

    const lookForGa = () => {
      if (window.ga !== undefined) {
        resolve(ga);
      } else {
        if (outOfTime)
          reject(
            new Error(
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              `googleAnalyticsTools: Ran out of time (${TIMEOUT / 1000}s) looking for ga on window`
            )
          );
        window.setTimeout(lookForGa, TIME_BETWEEN_ATTEMPTS_MS);
      }
    };

    lookForGa();
  });
};

const taskTypeList = [
  'previewTask',
  'checkProtocolTask',
  'validationTask',
  'checkStorageTask',
  'historyImportTask',
  'samplerTask',
  'buildHitTask',
  'sendHitTask',
  'timingTask',
  'displayFeaturesTask'
] as const;

type GoogleAnalyticsInterceptor = (model: UniversalAnalytics.Model) => void;
type GoogleAnalyticsASAPInterceptor = (ga: UniversalAnalytics.ga) => void;
type TaskType = (typeof taskTypeList)[number];

type TaskStore = Partial<Record<TaskType, GoogleAnalyticsInterceptor[]>>;
type OriginalTaskStore = Record<string, TaskStore>;

declare global {
  interface Window {
    __GAP__: {
      originalTaskStore?: OriginalTaskStore;
      taskStore?: TaskStore;
    };
  }
}

const retrieveTasks = function (taskName: TaskType) {
  if (!clientSide) return [];
  let tasks = window.__GAP__?.taskStore?.[taskName];
  if (tasks === undefined) {
    window.__GAP__ = window.__GAP__ || {};
    let store = window.__GAP__?.taskStore;
    if (store === undefined) store = window.__GAP__.taskStore = {};
    tasks = store[taskName];
    if (tasks === undefined) tasks = store[taskName] = [];
  }
  return tasks;
};

const getTask = function (trackerName: string, taskName: TaskType) {
  return window.ga.getByName(trackerName).get(taskName) as GoogleAnalyticsInterceptor;
};

const generateCombinedTask = function (trackerName: string, taskName: TaskType) {
  const originalTask = getTask(trackerName, taskName);
  return function (model: UniversalAnalytics.Model) {
    const alsoTasks = retrieveTasks(taskName);
    alsoTasks.forEach(function (alsoTask) {
      try {
        alsoTask(model);
      } catch (error) {
        logger.error(
          'GA_GENERATE_COMBINED_TASK',
          `Error in pipeline for ${taskName}:`,
          error as Error
        );
      }
    });
    originalTask(model);
    model.set(taskName, originalTask);
  };
};

/**
 * Google Analytics Proxy
 *
 * Use `GAP.asap` to run a function using the global `ga` instance as soon as possible.
 *
 * Use `GAP.on` to intercept one of the defined GA task types, and add additional functionality to it every time GA sends a signal.
 */
export class GoogleAnalyticsProxy {
  constructor() {
    waitForGa().then(ga => {
      if (ga === undefined) return;
      ga('set', 'customTask', (model: UniversalAnalytics.Model) => {
        const trackerName: string | undefined = model.get('name');
        if (trackerName != null) {
          taskTypeList.forEach(taskName => {
            model.set(taskName, generateCombinedTask(trackerName, taskName));
          });
        }
      });
    });
  }
  /**
   * A method for making adjustments to Google Analytics as soon as possible after it becomes available on window.
   * @param taskFunction A function to be executed as soon as possible, once GA is available. The function is passed the `ga` instance.
   */
  asap(taskFunction: GoogleAnalyticsASAPInterceptor): void {
    waitForGa().then(
      ga => {
        if (ga) taskFunction(ga);
      },
      () => {
        /* Do nothing */
        // This will mostly reject when running locally, and Segment isn't loaded.
      }
    );
  }
  /**
   * A method for registering interceptors for Google Analytics tasks.
   * @param taskName <string> The name of the Google Analytics task.
   * @param taskFunction <Function> A function to be executed when the task is run. Multiple functions can be registered to a task, and they will be executed in the order that they are registered. The default task will always be fired last.  The function is passed the GA "model" object, which has "get" and "set" methods.  Do not overwrite any tasks directly by using model.set in a function that you register here, or it will break things.
   */
  on(taskName: TaskType, taskFunction: GoogleAnalyticsInterceptor): void {
    if (!clientSide) return;
    const tasks = retrieveTasks(taskName);
    tasks.push(taskFunction);
  }
}
