import { ConversionInstructions } from './deviceModeTypes';
import { copy, move, remove } from './eventConversionTools';

/**
 * Each conversion instruction is a key named after a Segment event,
 * and a value that is an array with the converted name of the event
 * as the first element, and uses of either `copy` or `move` as the
 * remaining elements.
 */
export const conversionInstructions: ConversionInstructions = {
  'Signed In': ['login', copy('login_type').to('method')],
  'Product List Viewed': [
    'view_item_list',
    move('list_id').to('item_list_id'),
    copy('label').to('item_list_name'),
    move('products').to('items') // just know it's an item, based on structure
  ],
  'Promotion Clicked': [
    'select_promotion',
    move('name').to('creative_name'),
    move('position').to('creative_slot')
  ],
  'Checkout Started': ['begin_checkout', move('products').to('items'), move('total').to('value')],
  'Products Searched': ['search', move('query').to('search_term')],
  'Payment Info Entered': [
    'add_payment_info',
    move('products').to('items'),
    copy('payment_method').to('payment_type')
  ],
  'Product Viewed': ['view_item', move('.').to('items')],
  'Cart Viewed': ['view_cart', move('products').to('items')],
  'Product Added': ['add_to_cart', remove('products'), copy('.').to('items')],
  'Product Added to Wishlist': ['add_to_wishlist', copy('.').to('items')],
  'Product Removed': ['remove_from_cart', move('.').to('items')],
  'Product Clicked': ['select_item', move('.').to('items')]
};
