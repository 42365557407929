import { createContext, useContext } from 'react';
import { StatusCodes } from 'http-status-codes';

export const StatusCodeContext = createContext({ statusCode: StatusCodes.OK });

export const StatusCodeProvider = StatusCodeContext.Provider;

export const useStatusCode = (code: number) => {
  const context = useContext(StatusCodeContext);
  context.statusCode = code;
};
