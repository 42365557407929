import { Country, DEFAULT_COUNTRY } from '@moda/portal-stanchions';
import { difference } from 'ramda';

import { Gender } from '../../generated/types';

export type Preferences = {
  countryId: Country['id'];
  vertical: Gender;
  backInStockNotifications: boolean;
  personalizationRequiredNotifications: boolean;
  returnPolicyChangeNotifications: boolean;
  showEmailSignup: boolean;
  showLandingPageForRussia: boolean;
};

export type Preference = keyof Preferences;

export const DEFAULT_PREFERENCES: Preferences = {
  countryId: DEFAULT_COUNTRY.id,
  vertical: Gender.women,
  backInStockNotifications: true,
  personalizationRequiredNotifications: true,
  returnPolicyChangeNotifications: true,
  showEmailSignup: true,
  showLandingPageForRussia: true
} as const;

export const encode = (payload: Preferences): string => {
  return JSON.stringify(payload);
};

export const ENCODED_DEFAULT_PREFERENCES = encode(DEFAULT_PREFERENCES);

export const validate = (payload: unknown) => {
  if (typeof payload !== 'object' || payload == null) {
    throw new Error(`Preferences is not a valid JSON object.`);
  }

  const invalidKeys = difference(Object.keys(payload), Object.keys(DEFAULT_PREFERENCES));

  if (invalidKeys.length > 0) {
    throw new Error(`Preferences contains invalid data: ${invalidKeys.join(', ')}`);
  }

  // Assumes payload values are valid
  // TODO: Possibly use something like io-ts handle actual runtime validation
  return payload as Preferences;
};

export const decode = (payload: string = ENCODED_DEFAULT_PREFERENCES): Preferences => {
  try {
    const decoded = JSON.parse(payload);
    return validate(decoded);
  } catch {
    return DEFAULT_PREFERENCES;
  }
};
