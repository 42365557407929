import { ApolloError, ServerError } from '@apollo/client';

export type GenericError = Error &
  Partial<Omit<ApolloError, keyof Error>> & {
    displayMessage?: string;
    reason?: string;
  };

export const isGenericError = (error: unknown): error is GenericError =>
  typeof (error as GenericError)?.message === 'string';

type NetworkError = {
  title?: string;
  detail?: string;
};

export const getNetworkErrors = (error: GenericError) =>
  ((error.networkError as ServerError)?.result as Record<string, NetworkError[] | undefined | null>)
    ?.errors;
