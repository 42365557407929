import { env, Env } from './env';

// NOTE: Any environment specific overrides that happen here will beat environment variables.
// This is mostly provided as a convenience and to allow an opportunity to coerce the type of any
// env vars — which are always strings.
export const PRODUCTION: Env = {
  ...env.environment()
} as const;

export const DEVELOPMENT: Env = {
  ...env.environment()
} as const;

export const TEST: Env = {
  ...env.environment()
} as const;

const ENVIRONMENTS = {
  production: PRODUCTION,
  development: DEVELOPMENT,
  test: TEST
};

type Environment = keyof typeof ENVIRONMENTS;

export const CONFIG = ENVIRONMENTS[env.get('NODE_ENV') as Environment];
