import { Context, Plugin } from '@segment/analytics-next';
// Using segment's loadScript function because it's made to go here, and it reports success/failure.
import { loadScript } from '@segment/analytics-next/src/lib/load-script';
import { always, filter, toPairs, tryCatch, omit } from 'ramda';
import { CONFIG } from '../../../config';
import { normalizePageName } from '../../../hooks/usePageTracking';
import { findRouteFor } from '../../../routers';
import { getCookies } from '../../getCookies';
import { EventParameters } from './deviceModeTypes';
import { conversionInstructions } from './eventConversionInstructions';
import { convert, getNewEventName } from './eventConversionTools';

const measurementId = CONFIG['GA4_MEASUREMENT_ID'];
let ga4Loaded = false;

/**
 * This function is called "gtag" in all of google's documentation, and all of the
 * online documentation that you'll find on this topic.  We're calling it "ga4" here,
 * so that its purpose is more explicit.
 * This function must be implemented as shown.  If you push an ordinary array instead
 * of an `arguments` object, it won't trigger the tracking!
 *
 * The overloads are defined at https://developers.google.com/tag-platform/gtagjs/reference
 */
function ga4(command: 'config', targetId: string, additionalConfigInfo?: EventParameters): void;
function ga4(
  command: 'get',
  target: string,
  fieldName: string,
  callback: (value: unknown) => void
): void;
function ga4(command: 'set', parameters: EventParameters): void;
function ga4(command: 'event', eventName: string, eventParameters: EventParameters): void;
function ga4(
  command: 'consent',
  consentArgument: 'default' | 'update',
  consentParameters: {
    ad_storage: 'allowed' | 'denied';
    analytics_storage: 'allowed' | 'denied';
    wait_for_update: number;
  }
): void;
function ga4(command: 'js', date: Date): void;
function ga4() {
  if (window.ga4DataLayer != null) {
    // eslint-disable-next-line prefer-rest-params
    window.ga4DataLayer.push(arguments);
  }
}
window.ga4 = ga4;

const loadGA4 = () => {
  // Renaming the data layer to "ga4DataLayer".
  // https://developers.google.com/tag-platform/devguides/datalayer#rename_the_data_layer
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${measurementId}&l=ga4DataLayer`).then(
    script => {
      if (window.ga4DataLayer == null) {
        window.ga4DataLayer = [] as (IArguments | { event: string; 'gtm.uniqueEventId': number })[];
      }

      ga4('js', new Date());
      ga4('config', measurementId, {
        // This doesn't stop all page views from sending, only the automatic/default ones.
        // https://support.google.com/analytics/answer/11403294#disable&zippy=%2Cgoogle-tag-websites:~:text=Firebase%20(Mobile%20apps)-,Disable%20pageviews%20and%20screenviews,-Use%20the%20following
        send_page_view: false
      });

      ga4(
        'get',
        measurementId,
        'session_id',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        session_id =>
          (window.ga4_session_id = typeof session_id === 'number' ? session_id : Number(session_id))
      );
      ga4(
        'get',
        measurementId,
        'session_number',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        session_number =>
          (window.ga4_session_number =
            typeof session_number === 'number' ? session_number : Number(session_number))
      );
      ga4(
        'get',
        measurementId,
        'client_id',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id => (window.ga4_client_id = String(client_id))
      );
      ga4Loaded = true;

      return script;
    }
  );
  // Hard lesson learned here.  Don't actually wait until the script
  // loads to tell Segment that the plugin is loaded.  If you do,
  // Segment's code will basically ignore the plugin until the promise
  // resolves, and you'll miss the initial events on page load.
  return Promise.resolve();
};

const identify = function (context: Context) {
  const event = context.event;

  ga4('config', measurementId, { user_id: event.userId });

  // All event overrides in a Plugin MUST return the passed-in context.
  return context;
};

const parseSafely = tryCatch(JSON.parse, always({}));

const page = function (context: Context) {
  const event = context.event;

  const sessionParams = parseSafely(getCookies()?.sessionQueryParams ?? '{}') as Record<
    string,
    string
  >;
  const utmParameters = new URLSearchParams(
    filter(param => param[0].startsWith('utm_'), toPairs(sessionParams))
  ).toString();

  ga4('event', 'page_view', {
    ...omit(['ga4_title'], event.properties || {}),
    page_title: event.properties?.ga4_title,
    page_location: `${event.properties?.url}${utmParameters === '' ? '' : '?'}${utmParameters}`,
    content_group: event.properties?.name
  });

  // All event overrides in a Plugin MUST return the passed-in context.
  return context;
};

const track = function (context: Context) {
  const event = context.event;

  if (event.event == null) return context;

  const newName = getNewEventName(event.event, conversionInstructions);
  const parameters = convert(event, conversionInstructions);

  const sessionParams = parseSafely(getCookies()?.sessionQueryParams ?? '{}') as Record<
    string,
    string
  >;
  const utmParameters = new URLSearchParams(
    filter(param => param[0].startsWith('utm_'), toPairs(sessionParams))
  ).toString();
  const location = `${window.document.location}${utmParameters === '' ? '' : '?'}${utmParameters}`;

  const route = findRouteFor(window.document.location.pathname);
  const routeKey = route?.key ?? 'UnknownPage';
  const pageName = normalizePageName(routeKey);

  ga4('event', newName, { ...parameters, page_title: pageName, page_location: location });

  // All event overrides in a Plugin MUST return the passed-in context.
  return context;
};

/**
 * Examples of how this is intended to work are here:
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#example-plugins
 * The type definition for Plugin is here:
 * https://github.com/segmentio/analytics-next/blob/master/packages/browser/src/core/plugin/index.ts#L14
 */
export const ga4DeviceMode: Plugin = {
  name: 'GA4 Device Mode',
  type: 'destination',
  load: loadGA4,
  // Near as one can tell, nothing ever actually _calls_ `isLoaded`.  Nonetheless, it is required.
  isLoaded: () => true,
  version: '1.0.0',
  page,
  track,
  identify,
  // While Segment won't wait for non-critical plugins to load, it _will_ wait
  // until they're "ready", if you call an action using analytics.ready(),
  // which the TrackingContext now automatically does.
  ready: () => {
    return new Promise(resolve => {
      function waitForReady() {
        if (ga4Loaded) {
          resolve(true);
          return;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          setTimeout(waitForReady, 50);
        }
      }
      waitForReady();
    });
  }
};
