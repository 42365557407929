import React from 'react';
import { Constrain, Stack, Divider } from '@moda/om';
import { SiteFooterMobileSections } from './SiteFooterMobileSections';
import { SiteFooterMobileMailingList } from './SiteFooterMobileMailingList';
import { SiteFooterMobileSocialIcons } from './SiteFooterMobileSocialIcons';
import { SiteFooterMobileBasement } from './SiteFooterMobileBasement';

import './SiteFooterMobile.scss';

export const SiteFooterMobile: React.FC = () => (
  <Constrain className="SiteFooterMobile">
    <Stack space={4}>
      <SiteFooterMobileSections />
      <Divider />
      <SiteFooterMobileMailingList />
      <Divider />
      <SiteFooterMobileSocialIcons />
      <Divider />
      <SiteFooterMobileBasement />
    </Stack>
  </Constrain>
);
