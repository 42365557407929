import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Text } from '@moda/om';
import { hrefFor } from '../../routers';
import { tc } from '../../lib/trackingContext';
import { EmailVerificationStatusModal } from './EmailVerificationStatusModal';

export type EmailVerificationStatusAlreadyVerifiedProps = { show: boolean; onHide: () => void };

export const EmailVerificationStatusAlreadyVerified: React.FC<
  EmailVerificationStatusAlreadyVerifiedProps
> = ({ show, onHide }) => {
  const history = useHistory();

  useEffect(() => {
    if (show) tc.track('Welcome Window Viewed');
  }, [show]);

  const handleHide = () => {
    onHide();
    history.replace(hrefFor.HomePage());
  };

  return (
    <EmailVerificationStatusModal
      show={show}
      title="Welcome to Moda Operandi"
      onHide={() => {
        tc.track('Welcome Window Quit Clicked');
        onHide();
      }}
    >
      <Text>Your email address has already been successfully verified!</Text>
      <Button
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onClick={() => {
          tc.track('Welcome Window Start Shopping Clicked');
          handleHide();
        }}
      >
        Start Shopping
      </Button>
    </EmailVerificationStatusModal>
  );
};
