import { hrefFor } from '../../../routers';

type SiteFooterLinkConfig = {
  label: string;
  href: string;
  isPopup?: boolean;
};

type SiteFooterLinkCategoryConfig = {
  name: string;
  links: SiteFooterLinkConfig[];
  description?: string[];
};

export const SITE_FOOTER_LINKS_CONFIG: SiteFooterLinkCategoryConfig[] = [
  {
    name: 'Client Services',
    links: [
      {
        label: 'Contact Us',
        href: '/about#contact'
      },
      {
        label: 'Email Us',
        href: 'mailto:care@modaoperandi.com'
      },
      {
        label: 'FAQs',
        href: 'https://help.modaoperandi.com/hc/en-us',
        isPopup: true
      }
    ]
  },
  {
    name: 'Shipping & Returns',
    links: [
      {
        label: 'Track An Order',
        href: 'https://help.modaoperandi.com/hc/en-us/articles/210056706-Tracking-Your-Order',
        isPopup: true
      },
      {
        label: 'Return An Order',
        href: hrefFor.RequestReturnPage()
      },
      {
        label: 'Returns & Refunds',
        href: 'https://help.modaoperandi.com/hc/en-us/sections/202224966-Returns-Refunds',
        isPopup: true
      },
      {
        label: 'Shipping Times & Costs',
        href: 'https://help.modaoperandi.com/hc/en-us/articles/211521843-Shipping-Times-Costs',
        isPopup: true
      }
    ]
  },
  {
    name: 'About Moda',
    links: [
      { label: 'Careers', href: '/about#careers' },
      { label: 'About Us', href: '/editorial/about-us' },
      { label: 'Affiliates & Partners', href: '/editorial/affiliate' }
    ]
  },
  {
    name: 'How To Shop',
    links: [
      {
        label: 'Moda Rewards',
        href: '/rewards'
      },
      {
        label: 'Moda Private',
        href: '/editorial/moda-private'
      },
      {
        label: 'Moda Network',
        href: '/moda-network'
      }
    ]
  }
];
