import { Text, TextColor } from '@moda/om';
import React from 'react';

interface Props {
  color?: TextColor;
}

export const PhoneNumberTermsAndConditions: React.FC<Props> = ({ color }) => (
  <Text className="EmailSignup__termsconditions" treatment="body2" color={color}>
    †By submitting this form, you agree to receive recurring automated promotional and personalized
    marketing text messages (e.g. cart reminders) from Moda Operandi at the cell number used when
    signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel.
    Msg frequency varies. Msg &amp; data rates may apply.
  </Text>
);
