import { useEffect, useCallback } from 'react';
import uuid from 'uuid-random';
import { useCookies } from './useCookies';

type UseSignifydSessionIdArgs = {
  createIfNotExists?: boolean;
};

const SIGNIFYD_COOKIE_NAME = 'signifydSessionId';

export const useSignifydSessionId = (args?: UseSignifydSessionIdArgs) => {
  const {
    cookies: { signifydSessionId },
    setCookie,
    deleteCookie
  } = useCookies();
  const createIfNotExists = args?.createIfNotExists;

  const clearSignifydSessionId = useCallback(() => {
    deleteCookie(SIGNIFYD_COOKIE_NAME);
  }, [deleteCookie]);

  useEffect(() => {
    if (!signifydSessionId && createIfNotExists) {
      setCookie(SIGNIFYD_COOKIE_NAME, uuid());
    }
  }, [signifydSessionId, setCookie, createIfNotExists]);

  return { signifydSessionId, clearSignifydSessionId };
};
