import { SelectionNode, DocumentNode } from 'graphql';
import { setContext } from '@apollo/client/link/context';

const selectionHasRestDirectiveWithEndpoint = (selection: SelectionNode, endpoint: string) => {
  const restDirective = selection.directives?.find(directive => directive.name.value === 'rest');

  if (!restDirective) return false;

  const endpointArgument = restDirective.arguments?.find(
    argument => argument.name.value === 'endpoint'
  );

  if (!endpointArgument) return false;

  return endpointArgument.value.kind === 'StringValue' && endpointArgument.value.value === endpoint;
};

const queryHasRestDirectiveWithEndpoint = (query: DocumentNode, endpoint: string) => {
  const operation = query.definitions.find(definition => definition.kind === 'OperationDefinition');

  if (!operation || operation.kind !== 'OperationDefinition') return false;

  return operation.selectionSet.selections.some(selection =>
    selectionHasRestDirectiveWithEndpoint(selection, endpoint)
  );
};

/**
 * Sets headers on all requests to a REST endpoint through apollo-link-rest
 * apollo-link-rest doesn't support specifing header by endpoint, so this link takes care of that
 */
export const setRestHeaders = (endpoint: string, headers: { [key: string]: string }) =>
  setContext((request, context) =>
    queryHasRestDirectiveWithEndpoint(request.query, endpoint)
      ? { ...context, headers: { ...context?.headers, ...headers } }
      : context
  );
