import { breakpoints } from '@moda/tokens';
import { composeImageSrc, isValidImageSrc, remToUnitlessPx } from '@moda/portal-stanchions';
import { useBreakpoint } from '@moda/om';
import { CONFIG } from '../../config';

export type ImageSourceOptions = {
  src: string;
  quality?: number;
  nativeImageSize?: {
    height: number;
    width: number;
  };
  srcMode?: 'dynamic' | 'static';
};

export type ImageSources = {
  src: string;
  srcSet?: string;
};

const XS_WIDTH_PX = remToUnitlessPx(breakpoints.xs);

export const useImageSources = ({
  src,
  quality,
  nativeImageSize,
  srcMode = 'static'
}: ImageSourceOptions): ImageSources => {
  const origin = CONFIG.MODA_IMG_ROOT;

  const { matches: isExtraSmallScreen } = useBreakpoint({ lt: 'xs' });

  const size =
    nativeImageSize ||
    (isExtraSmallScreen ? { width: XS_WIDTH_PX, height: XS_WIDTH_PX } : undefined);

  if (srcMode === 'static' || !isValidImageSrc(src)) return { src };

  if (size) {
    const src1x = composeImageSrc(src, {
      size,
      quality,
      origin
    });

    const src2x = composeImageSrc(src, {
      size: {
        width: 2 * size.width,
        height: 2 * size.height
      },
      quality,
      origin
    });

    return {
      src: src1x,
      srcSet: `${src1x} 1x, ${src2x} 2x`
    };
  }

  return {
    src: composeImageSrc(src, {
      quality,
      origin
    })
  };
};
